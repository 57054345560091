import { useContext } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

import { useUserProfile } from '@/models/UserProfile';
import { verifyEmail } from '@/services/common';
import ConnectModal from '@/showcase/ConnectModal/ConnectModal';
import { type OSType } from '@/utils/computers';

import { ColumnPermissionsContext } from '../../contexts';
import { EmailVerificationModal, useEmailVerificationModal } from './EmailVerificationModal';
import { LaunchSRCModal, useLaunchSRCModal } from './LaunchSRCModal';

const connectModalAtom = atomWithReset<{ isOpen: boolean; computerId: number | null; platform: OSType | null }>({
  isOpen: false,
  computerId: null,
  platform: null,
});

export function useConnectModal() {
  const columnPermissions = useContext(ColumnPermissionsContext);
  const launchSRCModal = useLaunchSRCModal();

  const set = useSetAtom(connectModalAtom);

  return {
    open: ({ computerId, platform }: { computerId: number; platform: OSType }) => {
      // NOTE: Web app is currently not supported for SSH session
      if (columnPermissions.webApp && platform !== 'ssh') {
        set({ isOpen: true, computerId, platform });
      } else {
        // Launch SRC
        launchSRCModal.open({ computerId, isSSH: platform === 'ssh' });
      }
    },
  };
}

export function ConnectToStreamer(): React.JSX.Element {
  const userProfile = useUserProfile();

  const { isOpen, computerId, platform } = useAtomValue(connectModalAtom);
  const resetConnectModal = useResetAtom(connectModalAtom);

  const launchSRCModal = useLaunchSRCModal();
  const emailVerificationModal = useEmailVerificationModal();

  return (
    <>
      <ConnectModal
        isOpen={isOpen}
        onClose={() => {
          resetConnectModal();
        }}
        onBusinessApp={() => {
          if (computerId) {
            launchSRCModal.open({ computerId, isSSH: platform === 'ssh' });
          }
        }}
        onWebApp={() => {
          if (computerId) {
            if (userProfile.email_verified) {
              const url = `/w/web_client/${computerId}`;
              window.open(url, '_blank', 'noopener,noreferrer');
            } else {
              verifyEmail('connect_with_web_client').catch(console.error);
              emailVerificationModal.open({ email: userProfile.email });
            }
          }
        }}
      />

      <LaunchSRCModal />
      <EmailVerificationModal />
    </>
  );
}
