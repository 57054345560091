import type { DragEndEvent } from '@dnd-kit/core';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { useCurrentOpenField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { QuestionnaireFieldKindMap } from '@/services/serviceDesk/surveys/type';

import { ClickItemHandler, SurveyFormScenarioMap } from '../types';
import {
  CheckboxFieldEditMode,
  ComboBoxFieldEditMode,
  DragListWrapper,
  DragWrapper,
  LongTextFieldEditMode,
  RadioButtonFieldEditMode,
  ShortTextFieldEditMode,
} from './EditMode';
import { CheckboxField, ComboBoxField, LongTextField, RadioButtonField, ShortTextField } from './ViewMode';

const SCENARIO = SurveyFormScenarioMap.editor;

type QuestionnaireProps = {
  fields: Array<FieldArrayWithId<SurveyFormValue, 'questionnaire', 'id'>>;
  getClickFieldHandler: ({ fieldUuid, autoFocusType }: ClickItemHandler) => (event: { preventDefault: () => void }) => void;
  getClickOptionsFieldHandler: (index: number) => (
    options: Array<{
      option: string;
    }> | null,
  ) => void;
  getRemoveFieldHandler: (index: number) => () => void;
  onSaveField: () => void;
  onDragFieldEnd: (event: DragEndEvent) => void;
};
export const Questionnaire = ({
  fields,
  getClickFieldHandler,
  getClickOptionsFieldHandler,
  getRemoveFieldHandler,
  onSaveField,
  onDragFieldEnd,
}: QuestionnaireProps) => {
  const [currentOpenField] = useCurrentOpenField();

  const { register, getValues } = useFormContext<SurveyFormValue>();

  return (
    <DragListWrapper items={fields} onDragEnd={onDragFieldEnd}>
      <>
        {fields.map((field, index) => {
          const questionInfo = getValues(`questionnaire.${index}`);
          const questionValue = questionInfo.question;
          const answerPlaceholderValue = questionInfo.answer_placeholder;
          const fieldIsRequired = questionInfo.required;

          switch (questionInfo.kind) {
            case QuestionnaireFieldKindMap.shortText:
              return (
                <DragWrapper key={field.id} id={field.id}>
                  {currentOpenField?.uuid === field.uuid ? (
                    <ShortTextFieldEditMode
                      questionProps={{ ...register(`questionnaire.${index}.question`) }}
                      answerPlaceholderProps={{ ...register(`questionnaire.${index}.answer_placeholder`) }}
                      isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                      onSave={onSaveField}
                      onDelete={getRemoveFieldHandler(index)}
                    />
                  ) : (
                    <ShortTextField
                      {...field}
                      scenario={SCENARIO}
                      labelValue={questionValue}
                      placeholder={answerPlaceholderValue}
                      isRequired={fieldIsRequired}
                      onClickLabel={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'label' })}
                      onMouseDown={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'input' })}
                    />
                  )}
                </DragWrapper>
              );
            case QuestionnaireFieldKindMap.longText:
              return (
                <DragWrapper key={field.id} id={field.id}>
                  {currentOpenField?.uuid === field.uuid ? (
                    <LongTextFieldEditMode
                      questionProps={{ ...register(`questionnaire.${index}.question`) }}
                      answerPlaceholderProps={{ ...register(`questionnaire.${index}.answer_placeholder`) }}
                      isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                      onSave={onSaveField}
                      onDelete={getRemoveFieldHandler(index)}
                    />
                  ) : (
                    <LongTextField
                      {...field}
                      scenario={SCENARIO}
                      labelValue={questionValue}
                      placeholder={answerPlaceholderValue}
                      isRequired={fieldIsRequired}
                      onClickLabel={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'label' })}
                      onMouseDown={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'input' })}
                    />
                  )}
                </DragWrapper>
              );
            case QuestionnaireFieldKindMap.comboBox: {
              return (
                <DragWrapper key={field.id} id={field.id}>
                  {currentOpenField?.uuid === field.uuid ? (
                    <ComboBoxFieldEditMode
                      fieldIndex={index}
                      questionProps={{ ...register(`questionnaire.${index}.question`) }}
                      isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                      options={questionInfo.options}
                      updateOptions={getClickOptionsFieldHandler(index)}
                      onSave={onSaveField}
                      onDelete={getRemoveFieldHandler(index)}
                    />
                  ) : (
                    <ComboBoxField
                      {...field}
                      registerFieldName=""
                      defaultValue={questionInfo.default_option}
                      options={questionInfo.options}
                      scenario={SCENARIO}
                      labelValue={questionValue}
                      isRequired={fieldIsRequired}
                      onClickLabel={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'label' })}
                      onMouseDown={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'input' })}
                    />
                  )}
                </DragWrapper>
              );
            }
            case QuestionnaireFieldKindMap.radioButton:
              return (
                <DragWrapper key={field.id} id={field.id}>
                  {currentOpenField?.uuid === field.uuid ? (
                    <RadioButtonFieldEditMode
                      fieldIndex={index}
                      questionProps={{ ...register(`questionnaire.${index}.question`) }}
                      isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                      options={questionInfo.options}
                      updateOptions={getClickOptionsFieldHandler(index)}
                      onSave={onSaveField}
                      onDelete={getRemoveFieldHandler(index)}
                    />
                  ) : (
                    <RadioButtonField
                      {...field}
                      registerFieldName=""
                      defaultValue={questionInfo.default_option}
                      options={questionInfo.options}
                      scenario={SCENARIO}
                      labelValue={questionValue}
                      isRequired={fieldIsRequired}
                      onClickLabel={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'label' })}
                      onMouseDown={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'input' })}
                    />
                  )}
                </DragWrapper>
              );
            case QuestionnaireFieldKindMap.checkbox:
              return (
                <DragWrapper key={field.id} id={field.id}>
                  {currentOpenField?.uuid === field.uuid ? (
                    <CheckboxFieldEditMode
                      fieldIndex={index}
                      questionProps={{ ...register(`questionnaire.${index}.question`) }}
                      isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                      options={questionInfo.options}
                      updateOptions={getClickOptionsFieldHandler(index)}
                      onSave={onSaveField}
                      onDelete={getRemoveFieldHandler(index)}
                    />
                  ) : (
                    <CheckboxField
                      {...field}
                      registerFieldName=""
                      defaultValue={questionInfo.default_option}
                      options={questionInfo.options}
                      scenario={SCENARIO}
                      labelValue={questionValue}
                      isRequired={fieldIsRequired}
                      onClickLabel={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'label' })}
                      onMouseDown={getClickFieldHandler({ fieldUuid: field.uuid, autoFocusType: 'input' })}
                    />
                  )}
                </DragWrapper>
              );
            default:
              return null;
          }
        })}
      </>
    </DragListWrapper>
  );
};
