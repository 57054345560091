import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useRippleFlashMessage } from '@/design';
import { useTeamInfoForChannels } from '@/modules/ServiceDesk/Channels/useTeamInfoForChannels';
import { SurveyListError, surveyListErrorSchema } from '@/services/serviceDesk/surveys';

import { getSurveyList } from './service';

type SurveyListQueryProps = {
  errorMessages?: Record<SurveyListError['result'], React.ReactNode>;
};

export const useSurveyListQuery = ({ errorMessages }: SurveyListQueryProps = {}) => {
  const { teamId } = useTeamInfoForChannels();
  const { flashMessage } = useRippleFlashMessage();

  const query = useQuery({
    queryKey: ['survey', 'list', teamId],
    queryFn: async () => {
      const result = await getSurveyList(teamId);
      return result.surveys;
    },
  });

  useEffect(function handleError() {
    if (!errorMessages || !query.isError) {
      return;
    }
    const errorValidation = surveyListErrorSchema.safeParse(query.error);
    if (!errorValidation.success) {
      console.error('Invalid error code', errorValidation.error);
      return;
    }
    const { result } = errorValidation.data;
    if (!errorMessages[result]) {
      console.error('Unhandled error code: ', result);
      return;
    }
    flashMessage({ variant: 'error', title: errorMessages[result] });
  });

  return query;
};
