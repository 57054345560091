import { useContext, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import type { SortDirection } from '@tanstack/react-table';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, selectAtom } from 'jotai/utils';

import type { RippleComputerDeviceIconProps } from '@/design';
import type { ComputerListColumnPermissions } from '@/services/computers';
import { ComputerGroup } from '@/services/group/types';
import { getTeamDetail } from '@/services/team';
import useTeamInformation from '@/utils/useTeamInformation';
import type { TeamInformation } from '@/utils/useTeamInformation';

import { tableStateAtom, waitingUpdateMapAtom } from './atoms';
import { DEFAULT_GROUP_ID, FROM_OTHER_GROUP_ID, GROUPING_TAG } from './constants';
import { ScrollPositionContext } from './contexts';
import type { ComputerItemBase, ExpandedState, Group, GroupedComputerData } from './types';

export function filterNormalGroup(group: Group): boolean {
  return [DEFAULT_GROUP_ID, FROM_OTHER_GROUP_ID].includes(group.id) === false;
}

export function sortGroup(aGroup: Group | ComputerGroup, bGroup: Group | ComputerGroup): number {
  // let other group always at last
  if (aGroup.id === FROM_OTHER_GROUP_ID) return 1;
  if (bGroup.id === FROM_OTHER_GROUP_ID) return -1;

  // let default group always at last
  if (aGroup.id === DEFAULT_GROUP_ID) return 1;
  if (bGroup.id === DEFAULT_GROUP_ID) return -1;

  if (aGroup.name.toLocaleUpperCase() > bGroup.name.toLocaleUpperCase()) return 1;
  if (aGroup.name.toLocaleUpperCase() < bGroup.name.toLocaleUpperCase()) return -1;
  return 0;
}

export function sortGroupData(groupNameMap: Record<string, string>, sortDirection: SortDirection) {
  return function sortFunction(a: GroupedComputerData, b: GroupedComputerData): number {
    const sortGroupDirection = sortDirection === 'asc' ? 1 : -1;
    return (
      sortGroup(
        { id: String(a.group_id ?? FROM_OTHER_GROUP_ID), name: groupNameMap[a.group_id ?? FROM_OTHER_GROUP_ID] },
        { id: String(b.group_id ?? FROM_OTHER_GROUP_ID), name: groupNameMap[b.group_id ?? FROM_OTHER_GROUP_ID] },
      ) * sortGroupDirection
    );
  };
}

export function hasActionAbility<T extends Pick<ComputerItemBase, 'is_device_owner'>>(computerData: T | undefined): boolean {
  return Boolean(computerData?.is_device_owner);
}

export function getRowId<T extends ComputerItemBase>(data: T): string {
  return String(data.id);
}

export function generateGroupRowId(groupId: string): string {
  return `${GROUPING_TAG}:${groupId}`;
}

export function checkPermission<T>(permission: boolean | null | undefined, item: T): Array<T> {
  if (permission) return [item];
  return [];
}

export function canSeeGroup(teamInformation: TeamInformation | null, permissionMap: ComputerListColumnPermissions | undefined): boolean {
  if (teamInformation?.role !== 'member') return true;
  return Boolean(permissionMap?.team_permissions.display_group_to_member);
}

export function canSeeNotes(teamInformation: TeamInformation | null, permissionMap: ComputerListColumnPermissions | undefined): boolean {
  if (teamInformation?.role !== 'member') return true;
  return Boolean(permissionMap?.team_permissions.member_fetch_computer_notes);
}

export function useInitializeExpandedState({ storageKey, hasPermission }: { storageKey: string; hasPermission: boolean }): void {
  const setTableStateAtom = useSetAtom(tableStateAtom);

  const storedExpandedStateAtom = useMemo(() => atomWithStorage<ExpandedState>(storageKey, {}), [storageKey]);

  useEffect(
    function initializeExpandedState() {
      if (hasPermission) {
        setTableStateAtom((draft) => {
          draft.expandedStateAtom = storedExpandedStateAtom;
        });
      }
    },
    [hasPermission, setTableStateAtom, storedExpandedStateAtom],
  );
}

export function useTeamDetailQuery() {
  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;

  return useQuery({
    queryKey: ['computerList', 'getTeamDetail', teamId],
    queryFn: () => {
      return getTeamDetail(teamId);
    },
  });
}

export function useShowScrollOverlay(overlayPosition: 'left' | 'right'): boolean {
  const scrollPosition = useContext(ScrollPositionContext);

  switch (scrollPosition) {
    case 'middle':
      return true;
    case 'left':
      return overlayPosition === 'right';
    case 'right':
      return overlayPosition === 'left';
    case 'none':
    default:
      return false;
  }
}

export function useShowDefaultGroup(): boolean {
  const teamInformation = useTeamInformation();
  const isMember = teamInformation?.role === 'member';
  const isGroupAdmin = teamInformation?.role === 'manager' && teamInformation.groupScope === 'part';

  return !isMember && !isGroupAdmin;
}

export function useShowWaitingUpdate(computerId: string): boolean {
  const targetWaitingUpdateAtom = useMemo(() => selectAtom(waitingUpdateMapAtom, (stateMap) => stateMap[computerId]), [computerId]);
  return useAtomValue(targetWaitingUpdateAtom);
}

export function computeComputerIconState({
  connected,
  online,
  waitingUpdate,
}: {
  connected: boolean;
  online: boolean;
  waitingUpdate: boolean;
}): RippleComputerDeviceIconProps['state'] {
  if (waitingUpdate) return 'loading';
  if (connected) return 'connected';
  if (online) return 'online';
  return 'offline';
}
