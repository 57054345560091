import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useTeamInformation } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

export const usePreferencePolicyAccess = () => {
  const { teamKind, role } = useTeamInformation();

  const infoQuery = useQuery({
    queryKey: ['preference policy', 'info'],
    queryFn: () => infoQueryService.execute({ team_permissions: ['preference_policy'] }, [teamKind]),
    enabled: featureControl.getToggle('PCP_1134__Policy_management'),
  });

  const isTeamSupported = ['sba', 'srs', 'msp'].includes(teamKind);
  const isRoleSupported = role === 'manager' || role === 'owner';
  const isTeamAccessible = Boolean(infoQuery.data?.[teamKind]?.team_permissions.preference_policy);

  return {
    isFetched: infoQuery.isFetched,
    canAccess: isTeamSupported && isRoleSupported && isTeamAccessible,
  };
};
