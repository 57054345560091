import { useBreakpointValue } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleButton, RippleButtonText, RippleDownloadAvailable } from '@/design';
import type { RippleButtonProps } from '@/design';

const DOWNLOAD_DEFAULT_COLOR = 'legacyPurple.100';
const DOWNLOAD_HOVER_COLOR = 'legacyPurple.200';
const DOWNLOAD_ACTIVE_COLOR = 'legacyPurple.300';

type DownloadBusinessAppBtnProps = {
  onClick: () => void;
};

export const DownloadBusinessAppBtn = ({ onClick }: DownloadBusinessAppBtnProps): React.JSX.Element => {
  const { t } = useTranslation(['setup']);
  const buttonSize = useBreakpointValue<RippleButtonProps['size']>({ base: 'sm', sm: 'xs' });

  return (
    <RippleButton
      size={buttonSize}
      onClick={onClick}
      leftIcon={<RippleDownloadAvailable color="white" />}
      bg={DOWNLOAD_DEFAULT_COLOR}
      borderColor={DOWNLOAD_DEFAULT_COLOR}
      _hover={{ bg: DOWNLOAD_HOVER_COLOR, borderColor: DOWNLOAD_HOVER_COLOR }}
      _active={{
        bg: DOWNLOAD_ACTIVE_COLOR,
        borderColor: DOWNLOAD_ACTIVE_COLOR,
      }}
      pl="9px"
      pr="11px"
    >
      <RippleButtonText color="white">{t('setup:businessApp')}</RippleButtonText>
    </RippleButton>
  );
};
