import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { RippleInput } from '@/design';
import colors from '@/design/theme/colors';
import { LABEL_MAX_LENGTH, SHORT_TEXT_PLACEHOLDER_MAX_LENGTH } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { useCurrentOpenFieldValue, useScrollToLastField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';

import { ActionBar, FieldTitle, Highlighter } from '.';

type ShortTextFieldEditModeProps = {
  isRequireProps: UseFormRegisterReturn;
  questionProps: UseFormRegisterReturn;
  onDelete: () => void;
  onSave: () => void;
  answerPlaceholderProps: UseFormRegisterReturn;
};

export function ShortTextFieldEditMode({
  isRequireProps,
  questionProps,
  onDelete,
  onSave,
  answerPlaceholderProps,
}: ShortTextFieldEditModeProps) {
  const { t } = useTranslation();
  const { labelRef } = useScrollToLastField();
  const currentOpenField = useCurrentOpenFieldValue();

  return (
    <Box w="100%" p="0px 48px 8px" position="relative">
      <Highlighter />
      <FieldTitle title={t('survey:short_text_field')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-short-text-title"
        aria-describedby="edit-short-text-title"
        maxLength={LABEL_MAX_LENGTH}
        variant="flushed"
        sx={{
          caretColor: colors.blue[100],
        }}
        fontSize="16px"
        size="sm"
        type="text"
        placeholder={t('survey:add_name_for_this_field')}
        m="4px 0 12px"
        {...questionProps}
        ref={(e) => {
          questionProps.ref(e);
          labelRef.current = e;
        }}
      />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'input'}
        id="edit-short-text-placeholder"
        aria-describedby="edit-short-text-placeholder"
        maxLength={SHORT_TEXT_PLACEHOLDER_MAX_LENGTH}
        variant="editPlaceholder"
        size="sm"
        type="text"
        placeholder={t('survey:add_placeholder_text')}
        mb="12px"
        {...answerPlaceholderProps}
      />
      <ActionBar isShowRequired={false} switchProps={isRequireProps} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
}
