/**
 * Configure computer list columns view settings for SRS and SBA.
 * @see https://splashtop.atlassian.net/wiki/spaces/~5eb8c936767b660b774ccb69/pages/883916949/PCP+Computer+list
 */
import { useMemo } from 'react';

import { useTranslation } from 'next-i18next';

import { featureControl } from '@/feature/toggle';
import { tableColumn } from '@/models/Computer';

import { checkPermission } from '../ComputerList';
import type { ColumnPermissions, ConfigurableColumn } from '../ComputerList';
import { columnIdMap } from './constants';

export function useConfigurableColumnsSRS(columnPermissions: ColumnPermissions): Array<ConfigurableColumn> {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { columnId: columnIdMap.host_name, label: t('computer:deviceName'), defaultVisibility: false },
      ...checkPermission(columnPermissions.group, { columnId: columnIdMap.groupName, label: t('computer:group') }),
      { columnId: columnIdMap.version, label: t('computer:column.streamerVer.title') },
      { columnId: columnIdMap.os, label: tableColumn.os.getLabel(t) },
      { columnId: columnIdMap.osBuild, label: tableColumn.osBuild.getLabel(t) },
      {
        columnId: columnIdMap.architecture,
        label: tableColumn.architecture.getLabel(t),
        defaultVisibility: tableColumn.architecture.defaultVisibility,
      },
      // TODO: update permission checking for policy
      ...checkPermission(featureControl.getToggle('PCP_1134__Policy_management') && columnPermissions.emmPolicy, {
        columnId: columnIdMap.emm_policy,
        label: 'Policy',
      }),
      ...checkPermission(columnPermissions.preferencePolicy, {
        columnId: columnIdMap.preference_policy,
        label: t('preferencePolicy:title'),
      }),
      { columnId: columnIdMap.pubip, label: `${t('computer:ipAddress')} (WAN)` },
      { columnId: columnIdMap.local_ip, label: `${t('computer:ipAddress')} (LAN)` },
      ...checkPermission(columnPermissions.notes, { columnId: columnIdMap.note, label: t('computer:note') }),
      ...checkPermission(columnPermissions.antivirusStatus, { columnId: columnIdMap.antivirus_scan_status, label: t('computer:status') }),
      ...checkPermission(columnPermissions.windowsUpdates, {
        columnId: columnIdMap.windows_updates,
        label: t('computer:column.updates.title'),
      }),
      ...checkPermission(columnPermissions.alertCount, {
        columnId: columnIdMap.alerts_count,
        label: t('computer:column.alerts.title'),
      }),
      ...checkPermission(columnPermissions.securityLayer, {
        columnId: columnIdMap.infra_gen_status,
        label: t('computer:column.securityLayer.title'),
        defaultVisibility: false,
      }),
      { columnId: columnIdMap.last_session, label: t('computer:lastSession') },
      { columnId: columnIdMap.last_online, label: t('computer:lastOnline') },
    ],
    [t, columnPermissions],
  );
}

export function useConfigurableColumnsSBA(columnPermissions: ColumnPermissions): Array<ConfigurableColumn> {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { columnId: columnIdMap.host_name, label: t('computer:deviceName'), defaultVisibility: false },
      { columnId: columnIdMap.computerOwner, label: tableColumn.computerOwner.getLabel(t), defaultVisibility: true },
      { columnId: columnIdMap.access, label: tableColumn.access.getLabel(t), defaultVisibility: true },
      ...checkPermission(columnPermissions.group, { columnId: columnIdMap.groupName, label: t('computer:group') }),
      { columnId: columnIdMap.version, label: t('computer:column.streamerVer.title') },
      { columnId: columnIdMap.os, label: tableColumn.os.getLabel(t) },
      { columnId: columnIdMap.osBuild, label: tableColumn.osBuild.getLabel(t) },
      {
        columnId: columnIdMap.architecture,
        label: tableColumn.architecture.getLabel(t),
        defaultVisibility: tableColumn.architecture.defaultVisibility,
      },
      // TODO: update permission checking for policy
      ...checkPermission(featureControl.getToggle('PCP_1134__Policy_management') && columnPermissions.emmPolicy, {
        columnId: columnIdMap.emm_policy,
        label: 'Policy',
      }),
      ...checkPermission(columnPermissions.preferencePolicy, {
        columnId: columnIdMap.preference_policy,
        label: t('preferencePolicy:title'),
      }),
      { columnId: columnIdMap.pubip, label: `${t('computer:ipAddress')} (WAN)` },
      { columnId: columnIdMap.local_ip, label: `${t('computer:ipAddress')} (LAN)` },
      ...checkPermission(columnPermissions.notes, { columnId: columnIdMap.note, label: t('computer:note') }),
      ...checkPermission(columnPermissions.antivirusStatus, { columnId: columnIdMap.antivirus_scan_status, label: t('computer:status') }),
      ...checkPermission(columnPermissions.windowsUpdates, {
        columnId: columnIdMap.windows_updates,
        label: t('computer:column.updates.title'),
      }),
      ...checkPermission(columnPermissions.alertCount, {
        columnId: columnIdMap.alerts_count,
        label: t('computer:column.alerts.title'),
      }),
      ...checkPermission(columnPermissions.securityLayer, {
        columnId: columnIdMap.infra_gen_status,
        label: t('computer:column.securityLayer.title'),
        defaultVisibility: false,
      }),
      { columnId: columnIdMap.last_session, label: t('computer:lastSession') },
      { columnId: columnIdMap.last_online, label: t('computer:lastOnline') },
    ],
    [t, columnPermissions],
  );
}
