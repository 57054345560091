import { featureControl } from '@/feature/toggle';
import { useTeamRole, useUnattendedTeamInformation } from '@/models/TeamInformation';

export const useEmmPolicyAccess = () => {
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const teamRole = useTeamRole();

  const isTeamSupported = unattendedTeamInformation && ['sba', 'srs', 'msp'].includes(unattendedTeamInformation.teamKind);
  const isRoleSupported = teamRole.isOwner || teamRole.isAdmin;

  return featureControl.getToggle('PCP_1134__Policy_management') && Boolean(isTeamSupported && isRoleSupported);
};
