import { Flex, HStack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleButton, RippleHeading06, RippleSection, RippleSheetAction, RippleTextarea } from '@/design';
import { SurveyFormMode, SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import preSanitize from '@/utils/pre-sanitize';
import { useZendeskDisplay } from '@/utils/useZendesk';

import { PreviewThankYouMessageModal } from '.';
import { DESCRIPTION_MAX_LENGTH, SURVEY_FORM_CONTENT_WIDTH } from './constant';

type CustomThankYouMessageProps = {
  mode: SurveyFormMode;
  onClickPreviousStep: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
};

export const CustomThankYouMessage = ({ mode, onClickPreviousStep, onSubmit, isSubmitting }: CustomThankYouMessageProps) => {
  const previewModalState = useDisclosure();
  const { t } = useTranslation();
  const { register, setValue, watch } = useFormContext<SurveyFormValue>();

  useZendeskDisplay(!previewModalState.isOpen);

  const thankYouMessage = watch('thank_you_message');

  const THANK_YOU = t('survey:thank_you_for_answering');

  const autoFillThankYouMessage = () => {
    const sanitizedThankYouMessage = thankYouMessage ? preSanitize(thankYouMessage).trim() : '';
    setValue('thank_you_message', sanitizedThankYouMessage === '' ? THANK_YOU : sanitizedThankYouMessage);
  };

  const handlePreviousStep = () => {
    autoFillThankYouMessage();
    onClickPreviousStep();
  };

  const handleNextStep = () => {
    autoFillThankYouMessage();
    onSubmit();
  };

  const handleClickPreview = () => {
    autoFillThankYouMessage();
    previewModalState.onOpen();
  };
  return (
    <>
      <HStack w={SURVEY_FORM_CONTENT_WIDTH} mt="48px" gap="24px" flexDirection="column" alignItems="flex-start">
        <RippleHeading06>{t('survey:custom_thank_you_message')}</RippleHeading06>
        <RippleSection name={t('survey:thank_you_message')} w="56%">
          <RippleTextarea
            h="120px"
            overflow="auto"
            resize="none"
            placeholder={THANK_YOU}
            maxLength={DESCRIPTION_MAX_LENGTH}
            {...register('thank_you_message')}
          />
        </RippleSection>
      </HStack>
      <RippleSheetAction>
        <Flex w="100%" direction="row" alignItems="center" justifyContent="space-between">
          <RippleButton size="sm" variant="secondary" onClick={handleClickPreview}>
            {t('common:preview')}
          </RippleButton>
          {previewModalState.isOpen && <PreviewThankYouMessageModal onClose={previewModalState.onClose} />}
          <Flex gap="12px">
            <RippleButton size="sm" variant="grayScaleGhost" onClick={handlePreviousStep}>
              {t('common:back')}
            </RippleButton>
            <RippleButton size="sm" onClick={handleNextStep} isLoading={isSubmitting}>
              {mode === 'edit' ? t('common:update') : t('common:create')}
            </RippleButton>
          </Flex>
        </Flex>
      </RippleSheetAction>
    </>
  );
};
