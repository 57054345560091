import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { RippleInput, RippleTextarea } from '@/design';
import colors from '@/design/theme/colors';
import { ActionBar, FieldTitle, Highlighter } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode';
import { LABEL_MAX_LENGTH, PLACEHOLDER_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useCurrentOpenFieldAtom } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';

type CustomerIssueFieldEditModeProps = {
  isRequireProps: UseFormRegisterReturn;
  labelProps: UseFormRegisterReturn;
  onSave: () => void;
  placeholderProps: UseFormRegisterReturn;
};

export function CustomerIssueFieldEditMode({ isRequireProps, labelProps, onSave, placeholderProps }: CustomerIssueFieldEditModeProps) {
  const currentOpenField = useCurrentOpenFieldAtom();
  const { t } = useTranslation();
  return (
    <Box w="100%" p="0px 36px 12px" position="relative">
      <Highlighter />
      <FieldTitle title={t('support-forms:customerIssue')} tooltip={t('support-forms:asCustomerIssue')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-customer-issue-title"
        aria-describedby="edit-customer-issue-title"
        maxLength={LABEL_MAX_LENGTH}
        variant="flushed"
        sx={{
          caretColor: colors.blue[100],
        }}
        fontSize="12px"
        size="sm"
        type="text"
        placeholder={t('support-forms:fieldForCustomerIssue')}
        _placeholder={{
          fontStyle: 'italic',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'dark.40',
        }}
        mb="12px"
        {...labelProps}
      />
      <RippleTextarea
        autoFocus={currentOpenField?.autoFocusType === 'input'}
        id="edit-textarea-placeholder"
        aria-describedby="edit-textarea-placeholder"
        maxLength={PLACEHOLDER_MAX_LENGTH}
        height="120px"
        resize="none"
        color="dark.40"
        border="1px solid"
        borderColor="neutral.300"
        background="white"
        sx={{
          caretColor: colors.blue[100],
        }}
        _placeholder={{
          color: 'dark.40',
          fontStyle: 'italic',
        }}
        _hover={{
          background: 'blue.0',
          borderColor: 'neutral.300',
        }}
        _focus={{
          background: 'white',
          borderColor: 'blue.100',
        }}
        _focusVisible={{
          border: '1px solid',
          borderColor: 'blue.100',
          boxShadow: 'none',
        }}
        placeholder={t('support-forms:addPlaceholderText')}
        {...placeholderProps}
      />
      <ActionBar isShowDeleteButton={false} switchProps={isRequireProps} onSave={onSave} />
    </Box>
  );
}
