import { z } from 'zod';

export const AccessibilitySchema = z.enum(['hidden', 'read_only', 'read_write']);
export type Accessibility = z.infer<typeof AccessibilitySchema>;

const createPolicyItemSchema = <T extends z.ZodTypeAny, Code extends string>(code: Code, valueSchema: T) =>
  z.object({
    id: z.number(),
    code: z.literal(code),
    operator: z.union([z.literal('+'), z.literal('-')]),
    value: valueSchema.nullable(),
  });

export const policyItemsMapSchema = z.object({
  enabled: createPolicyItemSchema('01000001', z.boolean()),
  dev_name: createPolicyItemSchema(
    '01000002',
    z.object({
      setting: z.union([z.null(), z.literal('')]),
      os_sync: z.union([z.literal('0'), z.literal('1')]).optional(),
    }),
  ),
  file_transfer: createPolicyItemSchema(
    '01000003',
    z.object({
      setting: z.union([z.literal('0'), z.literal('3')]),
    }),
  ),
  idle_session_timeout: createPolicyItemSchema(
    '01000004',
    z.object({
      setting: z.string(),
    }),
  ),
  hide_tray_icon: createPolicyItemSchema(
    '01000005',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1')]),
    }),
  ),
  direct_connection: createPolicyItemSchema(
    '01000006',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1')]),
    }),
  ),
  audio: createPolicyItemSchema(
    '01000007',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1'), z.literal('2')]),
    }),
  ),
  blank_screen: createPolicyItemSchema(
    '01000008',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1')]),
    }),
  ),
  lock_screen: createPolicyItemSchema(
    '01000009',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1')]),
    }),
  ),
  lock_km: createPolicyItemSchema(
    '01000010',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1')]),
    }),
  ),
  admin_only: createPolicyItemSchema(
    '01000011',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1')]),
    }),
  ),
  max_fps: createPolicyItemSchema(
    '01000012',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1'), z.literal('2'), z.literal('3'), z.literal('10')]),
    }),
  ),
  max_audio: createPolicyItemSchema(
    '01000013',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1'), z.literal('2'), z.literal('3'), z.literal('4')]),
    }),
  ),
  osc_auth: createPolicyItemSchema(
    '01000014',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1')]),
    }),
  ),
  conn_perm: createPolicyItemSchema(
    '01000015',
    z.object({
      setting: z.union([z.literal('0'), z.literal('1'), z.literal('2')]),
      option: z.union([z.literal('1'), z.literal('2')]).optional(),
    }),
  ),
});

export type PolicyItemsMap = z.infer<typeof policyItemsMapSchema>;

export const policyItemSchema = z.discriminatedUnion('code', [
  policyItemsMapSchema.shape.enabled,
  policyItemsMapSchema.shape.dev_name,
  policyItemsMapSchema.shape.file_transfer,
  policyItemsMapSchema.shape.idle_session_timeout,
  policyItemsMapSchema.shape.hide_tray_icon,
  policyItemsMapSchema.shape.direct_connection,
  policyItemsMapSchema.shape.audio,
  policyItemsMapSchema.shape.blank_screen,
  policyItemsMapSchema.shape.lock_screen,
  policyItemsMapSchema.shape.lock_km,
  policyItemsMapSchema.shape.admin_only,
  policyItemsMapSchema.shape.max_fps,
  policyItemsMapSchema.shape.max_audio,
  policyItemsMapSchema.shape.osc_auth,
  policyItemsMapSchema.shape.conn_perm,
]);
export type PolicyItem = z.infer<typeof policyItemSchema>;

export const policyNodeSchema = z.object({
  emm_policy_id: z.number(),
  parent_id: z.union([z.number(), z.null()]),
  policy_items: z.array(policyItemSchema),
});
export type PolicyNode = z.infer<typeof policyNodeSchema>;

export const policyItemValueSchema = z.union([
  policyItemsMapSchema.shape.enabled.shape.value,
  policyItemsMapSchema.shape.dev_name.shape.value,
  policyItemsMapSchema.shape.file_transfer.shape.value,
  policyItemsMapSchema.shape.idle_session_timeout.shape.value,
  policyItemsMapSchema.shape.hide_tray_icon.shape.value,
  policyItemsMapSchema.shape.direct_connection.shape.value,
  policyItemsMapSchema.shape.audio.shape.value,
  policyItemsMapSchema.shape.blank_screen.shape.value,
  policyItemsMapSchema.shape.lock_screen.shape.value,
  policyItemsMapSchema.shape.lock_km.shape.value,
  policyItemsMapSchema.shape.admin_only.shape.value,
  policyItemsMapSchema.shape.max_fps.shape.value,
  policyItemsMapSchema.shape.max_audio.shape.value,
  policyItemsMapSchema.shape.osc_auth.shape.value,
  policyItemsMapSchema.shape.conn_perm.shape.value,
]);

export type PolicyRequestDataMap = {
  [K in keyof PolicyItemsMap]: Omit<PolicyItemsMap[K], 'id'>;
};

export const featureKeyCodeMap = {
  enabled: '01000001',
  dev_name: '01000002',
  file_transfer: '01000003',
  idle_session_timeout: '01000004',
  hide_tray_icon: '01000005',
  direct_connection: '01000006',
  audio: '01000007',
  blank_screen: '01000008',
  lock_screen: '01000009',
  lock_km: '01000010',
  admin_only: '01000011',
  max_fps: '01000012',
  max_audio: '01000013',
  osc_auth: '01000014',
  conn_perm: '01000015',
} as const;
