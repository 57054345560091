import { useState } from 'react';

import { useTranslation } from 'next-i18next';

import { DownloadBusinessAppBtn } from '@/modules/Unboxing/shared/DownloadBusinessAppBtn';
import { DownloadModal } from '@/modules/Unboxing/shared/DownloadModal';

import { TextWithActionBtn } from './TextWithActionBtn';

export const ConnectFrom = () => {
  const { t } = useTranslation(['setup', 'common']);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  return (
    <>
      <TextWithActionBtn intro={t('setup:installBusinessApp', { businessApp: t('setup:businessApp') })}>
        <DownloadBusinessAppBtn
          onClick={() => {
            setOpenDownloadModal(true);
          }}
        />
      </TextWithActionBtn>
      <DownloadModal
        isOpen={openDownloadModal}
        closeModal={() => {
          setOpenDownloadModal(false);
        }}
      />
    </>
  );
};
