import { MouseEventHandler } from 'react';

import { Box, FormControl, Stack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { RippleCheckbox, RippleCheckboxGroup, RippleCheckboxGroupProps, RippleCheckboxProps } from '@/design';
import { SurveyFormScenario, SurveyFormScenarioMap } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

import { Label } from '.';
import { DragButton } from '../EditMode';

type CheckboxFieldProps = {
  registerFieldName: string;
  defaultValue: RippleCheckboxGroupProps['defaultValue'];
  isRequired: RippleCheckboxProps['isRequired'];
  isInvalid?: RippleCheckboxProps['isInvalid'];
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  onMouseDown?: RippleCheckboxProps['onMouseDown'];
  options: Array<{ option: string }> | null;
  scenario: SurveyFormScenario;
};

const checkboxSharedProps = {
  id: 'radio-button',
  'aria-describedby': 'radio-button',
  borderRadius: '4px',
  mt: '4px',
  px: '4px',
};

export const CheckboxField = ({
  registerFieldName,
  defaultValue,
  isRequired,
  isInvalid,
  labelValue,
  onClickLabel,
  onMouseDown,
  options,
  scenario,
}: CheckboxFieldProps) => {
  const { control } = useFormContext();

  const optionsMap: Record<string, number> = {};

  const optionIndexMap = options?.reduce((acc, optionInfo, index) => {
    acc[optionInfo.option] = index;
    return acc;
  }, optionsMap);

  return (
    <Box w="100%" p="16px 48px 24px" position="relative" role="group">
      {scenario === SurveyFormScenarioMap.editor && <DragButton />}
      <FormControl isRequired={isRequired} isInvalid={isInvalid}>
        <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
        {scenario === SurveyFormScenarioMap.editor ? (
          <Box borderColor="neutral.300" _hover={{ bg: 'blue.0', cursor: 'pointer' }} onMouseDown={onMouseDown} {...checkboxSharedProps}>
            <RippleCheckboxGroup value={defaultValue} onChange={() => null}>
              <Options options={options} />
            </RippleCheckboxGroup>
          </Box>
        ) : (
          <Controller
            name={registerFieldName}
            control={control}
            defaultValue={defaultValue}
            rules={{ required: isRequired }}
            render={({ field: { value, onChange } }) => {
              const handleChange = (values: Array<string | number>) => {
                // Re-order values in option list's order to make sure user can see checkbox's answer in order on report page
                const sortedValues = optionIndexMap ? [...values].sort((a, b) => optionIndexMap[a] - optionIndexMap[b]) : values;
                onChange(sortedValues);
              };
              return (
                <Box onMouseDown={onMouseDown} {...checkboxSharedProps}>
                  <RippleCheckboxGroup value={value} onChange={handleChange}>
                    <Options options={options} />
                  </RippleCheckboxGroup>
                </Box>
              );
            }}
          />
        )}
      </FormControl>
    </Box>
  );
};

const Options = ({ options }: { options: CheckboxFieldProps['options'] }) => {
  return (
    <Stack gap="4px">
      {options && options?.length > 0 ? (
        options.map(({ option }) => (
          <RippleCheckbox key={option} value={option} id={option} w="fit-content" minH="32px" h="auto" alignItems="center">
            {option}
          </RippleCheckbox>
        ))
      ) : (
        <Box h="64px" />
      )}
    </Stack>
  );
};
