import Head from 'next/head';
import React from 'react';

import { BoxProps, SystemStyleObject } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import NavigationIframe from '@/components/Navigation/NavigationIframe';
import { useNavType } from '@/showcase';
import { PageFooter } from '@/showcase/Layout';

import Container from './Container';
import Wrapper from './Wrapper';

type CommonLayoutProps = {
  children: React.ReactNode;
  innerPaddingTop?: string | number;
  containerMiniHeight?: string;
  containerWidth?: BoxProps['width'];
  containerSx?: BoxProps;
  hideFooter?: boolean;
  sx?: SystemStyleObject;
};

const Layout = ({
  children,
  innerPaddingTop = '15px',
  containerMiniHeight = 'initial',
  containerWidth = ['100%', 750, 970, 1170],
  containerSx,
  hideFooter = false,
  sx,
}: CommonLayoutProps) => {
  const { t } = useTranslation();
  const navType = useNavType();

  return (
    <Wrapper
      sx={sx}
      // Minus 1px for hiding scrollbar
      minHeight="calc(100vh - 1px)"
    >
      <Head>
        <title>{t('common:title')}</title>
      </Head>
      <NavigationIframe navType={navType} />
      <Container width={containerWidth} paddingTop={innerPaddingTop} minHeight={containerMiniHeight} sx={containerSx}>
        {children}
      </Container>
      {!hideFooter && <PageFooter />}
    </Wrapper>
  );
};

export { Layout };
