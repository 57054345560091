// import { useTranslation } from 'react-i18next';
import { RippleMultipleSelect, RippleMultipleSelectOption, RippleMultipleSelectProps } from '@/design';
import { Day, dayList } from '@/utils/cron';

type DayPickerProps = Omit<RippleMultipleSelectProps<Day>, 'children' | 'placeholder'>;

export const DayPicker = ({ value, onChange, ...props }: DayPickerProps) => {
  // TODO: const { t } = useTranslation();

  const days = dayList.map((day) => {
    switch (day) {
      case '1':
        return { value: '1', label: '1st Day' };
      case '2':
        return { value: '2', label: '2nd Day' };
      case '3':
        return { value: '3', label: '3rd Day' };
      default:
        return { value: day, label: `${day}th Day` };
    }
  });

  return (
    <RippleMultipleSelect placeholder="" value={value} onChange={onChange} {...props}>
      {days.map(({ value: optionValue, label }) => (
        <RippleMultipleSelectOption key={optionValue} value={optionValue} isDisabled={value?.length === 1 && value[0] === optionValue}>
          {label}
        </RippleMultipleSelectOption>
      ))}
      <RippleMultipleSelectOption value="L">Last Day</RippleMultipleSelectOption>
    </RippleMultipleSelect>
  );
};
