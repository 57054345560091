import { Box, Flex, Portal, Stack, forwardRef } from '@chakra-ui/react';
import type { MenuButtonProps } from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';

import {
  RippleArrowDown16,
  RippleArrowUp16,
  RippleBodyText02,
  RippleButton,
  RippleCheckbox,
  RippleColumn,
  RippleDivider,
  RippleHeading09,
  RipplePopover,
  RipplePopoverBody,
  RipplePopoverContent,
  RipplePopoverTrigger,
} from '@/design';
import { featureControl } from '@/feature/toggle';

import { getInventoryColumnHeaders, inventoryPickColumnOrder } from './constant';

const FT_PCP_631__Inventory_OS_Columns = featureControl.getToggle('PCP_631__Inventory_OS_Columns');

export const defaultColumnsVisibilities = {
  computerName: true,
  groupName: true,
  reportTime: true,
  os: true,
  ...(FT_PCP_631__Inventory_OS_Columns
    ? {
        osBuild: false,
        architecture: false,
      }
    : {}),
  deviceName: true,
  domain: false,
  manufacturer: false,
  modelProduct: true,
  serialNumber: false,
  biosVersion: false,
  cpu: false,
  cpuSpeed: false,
  chipset: false,
  ram: false,
  totalFreeSpace: false,
  totalStorage: false,
  displayAdapter: false,
  ipAddress: true,
  timeZone: false,
  lastBoot: true,
  lastLoggedOn: true,
} as const;

export const allSelectedVisibilities = {
  computerName: true,
  groupName: true,
  reportTime: true,
  os: true,
  ...(FT_PCP_631__Inventory_OS_Columns
    ? {
        osBuild: true,
        architecture: true,
      }
    : {}),
  deviceName: true,
  domain: false,
  manufacturer: true,
  modelProduct: true,
  serialNumber: false,
  biosVersion: false,
  cpu: true,
  cpuSpeed: false,
  chipset: false,
  ram: true,
  totalFreeSpace: true,
  totalStorage: true,
  displayAdapter: false,
  ipAddress: true,
  timeZone: true,
  lastBoot: true,
  lastLoggedOn: true,
} as const;

export const unselectedVisibilities = {
  computerName: true,
  groupName: true,
  reportTime: true,
  os: false,
  ...(FT_PCP_631__Inventory_OS_Columns
    ? {
        osBuild: false,
        architecture: false,
      }
    : {}),
  deviceName: false,
  domain: false,
  manufacturer: false,
  modelProduct: false,
  serialNumber: false,
  biosVersion: false,
  cpu: false,
  cpuSpeed: false,
  chipset: false,
  ram: false,
  totalFreeSpace: false,
  totalStorage: false,
  displayAdapter: false,
  ipAddress: false,
  timeZone: false,
  lastBoot: false,
  lastLoggedOn: false,
} as const;

export type CustomizableColumnProps = MenuButtonProps & { isOpen: boolean };
export const CustomizableColumn = forwardRef<CustomizableColumnProps, 'button'>(({ isOpen, ...otherProps }, ref) => {
  const defaultColor = 'neutral.300';
  const activeColor = 'blue.200';
  const hoverColor = 'blue.100';

  return (
    <RippleButton
      variant="ghost"
      border={0}
      borderRadius="4px"
      width="46px"
      height="32px"
      padding="4px"
      backgroundColor={isOpen ? 'blue.20' : 'transparent'}
      _hover={{
        bg: 'blue.10',
        svg: {
          color: isOpen ? activeColor : hoverColor,
        },
      }}
      ref={ref}
      {...otherProps}
    >
      <Flex justifyContent="center" alignItems="center">
        <RippleColumn color={isOpen ? activeColor : defaultColor} />
        {isOpen ? <RippleArrowUp16 color={activeColor} /> : <RippleArrowDown16 color={defaultColor} />}
      </Flex>
    </RippleButton>
  );
});

export type ColumnSelectorProps = {
  visibilities: Record<string, boolean>;
  onChange: (visibilities: Record<string, boolean>) => void;
};

const ColumnSelector = ({ visibilities, onChange }: ColumnSelectorProps) => {
  const { t } = useTranslation();
  const columnHeaders = getInventoryColumnHeaders(t);
  const [_computerName, _groupName, _reportTime, ...optionalColumnOrder] = inventoryPickColumnOrder;

  const isAllSelectedValues = isEqual(visibilities, allSelectedVisibilities);
  const isUnselectedValue = isEqual(visibilities, unselectedVisibilities);
  const handleChangeVisibilities = (columnName: string) => () => {
    const newVisibilities = { ...visibilities, [columnName]: !visibilities[columnName] };
    onChange(newVisibilities);
  };

  const handleSelectAll = () => {
    onChange(allSelectedVisibilities);
  };
  const handleUnselectAll = () => {
    onChange(unselectedVisibilities);
  };

  return (
    <RipplePopover placement="bottom-end">
      {({ isOpen }) => (
        <>
          <RipplePopoverTrigger>
            <CustomizableColumn data-testid="customizable-column-btn" isOpen={isOpen} />
          </RipplePopoverTrigger>

          <Portal>
            <RipplePopoverContent width="480px" data-testid="customizable-column-menu">
              <RipplePopoverBody display="flex" flexDirection="column">
                <Flex width="100%" height="32px" justifyContent="space-between">
                  <Flex alignItems="center">
                    <RippleHeading09>{t('common:table.optionalColumns')}</RippleHeading09>
                  </Flex>
                  <Stack direction="row" alignItems="center" spacing="8px">
                    <RippleButton height="32px" variant="grayScaleGhost" disabled={isAllSelectedValues} onClick={handleSelectAll}>
                      <RippleHeading09 as="span" color={isAllSelectedValues ? 'dark.40' : 'dark.90'}>
                        {t('common:selectAll')}
                      </RippleHeading09>
                    </RippleButton>

                    <RippleButton height="32px" variant="grayScaleGhost" disabled={isUnselectedValue} onClick={handleUnselectAll}>
                      <RippleHeading09 as="span" color={isUnselectedValue ? 'dark.40' : 'dark.90'}>
                        {t('common:unselectAll')}
                      </RippleHeading09>
                    </RippleButton>
                  </Stack>
                </Flex>

                <Flex width="100%">
                  <Box padding="12px 8px" width="240px">
                    {optionalColumnOrder.slice(0, Math.ceil(optionalColumnOrder.length / 2)).map((columnName, index) => {
                      return (
                        <Flex key={index} alignItems="center" padding="10px 12px">
                          <RippleCheckbox isChecked={visibilities[columnName]} onChange={handleChangeVisibilities(columnName)} />
                          <RippleBodyText02 as="span" ml="8px" color="dark.90" onClick={handleChangeVisibilities(columnName)}>
                            {columnHeaders[columnName]}
                          </RippleBodyText02>
                        </Flex>
                      );
                    })}
                  </Box>
                  <RippleDivider orientation="vertical" />
                  <Box padding="12px 8px" width="240px">
                    {optionalColumnOrder.slice(Math.ceil(optionalColumnOrder.length / 2)).map((columnName, index) => {
                      return (
                        <Flex key={index} alignItems="center" padding="10px 12px">
                          <RippleCheckbox isChecked={visibilities[columnName]} onChange={handleChangeVisibilities(columnName)} />
                          <RippleBodyText02 as="span" ml="8px" color="dark.90" onClick={handleChangeVisibilities(columnName)}>
                            {columnHeaders[columnName]}
                          </RippleBodyText02>
                        </Flex>
                      );
                    })}
                  </Box>
                </Flex>
              </RipplePopoverBody>
            </RipplePopoverContent>
          </Portal>
        </>
      )}
    </RipplePopover>
  );
};

export default ColumnSelector;
