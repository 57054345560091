import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';

import { Flex, HStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithImmer } from 'jotai-immer';
import { atomWithReset, selectAtom, useResetAtom } from 'jotai/utils';
import chunk from 'lodash/chunk';
import { Trans, useTranslation } from 'next-i18next';
import { Virtuoso } from 'react-virtuoso';

import {
  RippleBadge,
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
} from '@/design';
import { featureControl } from '@/feature/toggle';
import { deleteComputer, removeExternalSharedComputer } from '@/services/computers';
import { ModalGridTable, ModalGridTableContainer, ModalGridTbody, ModalGridTd, ModalGridTh, ModalGridThead, ModalGridTr } from '@/showcase';
import { criticalBulkActionCall } from '@/utils/bulkAction';

import { computerCountAtom, computerDataMapAtom, rowSelectionStateAtom } from '../atoms';
import { TargetComputerItem } from './types';

/**
 * The browser automatically limits the amount of concurrent requests, which is usually 6.
 * Therefore, we define a chunk size as a multiple of 6.
 */
const DELETE_COMPUTER_CHUNK = 360;

export const resultModalAtom = atomWithReset<{ isOpen: boolean; targetComputerList: Array<TargetComputerItem> }>({
  isOpen: false,
  targetComputerList: [],
});

export function useResultModal() {
  const set = useSetAtom(resultModalAtom);

  return {
    open({ targetComputerList }: { targetComputerList: Array<{ id: string; name: string }> }) {
      set({ isOpen: true, targetComputerList });
    },
  };
}

type Status = 'pending' | 'success' | 'failed';
type StatusMap = Record<string, Status>;
const statusMapAtom = atomWithImmer<StatusMap>({});

export function ResultModal(): React.JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();

  const { isOpen, targetComputerList } = useAtomValue(resultModalAtom);
  const resetModal = useResetAtom(resultModalAtom);
  const setStatusMapAtom = useSetAtom(statusMapAtom);
  const setComputerDataMap = useSetAtom(computerDataMapAtom);
  const setRowSelectionState = useSetAtom(rowSelectionStateAtom);
  const computerCount = useAtomValue(computerCountAtom);

  const execute = useCallback(
    (computer: TargetComputerItem) =>
      deleteComputerService(Number(computer.id), Boolean(computer.isFromExternalShared))
        .then(() => {
          setStatusMapAtom((draft) => {
            draft[computer.id] = 'success';
          });

          return computer.id;
        })
        .catch(() => {
          setStatusMapAtom((draft) => {
            draft[computer.id] = 'failed';
          });

          return null;
        }),
    [setStatusMapAtom],
  );

  /**
   * Send requests in each chunk to avoid sending too many requests at the same time,
   * which might cause the browser to block them.
   **/
  const deleteComputersByChunk = useCallback(
    async (targetComputerList: Array<TargetComputerItem>) => {
      if (featureControl.getToggle('PCP_2039__AllComputers__AdjustChunkForDeleteComputers')) {
        return criticalBulkActionCall(targetComputerList.map((computer) => () => execute(computer)));
      } else {
        const chunkList = chunk(targetComputerList, DELETE_COMPUTER_CHUNK);
        const resultList: Array<string | null> = [];

        for (const computerList of chunkList) {
          resultList.push(...(await Promise.all(computerList.map(execute))));
        }

        return resultList;
      }
    },
    [execute],
  );

  useEffect(
    function resetStatusMap() {
      setStatusMapAtom(
        targetComputerList.reduce<StatusMap>((acc, cur) => {
          acc[cur.id] = 'pending';
          return acc;
        }, {}),
      );
    },
    [setStatusMapAtom, targetComputerList],
  );

  useEffect(
    function executeDeleteComputers() {
      deleteComputersByChunk(targetComputerList).then((resultList) => {
        const successIdList = resultList.filter((result) => result !== null) as Array<string>;

        setComputerDataMap((draft) => {
          successIdList.forEach((computerId) => {
            delete draft[computerId];
          });
        });

        setRowSelectionState((draft) => {
          successIdList.forEach((computerId) => {
            delete draft[computerId];
          });
        });
      });
    },
    [deleteComputersByChunk, setComputerDataMap, setRowSelectionState, setStatusMapAtom, targetComputerList],
  );

  function handleClose(): void {
    if (computerCount === 0) {
      // Delete to empty, refresh page to show OOBE
      router.reload();
    } else {
      resetModal();
    }
  }

  return (
    <RippleModal isOpen={isOpen} onClose={handleClose} size="xl" closeOnEsc={false} closeOnOverlayClick={false}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:delete_computers')}</RippleModalTitle>
        </RippleModalHeader>

        <RippleModalBody display="flex" flexDirection="column">
          <StatusSummary />

          <RippleBodyText02 mt="8px">{t('computer:deleteComputersWording')}</RippleBodyText02>

          <ModalGridTableContainer flex="1" mt="8px">
            <ModalGridTable display="block">
              <ModalGridThead>
                <Flex bg="white">
                  <ModalGridTr>
                    <ModalGridTh w="300px">{t('common:name')}</ModalGridTh>
                    <ModalGridTh w="100px">{t('computer:status')}</ModalGridTh>
                  </ModalGridTr>
                </Flex>
              </ModalGridThead>
              <ModalGridTbody>
                <Virtuoso
                  style={{ height: 300 }}
                  data={targetComputerList}
                  itemContent={(_index, computer) => <StatusItem computer={computer} />}
                />
              </ModalGridTbody>
            </ModalGridTable>
          </ModalGridTableContainer>
        </RippleModalBody>

        <RippleModalFooter>
          <CloseButton onClick={handleClose} />
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

type StatusItemProps = { computer: TargetComputerItem };
function StatusItem({ computer }: StatusItemProps): React.JSX.Element {
  const { t } = useTranslation();
  const statusAtom = useMemo(() => selectAtom(statusMapAtom, (statusMap) => statusMap[computer.id]), [computer.id]);
  const status = useAtomValue(statusAtom);
  const statusBadge = computeStatusBadge();

  return (
    <Flex>
      <ModalGridTr>
        <ModalGridTd w="300px" wordBreak="break-all">
          {computer.name}
        </ModalGridTd>
        <ModalGridTd w="100px">{statusBadge}</ModalGridTd>
      </ModalGridTr>
    </Flex>
  );

  function computeStatusBadge() {
    switch (status) {
      case 'success': {
        return (
          <RippleBadge variant="secondary" colorScheme="success">
            {t('common:success')}
          </RippleBadge>
        );
      }
      case 'failed': {
        return (
          <RippleBadge variant="secondary" colorScheme="danger">
            {t('common:failed')}
          </RippleBadge>
        );
      }

      case 'pending':
      default: {
        return (
          <RippleBadge variant="secondary" colorScheme="grey">
            {t('common:pending')}
          </RippleBadge>
        );
      }
    }
  }
}

function StatusSummary(): React.JSX.Element {
  const { t } = useTranslation();
  const statusMap = useAtomValue(statusMapAtom);

  const { targetComputerList } = useAtomValue(resultModalAtom);
  const totalTargetCount = targetComputerList.length;
  const { success, failed } = computeSummary(statusMap);

  return (
    <HStack spacing="4px">
      <RippleBodyText02 color="neutral.200">
        <Trans
          t={t}
          i18nKey="computer:deleteComputers.resultModal.summary"
          components={{ FailedText: <RippleBodyText02 as="span" color={failed > 0 ? 'red.100' : 'neutral.200'} /> }}
          values={{ total: totalTargetCount, success, failed }}
        />
      </RippleBodyText02>
    </HStack>
  );
}

type Summary = { success: number; failed: number; total: number };
function computeSummary(statusMap: StatusMap): Summary {
  return Object.values(statusMap).reduce<Summary>(
    (acc: Summary, status) => {
      switch (status) {
        case 'success': {
          acc.success += 1;
          acc.total += 1;

          break;
        }
        case 'failed': {
          acc.failed += 1;
          acc.total += 1;

          break;
        }
        case 'pending':
        default:
      }
      return acc;
    },
    {
      success: 0,
      failed: 0,
      total: 0,
    },
  );
}

function CloseButton({ onClick }: { onClick: () => void }): React.JSX.Element {
  const { t } = useTranslation();

  const statusMap = useAtomValue(statusMapAtom);
  const { targetComputerList } = useAtomValue(resultModalAtom);

  const { total } = computeSummary(statusMap);

  const isProcessing = total !== targetComputerList.length;

  return (
    <RippleButton data-testid="ok-button" variant="primary" onClick={onClick} isLoading={isProcessing} isDisabled={isProcessing}>
      {t('common:ok')}
    </RippleButton>
  );
}

function deleteComputerService(computerId: number, isExternalShared: boolean) {
  if (isExternalShared) {
    return removeExternalSharedComputer.service(computerId);
  }

  return deleteComputer.service(computerId);
}
