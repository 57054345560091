import { Image } from '@chakra-ui/react';

import type { TeamMemberRoleTag } from './types';

const roleIconURL: Record<TeamMemberRoleTag, string> = {
  owner: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_owner.svg',
  superAdmin: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_super-admin.svg',
  admin: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_admin.svg',
  groupAdmin: '//files.splashtop.com/backend/assets/ucp/icon_24x24px_role_group-admin.svg',
  member: '//files.splashtop.com/backend-develop/assets/ucp/icon_24x24px_role_member.svg',
};

type TeamMemberRoleIconProps = {
  roleTag: TeamMemberRoleTag;
};

export const TeamMemberRoleIcon = ({ roleTag }: TeamMemberRoleIconProps) => {
  const imageURL = roleIconURL[roleTag];
  return <Image boxSize="24px" alt={`team member role ${roleTag}`} src={imageURL} />;
};
