import { forwardRef, useEffect, useRef } from 'react';

import { Box, useToken } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { RippleTextarea, RippleTextareaProps } from '@/design';
import { useCurrentOpenFieldValue } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';

import { ActionBar, FieldTitle, Highlighter } from '.';
import { DESCRIPTION_MAX_LENGTH } from '../../constant';

type WelcomeMessageEditModeProps = {
  titleProps: UseFormRegisterReturn;
  subtitleProps: UseFormRegisterReturn;
  onDelete: () => void;
  onSave: () => void;
};

export const WelcomeMessageEditMode = ({ titleProps, subtitleProps, onDelete, onSave }: WelcomeMessageEditModeProps) => {
  const { t } = useTranslation();
  const currentOpenField = useCurrentOpenFieldValue();

  const titleRef = useRef<HTMLTextAreaElement | null>(null);
  const subtitleRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    // set default height when user trigger edit mode
    if (titleRef.current) {
      titleRef.current.style.height = '0px';
      titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
    }
    if (subtitleRef.current) {
      subtitleRef.current.style.height = '0px';
      subtitleRef.current.style.height = `${subtitleRef.current.scrollHeight}px`;
    }
  }, []);

  return (
    <Box w="100%" p="0px 48px 8px" position="relative">
      <Highlighter />
      <FieldTitle title={t('survey:welcome_message')} />
      <TextAreaBase
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        placeholder={t('survey:enter_welcome_message_title')}
        isAutoExpand
        mb="12px"
        fontSize="24px"
        color="dark.100"
        fontWeight={700}
        _placeholder={{ pt: '4px' }}
        maxLength={64}
        {...titleProps}
        ref={(e: HTMLTextAreaElement) => {
          titleProps.ref(e);
          titleRef.current = e;
        }}
      />
      <TextAreaBase
        autoFocus={currentOpenField?.autoFocusType === 'input'}
        placeholder={t('survey:enter_welcome_message_body_text')}
        isAutoExpand
        mb="20px"
        fontSize="16px"
        color="neutral.300"
        fontWeight={400}
        maxLength={DESCRIPTION_MAX_LENGTH}
        {...subtitleProps}
        ref={(e: HTMLTextAreaElement) => {
          subtitleProps.ref(e);
          subtitleRef.current = e;
        }}
      />
      <ActionBar isShowRequired={false} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
};

const TextAreaBase = forwardRef((props: RippleTextareaProps, ref) => {
  const [blue100] = useToken('colors', ['blue.100']);

  return (
    <RippleTextarea
      ref={ref}
      sx={{ width: '100%', caretColor: blue100 }}
      isAutoExpand
      rows={1}
      resize="none"
      textAlign="center"
      lineHeight={1.5}
      border="none"
      borderBottom="1px solid"
      borderRadius={0}
      borderBottomColor="neutral.300"
      background="white"
      {...props}
      _placeholder={{
        ...props._placeholder,
        color: 'dark.40',
        fontSize: '16px',
        fontStyle: 'italic',
        fontWeight: 400,
      }}
      _hover={{
        background: 'blue.0',
        borderColor: 'neutral.300',
      }}
      _focus={{
        background: 'blue.10',
        borderColor: 'blue.100',
        color: 'dark.100',
      }}
      _focusVisible={{
        border: 'none',
        borderColor: 'blue.100',
        boxShadow: `0px 1px 0px 0px ${blue100}`,
      }}
    />
  );
});
