import { MouseEventHandler, forwardRef } from 'react';

import { Box, FormControl } from '@chakra-ui/react';

import { RippleTextarea, RippleTextareaProps } from '@/design';
import { TEXTAREA_MAX_LENGTH } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { SurveyFormScenario, SurveyFormScenarioMap } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

import { Label } from '.';
import { DragButton } from '../EditMode';

type LongTextFieldProps = RippleTextareaProps & {
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  scenario: SurveyFormScenario;
};

export const LongTextField = forwardRef(
  ({ isRequired, labelValue, onClickLabel, onMouseDown, placeholder, scenario, ...textareaInputProps }: LongTextFieldProps, ref) => {
    return (
      <Box w="100%" p="16px 48px 24px" position="relative" role="group">
        {scenario === SurveyFormScenarioMap.editor && <DragButton />}
        <FormControl isRequired={isRequired} id={labelValue}>
          <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
          <RippleTextarea
            aria-describedby={labelValue}
            mt="4px"
            maxLength={TEXTAREA_MAX_LENGTH}
            height="120px"
            overflow="auto"
            resize="none"
            fontSize="14px"
            placeholder={placeholder}
            _hover={scenario === SurveyFormScenarioMap.editor ? { bg: 'blue.0', cursor: 'pointer' } : {}}
            onMouseDown={onMouseDown}
            ref={ref}
            {...textareaInputProps}
          />
        </FormControl>
      </Box>
    );
  },
);
