import { z } from 'zod';

import { ValueOf } from '@/types';

export const GranularControlKeys = {
  attended_access: 'attended_access',
  file: 'file',
  remote_print: 'remote_print',
  copy_paste: 'copy_paste',
  required_2sv: 'required_2sv',
  one_to_many: 'one_to_many',
  command_prompt: 'command_prompt',
  watermark: 'watermark',
  remote_control: 'remote_control',
  conn_perm: 'conn_perm',
  conn_perm_setting: 'conn_perm_setting',
  relay_recording: 'relay_recording',
  sos_relay_recording: 'sos_relay_recording',
} as const;

export type GranularControlKeyValues = ValueOf<typeof GranularControlKeys>;
export type GranularControlKeyValuesConfigurable = Exclude<GranularControlKeyValues, 'conn_perm_setting'>;

export const ConnectPermissionSettingStates = {
  rejectExpiredAndRejectOnLoginScreen: 'rejectExpiredAndRejectOnLoginScreen',
  rejectExpiredAndAllowOnLoginScreen: 'rejectExpiredAndAllowOnLoginScreen',
  allowExpired: 'allowExpired',
  disabled: 'disabled',
} as const;

export type ConnectPermissionSettingStateValues = ValueOf<typeof ConnectPermissionSettingStates>;

export const ConnectPermissionSchema = z.union([
  z.object({
    setting: z.literal(1),
    option: z.union([z.literal(1), z.literal(2)]),
  }),
  z.object({
    setting: z.union([z.literal(0), z.literal(2)]),
    option: z.literal(null),
  }),
]);

/**
 * { setting: 1, option: 1 }, 請求過期後拒絕連接（在登入屏幕上，自動拒絕）
 * { setting: 1, option: 2 }, 請求過期後拒絕連接（在登入屏幕上，自動允許）
 * { setting: 2, option: nil }, 仍允許連線在請求逾時之後
 * { setting: 0, option: nil }, 關閉
 */
export const ConnectPermissionSettingHashMaps: Record<ConnectPermissionSettingStateValues, z.infer<typeof ConnectPermissionSchema>> = {
  rejectExpiredAndRejectOnLoginScreen: { setting: 1, option: 1 },
  rejectExpiredAndAllowOnLoginScreen: { setting: 1, option: 2 },
  allowExpired: { setting: 2, option: null },
  disabled: { setting: 0, option: null },
} as const;

export const toConnectPermissionState = (value?: z.infer<typeof ConnectPermissionSchema> | null): ConnectPermissionSettingStateValues => {
  if (!value) {
    return ConnectPermissionSettingStates.disabled;
  }

  const foundKey = Object.keys(ConnectPermissionSettingHashMaps).find((key) => {
    const target = ConnectPermissionSettingHashMaps[key as ConnectPermissionSettingStateValues];

    return target.setting === value.setting && target.option === value.option;
  });

  return foundKey as ConnectPermissionSettingStateValues;
};

export const toConnectPermissionSetting = (
  key: ConnectPermissionSettingStateValues | undefined,
): z.infer<typeof ConnectPermissionSchema> => {
  return key ? ConnectPermissionSettingHashMaps[key] : ConnectPermissionSettingHashMaps.disabled;
};

const UserGranularControlSettingSchema = z.enum(['on', 'off', 'follow_team', 'follow_group']);
export const UserGranularControlSettingEnum = UserGranularControlSettingSchema.enum;
export type UserGranularControlSettingValues = z.infer<typeof UserGranularControlSettingSchema>;

export const UserGranularControlSettingsSchema = z.object({
  [GranularControlKeys.attended_access]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.file]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.remote_print]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.copy_paste]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.required_2sv]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.one_to_many]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.command_prompt]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.watermark]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.remote_control]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.conn_perm]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.conn_perm_setting]: ConnectPermissionSchema.optional(),
  [GranularControlKeys.relay_recording]: UserGranularControlSettingSchema.optional(),
  [GranularControlKeys.sos_relay_recording]: UserGranularControlSettingSchema.optional(),
});
