import Link from 'next/link';
import { useMemo } from 'react';

import { Center, HStack } from '@chakra-ui/react';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import {
  RippleHighlight,
  RippleIconButton,
  RippleInfo16,
  RippleInherited,
  RippleMenu,
  RippleMenuIconButton,
  RippleMenuItem,
  RippleMenuList,
  RippleMore,
  RippleSkeleton,
  RippleSwitch,
  RippleTooltip,
  RippleTypography,
} from '@/design';

import { MAX_POLICY_DEPTH } from '../constants';
import { isPreparedDefaultPolicy } from '../utils';
import { PolicyAssignment } from './usePolicyRelationModel';

const columnsHelper = createColumnHelper<PolicyAssignment>();
type Node = PolicyAssignment['policyNode'];

type usePolicyColumnsProps = {
  isLoading: boolean;
  hideMenu: boolean;
  searchKeyword: string;
  isTeamExpired: boolean;
  onEdit: (node: Node) => void;
  onClone: (node: Node) => void;
  onAssign: (node: Node) => void;
  onDelete: (node: Node) => void;
  onChildCreate: (node: Node) => void;
  onStatusChange: (node: Node) => void;
};
export const usePolicyColumns = ({
  isLoading,
  isTeamExpired,
  hideMenu,
  searchKeyword,
  onEdit,
  onClone,
  onAssign,
  onDelete,
  onChildCreate,
  onStatusChange,
}: usePolicyColumnsProps) => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      // policy name
      columnsHelper.accessor('policyNode.policy.name', {
        header: () => <TooltipsHeader>{t('common:name')}</TooltipsHeader>,
        cell: ({ getValue, row }) => {
          if (isLoading) {
            return <RippleSkeleton width="70%" />;
          }
          return (
            <HStack
              as={Link}
              href={`/w/policy/${row.original.policyNode.policy.id}/streamer_preference`}
              flex="1"
              style={{ marginLeft: `${8 + Math.max(0, row.original.policyNode.depth - 1) * 16}px` }}
              w="100%"
              h="100%"
            >
              {row.original.policyNode.depth > 0 && <RippleInherited />}
              <HStack spacing="8px">
                <RippleTooltip label={getValue()} aria-label={getValue()}>
                  <RippleTypography variant="body02" noOfLines={2} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                    <RippleHighlight query={searchKeyword}>{String(getValue() ?? '')}</RippleHighlight>
                  </RippleTypography>
                </RippleTooltip>
                {row.original.policyNode.policy.super_root && (
                  <RippleTooltip
                    aria-label={t('emm-policy:this_built_in_policy_is_applied_to_all_groups_and_computers_by_default')}
                    label={t('emm-policy:this_built_in_policy_is_applied_to_all_groups_and_computers_by_default')}
                  >
                    <span>
                      <RippleInfo16 />
                    </span>
                  </RippleTooltip>
                )}
              </HStack>
            </HStack>
          );
        },
        enableSorting: false,
        size: 400,
      }),
      columnsHelper.accessor('groupCount', {
        id: 'group',
        header: () => <TooltipsHeader>{t('emm-policy:group')}</TooltipsHeader>,
        cell: ({ getValue }) => {
          if (isLoading) {
            return <RippleSkeleton width="70%" />;
          }
          return (
            <RippleTypography variant="body02">
              <RippleHighlight query={searchKeyword}>{String(getValue())}</RippleHighlight>
            </RippleTypography>
          );
        },
        size: 124,
        enableSorting: false,
      }),
      columnsHelper.accessor('serverCount', {
        id: 'endpoint',
        header: () => <TooltipsHeader>{t('emm-policy:computer')}</TooltipsHeader>,
        cell: ({ getValue }) => {
          if (isLoading) {
            return <RippleSkeleton width="70%" />;
          }
          return (
            <RippleTypography variant="body02">
              <RippleHighlight query={searchKeyword}>{String(getValue())}</RippleHighlight>
            </RippleTypography>
          );
        },
        size: 124,
        enableSorting: false,
      }),
      columnsHelper.accessor('overriddenCount', {
        id: 'override',
        header: () => <TooltipsHeader>{t('emm-policy:override')}</TooltipsHeader>,
        cell: ({ getValue }) => {
          if (isLoading) {
            return <RippleSkeleton width="70%" />;
          }
          return (
            <RippleTypography variant="body02">
              <RippleHighlight query={searchKeyword}>{String(getValue())}</RippleHighlight>
            </RippleTypography>
          );
        },
        enableSorting: false,
        size: 124,
      }),
      columnsHelper.accessor('formatedLastUpdated', {
        header: () => <TooltipsHeader>{t('common:lastUpdated')}</TooltipsHeader>,
        cell: ({ getValue, row }: CellContext<PolicyAssignment, string>) => {
          if (isPreparedDefaultPolicy(row.original.policyNode.policy.id)) {
            return <></>;
          }
          if (isLoading) {
            return <RippleSkeleton width="70%" />;
          }
          return (
            <RippleTypography variant="body02" noOfLines={2}>
              <RippleHighlight query={searchKeyword}>{getValue()}</RippleHighlight>
            </RippleTypography>
          );
        },
        size: 242,
        enableSorting: false,
      }),
      columnsHelper.accessor('policyNode.policy.active', {
        header: () => <div />,
        cell: ({ row, getValue }: CellContext<PolicyAssignment, boolean>) => {
          if (isLoading) {
            return <RippleSkeleton width="44px" m="auto" />;
          }
          return (
            <Center w="100%">
              <RippleSwitch
                isChecked={Boolean(getValue())}
                isDisabled={isTeamExpired}
                onChange={() => onStatusChange(row.original.policyNode)}
                aria-label="Status"
              />
            </Center>
          );
        },
        size: 68,
        enableSorting: false,
      }),
      columnsHelper.display({
        id: 'actions',
        header: () => <div />,
        cell: ({ row }) => {
          // Optimization for Chakra rendering overlapping elements
          if (hideMenu) {
            return <RippleIconButton aria-label="more actions" icon={<RippleMore />} isDisabled={isTeamExpired} />;
          }
          if (isLoading) {
            return <RippleSkeleton width="24px" m="auto" />;
          }
          const node = row.original.policyNode;
          const isExceedMaxPolicyDepth = node.depth >= MAX_POLICY_DEPTH;
          return (
            <Center w="100%">
              <RippleMenu isLazy>
                <RippleMenuIconButton aria-label="more actions" icon={<RippleMore />} isDisabled={isTeamExpired} />
                <RippleMenuList zIndex="dropdown">
                  <RippleMenuItem onClick={() => onEdit(node)} isDisabled={isTeamExpired}>
                    {t('common:edit')}
                  </RippleMenuItem>
                  <RippleMenuItem onClick={() => onAssign(node)} isDisabled={isTeamExpired}>
                    {t('emm-policy:assign_group_and_computer')}
                  </RippleMenuItem>
                  <RippleMenuItem onClick={() => onChildCreate(node)} isDisabled={isTeamExpired || isExceedMaxPolicyDepth}>
                    {t('emm-policy:create_child_policy')}
                  </RippleMenuItem>
                  <RippleMenuItem onClick={() => onClone(node)} isDisabled={isTeamExpired}>
                    {t('common:clone')}
                  </RippleMenuItem>
                  <RippleMenuItem
                    isDisabled={isTeamExpired || isPreparedDefaultPolicy(node.policy.id) || node.policy.super_root}
                    onClick={() => onDelete(node)}
                  >
                    {t('common:delete')}
                  </RippleMenuItem>
                </RippleMenuList>
              </RippleMenu>
            </Center>
          );
        },
        size: 56,
        enableSorting: false,
      }),
    ],
    [hideMenu, isLoading, isTeamExpired, onAssign, onChildCreate, onClone, onDelete, onEdit, onStatusChange, searchKeyword, t],
  );
};

const TooltipsHeader = ({ children }: { children: string }) => (
  <RippleTooltip label={children} aria-label={children}>
    <RippleTypography as="span" variant="heading09" color="neutral.300" noOfLines={2}>
      {children}
    </RippleTypography>
  </RippleTooltip>
);
