import { type TFunction } from 'next-i18next';

import { TeamMember, TeamMemberRoleTag } from './types';

export const teamMemberRoleTagMapper = ({
  role,
  group_scope,
  super_admin,
}: Pick<TeamMember, 'role' | 'group_scope'> & Partial<Pick<TeamMember, 'super_admin'>>): TeamMemberRoleTag => {
  if (role === 'owner') return 'owner';
  if (super_admin) return 'superAdmin';
  if (role === 'manager' && group_scope === 'part') return 'groupAdmin';
  if (role === 'manager') return 'admin';

  return 'member';
};

const roleWeight: Record<TeamMemberRoleTag, number> = {
  owner: 5,
  superAdmin: 4,
  admin: 3,
  groupAdmin: 2,
  member: 1,
};
export function sortTeamMemberRole(a: TeamMemberRoleTag | null, b: TeamMemberRoleTag | null): number {
  if (a === null) return -1;
  if (b === null) return 1;
  return roleWeight[a] - roleWeight[b];
}

export function getTeamMemberRoleLabelMap(t: TFunction): Record<TeamMemberRoleTag, string> {
  return {
    owner: t('Owner'),
    superAdmin: t('Super Admin'),
    admin: t('Admin'),
    member: t('Member'),
    groupAdmin: t('Group Admin'),
  };
}
