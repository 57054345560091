import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import {
  useBrandImageHandler,
  useCurrentOpenField,
  useCustomImageFileValue,
  useWelcomeMessageHandler,
} from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormScenarioMap, SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

import { BrandImageEditMode, WelcomeMessageEditMode } from '.';
import { BRAND_ICON_UUID, WELCOME_UUID } from '../../constant';
import { AddHeaderSection } from '../EmptyState';
import { BrandImage, HeaderDivider, WelcomeMessage } from '../ViewMode';

type HeaderProps = {
  isNoQuestionnaire: boolean;
  onSaveField: () => void;
};

export const Header = ({ isNoQuestionnaire, onSaveField }: HeaderProps) => {
  const { watch, register } = useFormContext<SurveyFormValue>();
  const [title, subtitle] = watch(['welcome_message.title', 'welcome_message.subtitle']);

  const [currentOpenFieldValue] = useCurrentOpenField();

  const { isShowBrandImage, isShowSplashtopLogo, deleteBrandImageField, editBrandImageField } = useBrandImageHandler();
  const customImageFile = useCustomImageFileValue();

  const { noWelcomeMessageField, deleteWelcomeMessageField, editWelcomeMessageTitle, editWelcomeMessageSubtitle } =
    useWelcomeMessageHandler();

  const isShowWelcomeMessage = !noWelcomeMessageField;
  const isShowHeader = isShowWelcomeMessage || isShowBrandImage;
  const isShowAddHeaderEmptyState = !isShowHeader && isNoQuestionnaire;

  if (isShowAddHeaderEmptyState) {
    return <AddHeaderSection />;
  }

  return (
    <>
      {isShowHeader && (
        <Flex w="88%" direction="column" align="center">
          {isShowBrandImage && (
            <>
              {currentOpenFieldValue?.uuid === BRAND_ICON_UUID ? (
                <BrandImageEditMode onSave={onSaveField} onDelete={deleteBrandImageField} />
              ) : (
                <BrandImage
                  scenario={SurveyFormScenarioMap.editor}
                  editBrandImageField={editBrandImageField}
                  isShowSplashtopLogo={isShowSplashtopLogo}
                  customLogoSrc={customImageFile.url}
                />
              )}
            </>
          )}
          {isShowWelcomeMessage && (
            <>
              {currentOpenFieldValue?.uuid === WELCOME_UUID ? (
                <WelcomeMessageEditMode
                  titleProps={{ ...register('welcome_message.title') }}
                  subtitleProps={{ ...register('welcome_message.subtitle') }}
                  onSave={onSaveField}
                  onDelete={deleteWelcomeMessageField}
                />
              ) : (
                <WelcomeMessage
                  scenario={SurveyFormScenarioMap.editor}
                  title={title}
                  subtitle={subtitle}
                  editTitle={editWelcomeMessageTitle}
                  editSubtitle={editWelcomeMessageSubtitle}
                />
              )}
            </>
          )}
          <HeaderDivider />
        </Flex>
      )}
    </>
  );
};
