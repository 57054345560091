import { Box, Flex, VStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleRadio, RippleRadioGroup } from '@/design';
import { useBrandImageHandler } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { BrandImageMap } from '@/services/serviceDesk/surveys/type';

import { ActionBar, FieldTitle, Highlighter, ImageUploader } from '.';
import { SplashtopLogo } from '../';

type BrandImageEditModeProps = {
  onDelete: () => void;
  onSave: () => void;
};

export function BrandImageEditMode({ onDelete, onSave }: BrandImageEditModeProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext<SurveyFormValue>();
  const { brandImage, isShowSplashtopLogo } = useBrandImageHandler();

  return (
    <Box w="100%" p="0px 48px 8px" position="relative">
      <Highlighter />
      <FieldTitle title={t('survey:brand_icon')} />
      <Flex justify="center">{isShowSplashtopLogo ? <SplashtopLogo /> : <ImageUploader />}</Flex>
      <RippleRadioGroup value={brandImage === null ? undefined : String(brandImage)} gap="4px">
        <VStack spacing="8px" alignItems="stretch" my="12px">
          <RippleRadio
            w="fit-content"
            value={String(BrandImageMap.splashtop)}
            onChange={() => setValue('brand_image', BrandImageMap.splashtop)}
          >
            {t('survey:use_splashtop_icon')}
          </RippleRadio>
          <RippleRadio w="fit-content" value={String(BrandImageMap.custom)} onChange={() => setValue('brand_image', BrandImageMap.custom)}>
            {t('survey:use_your_own_icon')}
          </RippleRadio>
        </VStack>
      </RippleRadioGroup>
      <ActionBar isShowRequired={false} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
}
