// @ts-nocheck
import { useEffect } from 'react';
import { TEST_ONLY, featureControl } from '@/feature/toggle';

declare global {
  interface Window {
    zESettings: any;
    zEmbed: any;
  }
}

type useZendeskProps = {
  product?: string;
  email?: string;
  contactFormSuppress?: boolean;
  enabled?: boolean;
};

export function useZendesk({ product, email, contactFormSuppress = false, enabled = true }: useZendeskProps) {
  useEffect(() => {
    if(TEST_ONLY || !enabled) return;
    if (window.zE && window.zE.show) window.zE.show();
    else if (email) {
      window.zESettings = {
        webWidget: {
          contactForm: {
            suppress: contactFormSuppress,
            title: { '*': 'Support Ticket' },
            fields: [{ id: 21087580, prefill: { '*': email } }],
          },
          chat: {
            suppress: false,
          },
          contactOptions: {
            enabled: true,
            contactFormLabel: { '*': 'Submit ticket' },
            contactButton: { '*': 'Ask Support' },
          },
        },
      };
      window.zEmbed||function(e,t){var n,o,d,i,s,a=[],r=document.createElement("iframe");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,this.zendeskHost="splashtopbusiness.zendesk.com",this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()}();
  
      if (product && product !== '') {
        zE(function () {
          zE.setHelpCenterSuggestions({ labels: [`${product} setup`] });
        });
      }
    }

    return () => {
      if (window.zE && window.zE.hide) window.zE.hide();
    }
  }, [email]);
}


// Reference: https://developer.zendesk.com/api-reference/widget/api/


export function useZendeskDisplay(show: boolean){
  useEffect(() => {
    if(TEST_ONLY || !featureControl.getToggle('PCP_2135__Zendesk_show_hide')) return;
    if(!window.zE) return;
    if(show && window.zE.show) {
      window.zE.show()
    } else if(!show && window.zE.hide) {
      window.zE.hide()
    }
  }, [show])
}
