import { useCallback, useRef, useState } from 'react';

import { Box, Fade, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleBodyText03, RippleButtonText, RippleDelete, RippleEdit, RippleIconButton } from '@/design';
import { useCustomImageFile, useResetCustomImageFile } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { fileFormat, fileSize } from '@/utils/checkFile';

import { LogoImage } from '../ViewMode';

const TWO_MB = 2 * 1024 * 1024;

export const ImageUploader = () => {
  const { t } = useTranslation();
  const [customImageFile, setCustomImageFile] = useCustomImageFile();
  const isShowUploadImageButton = customImageFile.url === '';
  const resetCustomImageFile = useResetCustomImageFile();

  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetFile = event?.target?.files?.[0];

    if (!targetFile) {
      return;
    }

    if (!fileSize(targetFile, TWO_MB)) {
      setErrorMessage(t('survey:file_size_exceeds_2_mb'));
      resetCustomImageFile();
      return;
    }

    if (!fileFormat(targetFile, ['image/jpeg', 'image/png', 'image/gif'])) {
      setErrorMessage(t('survey:invalid_format'));
      resetCustomImageFile();
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const base64String = event.target?.result;
      if (base64String && typeof base64String === 'string') {
        setCustomImageFile({ url: base64String, name: targetFile.name });
      }
    };
    reader.readAsDataURL(targetFile);
  };

  const resetInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  const handleUpload = useCallback(() => {
    resetInput();
    if (errorMessage) setErrorMessage('');
    inputRef.current?.click();
  }, [errorMessage, resetInput]);

  const handleDelete = useCallback(() => {
    resetInput();
    resetCustomImageFile();
  }, [resetInput, resetCustomImageFile]);

  return (
    <Box>
      <Fade in={!isShowUploadImageButton}>{!isShowUploadImageButton && <LogoImage src={customImageFile.url} />}</Fade>
      <Fade in={isShowUploadImageButton}>{isShowUploadImageButton && <UploadImageButton onClick={handleUpload} />}</Fade>
      <input
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg, image/gif"
        onChange={(e) => {
          handleImageChange(e);
        }}
        style={{ display: 'none' }}
      />
      {errorMessage ? (
        <RippleBodyText02 color="red.100" mt="4px" py="6px">
          {errorMessage}
        </RippleBodyText02>
      ) : (
        <Flex w="320px" gap="4px" align="center" mt="4px">
          {customImageFile.url ? (
            <>
              <RippleBodyText02 flex={1}>{customImageFile.name}</RippleBodyText02>
              <RippleIconButton
                variant="ghost"
                boxSize="32px"
                aria-label="edit"
                icon={<RippleEdit color="neutral.300" />}
                onClick={handleUpload}
              />
              <RippleIconButton
                variant="ghost"
                boxSize="32px"
                aria-label="delete"
                icon={<RippleDelete color="neutral.300" />}
                onClick={handleDelete}
              />
            </>
          ) : (
            <RippleBodyText02 py="6px">{t('survey:no_file_chosen')}</RippleBodyText02>
          )}
        </Flex>
      )}
    </Box>
  );
};

const UploadImageButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <Flex
      w="320px"
      h="120px"
      border="1px dashed"
      borderColor="blue.100"
      borderRadius="4px"
      _hover={{ background: 'blue.0' }}
      _active={{ background: 'blue.20' }}
      p="12px"
      onClick={onClick}
      cursor="pointer"
      direction="column"
      justify="center"
      align="center"
    >
      <RippleButtonText pb="8px" color="blue.100">
        {t('survey:upload_image')}
      </RippleButtonText>
      <RippleBodyText03>{t('survey:image_size')}</RippleBodyText03>
      <RippleBodyText03>{t('survey:image_format')}</RippleBodyText03>
    </Flex>
  );
};
