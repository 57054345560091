import { HStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import { RippleButton, RippleTypography } from '@/design';
import {
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
} from '@/design/RippleModal';
import { useUserProfile } from '@/models/UserProfile';

import { resetModalAtom } from './atoms';
import { useSubmitMutation } from './hooks';

export const warningModalAtom = atomWithReset({ isOpen: false });

export function useWarningModal() {
  const set = useSetAtom(warningModalAtom);

  return {
    open: () => {
      set({ isOpen: true });
    },
  };
}

export function WarningModal(): React.JSX.Element {
  const { t } = useTranslation();

  const { isOpen } = useAtomValue(warningModalAtom);
  const resetWarningModal = useResetAtom(warningModalAtom);
  const resetAll = useResetAtom(resetModalAtom);

  const submitMutation = useSubmitMutation();

  const { email } = useUserProfile();

  return (
    <RippleModal
      size="2xl"
      isOpen={isOpen}
      closeOnOverlayClick={!submitMutation.isLoading}
      closeOnEsc={!submitMutation.isLoading}
      onClose={resetWarningModal}
    >
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:access_change_notice')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>
          <RippleTypography variant="body02">
            {t('computer:access_change_notice__desc1', {
              email,
            })}
          </RippleTypography>
          <br />
          <RippleTypography variant="body02">{t('computer:access_change_notice__desc2')}</RippleTypography>
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="back-button" variant="grayScaleGhost" onClick={resetWarningModal}>
              {t('common:back')}
            </RippleButton>
            <RippleButton
              data-testid="confirm-button"
              variant="primary"
              isLoading={submitMutation.isLoading}
              onClick={() => {
                submitMutation.mutateAsync().then(() => {
                  resetWarningModal();
                  resetAll();
                });
              }}
            >
              {t('common:confirm')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}
