import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';
import { useTeamId, useTeamRole } from '@/models/TeamInformation';
import { ErrorResponse } from '@/services/common/types';
import { getPreferencePolicyDefaultValue } from '@/services/preferencePolicy';
import type { PreferencePolicyDefaultSettingMap } from '@/services/preferencePolicy/types';
import { getTeamDetail, getTeamMemberListSimplified } from '@/services/team';
import type { TeamMemberListItem } from '@/services/team/types';

type PreferencePolicyInformation = {
  isFetching: boolean;
  permissions: {
    canSetGroupAdmin: boolean;
  };
  groupAdminList: Array<TeamMemberListItem>;
};
export function usePreferencePolicyInformation(): PreferencePolicyInformation {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const teamId = useTeamId();
  const { isAdmin, isOwner } = useTeamRole();

  const { data: teamDetail, isFetching: isFetchingTeamDetail } = useQuery(['getTeamDetail'], () => getTeamDetail(teamId), {
    enabled: true,
    onError: (error: ErrorResponse) => {
      console.error(error);
      flashMessage({
        title: t('common:unexpectedError'),
        variant: 'error',
      });
    },
  });

  const canSetGroupAdmin = computeCanSetGroupAdmin();
  function computeCanSetGroupAdmin(): boolean {
    return Boolean(teamDetail?.team_member_permissions.create_group_admin && (isOwner || isAdmin));
  }

  const { data: groupAdminList = [], isFetching: isFetchingGroupAdminList } = useQuery(
    ['getGroupAdminList'],
    () => getTeamMemberListSimplified(teamId, { roles: ['group_admin'] }),
    {
      enabled: canSetGroupAdmin,
      onError: (error: ErrorResponse) => {
        console.error(error);
        flashMessage({
          title: t('common:unexpectedError'),
          variant: 'error',
        });
      },
    },
  );

  const isFetching = isFetchingTeamDetail || isFetchingGroupAdminList;

  const permissions = {
    canSetGroupAdmin,
  };

  return { isFetching, permissions, groupAdminList };
}

export function usePreferencePolicyDefaultValue(): PreferencePolicyDefaultSettingMap | null {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const teamId = useTeamId();

  const { data: defaultPolicy = {} as PreferencePolicyDefaultSettingMap, isFetching } = useQuery(
    ['getDefaultPolicy'],
    () => getPreferencePolicyDefaultValue(teamId),
    {
      enabled: true,
      onError: (error: ErrorResponse) => {
        console.error(error);
        flashMessage({
          title: t('common:unexpectedError'),
          variant: 'error',
        });
      },
    },
  );

  if (isFetching) return null;

  return defaultPolicy;
}
