import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { Box, Center, VStack, useDisclosure, useSteps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import {
  RippleBodyText01,
  RippleButton,
  RippleHeading04,
  RippleReadyOutline,
  RippleSheet,
  RippleSheetAction,
  RippleStep,
  RippleStepIndicator,
  RippleStepNumber,
  RippleStepStatus,
  RippleStepTitle,
  RippleStepper,
} from '@/design';
import { useResetCurrentOpenField, useResetCustomImageFile } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormValue, surveyFormSchema } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { useSurveyListQuery } from '@/services/serviceDesk/surveys';
import { SurveyFormAPIValue, surveyFormAPISchema } from '@/services/serviceDesk/surveys/type';

import { CustomFields, CustomThankYouMessage, ErrorModal, General, LeaveConfirmModal } from './';
import { SurveyFormMode } from './types';
import { type CurrentSurveyFormNameList } from './utils';

type SurveyFormLayoutProps = {
  mode: SurveyFormMode;
  defaultFormValue?: SurveyFormValue;
  onSubmit: (formValue: SurveyFormAPIValue) => void;
  onSubmitSuccess?: () => void;
  isSubmitting: boolean;
};

export const SurveyFormLayout = ({ mode, defaultFormValue, onSubmit, onSubmitSuccess, isSubmitting }: SurveyFormLayoutProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const resetCurrentOpenField = useResetCurrentOpenField();
  const resetCustomImageFile = useResetCustomImageFile();

  const surveyFormId = parseInt(router.query.id as string, 10);

  const surveyListQuery = useSurveyListQuery({
    errorMessages: {
      40403: t('survey:unable_to_access_the_survey'),
      41403: t('survey:unable_to_access_the_survey'),
    },
  });

  const currentSurveyFormNameList: CurrentSurveyFormNameList = useMemo(() => {
    return surveyListQuery.data
      ? surveyListQuery.data.map((form) => {
          return {
            name: form.name,
            formId: form.id,
          };
        })
      : [];
  }, [surveyListQuery]);

  const toFormListPage = () => {
    resetCurrentOpenField();
    resetCustomImageFile();
    const formListPage = `/service_desk/surveys`;
    router.push(formListPage);
  };

  const TITLE_MAP = {
    create: t('survey:create_survey'),
    edit: t('survey:edit_survey'),
    clone: t('survey:create_survey'),
  };

  const steps = [t('survey:general'), t('survey:custom_fields'), t('survey:custom_thank_you_message')];

  const { activeStep, goToPrevious, goToNext } = useSteps({
    index: 0,
    count: steps.length,
  });

  const { isOpen, onClose, onOpen: openLeaveConfirmModal } = useDisclosure();

  const handleClose = onSubmitSuccess ? toFormListPage : openLeaveConfirmModal;

  const form = useForm<SurveyFormValue>({
    defaultValues: defaultFormValue,
    reValidateMode: 'onSubmit',
    resolver: zodResolver(surveyFormSchema),
  });

  const handleSubmit = () => {
    form.handleSubmit(async (formValue) => {
      const orderUpdatedForm = {
        ...formValue,
        questionnaire: formValue.questionnaire.map((field, index) => {
          return {
            ...field,
            order: index, // update latest order for each field
          };
        }),
      };
      const result = surveyFormAPISchema.safeParse(orderUpdatedForm);

      if (!result.success) {
        console.error(result.error);
        return;
      }
      onSubmit(result.data);
    })();
  };

  return (
    <>
      <FormProvider {...form}>
        <RippleSheet contentWidth="1140px" title={onSubmitSuccess ? '' : TITLE_MAP[mode]} handleClose={handleClose}>
          <Center display="flex" flexDirection="column" justifyContent="center" h={onSubmitSuccess ? '60vh' : '100%'}>
            {onSubmitSuccess ? (
              <VStack gap="24px">
                <RippleReadyOutline color="green.200" width="56px" height="56px" />
                <RippleHeading04>{t('survey:create_successfully')}</RippleHeading04>
                <RippleBodyText01>{t('survey:you_can_assign_this_survey_to_channels_now')}</RippleBodyText01>
                <RippleButton size="sm" onClick={onSubmitSuccess}>
                  {t('survey:assign_channel')}
                </RippleButton>
                <RippleSheetAction>
                  <RippleButton size="sm" variant="secondary" onClick={toFormListPage}>
                    {t('common:finish')}
                  </RippleButton>
                </RippleSheetAction>
              </VStack>
            ) : (
              <>
                <RippleStepper index={activeStep} w="1140px" mt="20px" gap="8px">
                  {steps.map((stepName) => (
                    <RippleStep key={stepName}>
                      <RippleStepIndicator>
                        <RippleStepStatus complete={<RippleStepNumber />} incomplete={<RippleStepNumber />} active={<RippleStepNumber />} />
                      </RippleStepIndicator>
                      <Box>
                        <RippleStepTitle>{stepName}</RippleStepTitle>
                      </Box>
                    </RippleStep>
                  ))}
                </RippleStepper>
                {activeStep === 0 && (
                  <General
                    mode={mode}
                    surveyFormId={surveyFormId}
                    currentSurveyFormNameList={currentSurveyFormNameList}
                    onClickPreviousStep={openLeaveConfirmModal}
                    onClickNextStep={goToNext}
                  />
                )}
                {activeStep === 1 && <CustomFields onClickPreviousStep={goToPrevious} onClickNextStep={goToNext} />}
                {activeStep === 2 && (
                  <CustomThankYouMessage
                    mode={mode}
                    onClickPreviousStep={goToPrevious}
                    onSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                  />
                )}
              </>
            )}
          </Center>
        </RippleSheet>
      </FormProvider>
      <ErrorModal />
      <LeaveConfirmModal isOpen={isOpen} onClose={onClose} onConfirm={toFormListPage} />
    </>
  );
};
