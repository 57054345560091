import { Flex, HStack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleButton, RippleHeading06, RippleSheetAction } from '@/design';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { useZendeskDisplay } from '@/utils/useZendesk';

import { PreviewFormModal } from './';
import { FormEditor } from './FormEditor';
import { SURVEY_FORM_CONTENT_WIDTH } from './constant';
import { useValidateAndAutoFillEmptyField } from './hooks';

type CustomFieldsProps = {
  onClickPreviousStep: () => void;
  onClickNextStep: () => void;
};

export function CustomFields({ onClickPreviousStep, onClickNextStep }: CustomFieldsProps) {
  const previewModalState = useDisclosure();
  const { t } = useTranslation();
  useZendeskDisplay(!previewModalState.isOpen);

  const { watch } = useFormContext<SurveyFormValue>();
  const questionnaire = watch('questionnaire');
  const { validateAndAutoFillEmptyField } = useValidateAndAutoFillEmptyField();

  const noQuestion = questionnaire.length === 0;

  const handleClickPreviousStep = () => {
    validateAndAutoFillEmptyField({ isResetCurrentOpenField: true });
    onClickPreviousStep();
  };

  const handleClickNextStep = () => {
    validateAndAutoFillEmptyField({ isResetCurrentOpenField: true });
    onClickNextStep();
  };

  const handleClickPreview = () => {
    validateAndAutoFillEmptyField({ isResetCurrentOpenField: true });
    previewModalState.onOpen();
  };

  return (
    <HStack w={SURVEY_FORM_CONTENT_WIDTH} mt="32px" gap="32px" flexDirection="column" alignItems="center">
      <RippleHeading06 alignSelf="flex-start">{t('survey:custom_fields')}</RippleHeading06>
      <FormEditor />
      <RippleSheetAction>
        <Flex w="100%" direction="row" alignItems="center" justifyContent="space-between">
          <RippleButton size="sm" variant="secondary" onClick={handleClickPreview}>
            {t('common:preview')}
          </RippleButton>
          {previewModalState.isOpen && <PreviewFormModal onClose={previewModalState.onClose} />}
          <Flex gap="12px">
            <RippleButton size="sm" variant="grayScaleGhost" onClick={handleClickPreviousStep}>
              {t('common:back')}
            </RippleButton>
            <RippleButton size="sm" onClick={handleClickNextStep} isDisabled={noQuestion}>
              {t('common:next')}
            </RippleButton>
          </Flex>
        </Flex>
      </RippleSheetAction>
    </HStack>
  );
}
