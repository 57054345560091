import { Fragment } from 'react';

import { HStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Row, Table, createColumnHelper, flexRender } from '@tanstack/react-table';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { Trans, useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalSubTitle,
  RippleModalTitle,
  RippleTD,
  RippleTH,
  RippleTR,
  RippleTooltip,
  RippleVirtuosoReactTable,
  useRippleFlashMessage,
} from '@/design';
import { updateStreamers } from '@/services/computers';

import { rowSelectionStateAtom } from '../../atoms';

type TargetComputer = { id: string; name: string };
type TargetComputerList = Array<TargetComputer>;
export const confirmModalAtom = atomWithReset<{ isOpen: boolean; targetComputerList: TargetComputerList }>({
  isOpen: false,
  targetComputerList: [],
});

export function useConfirmModal() {
  const set = useSetAtom(confirmModalAtom);

  return {
    open({ targetComputerList }: { targetComputerList: TargetComputerList }) {
      set({ isOpen: true, targetComputerList });
    },
  };
}

const columnHelper = createColumnHelper<TargetComputer>();
const columns = [
  columnHelper.accessor('name', {
    header: function RenderHeader() {
      const { t } = useTranslation();

      return <RippleTH w="50%">{t('common:name')}</RippleTH>;
    },
    cell: ({ getValue }) => {
      const value = getValue();

      return (
        <RippleTD w="50%">
          <RippleTooltip label={value}>
            <RippleBodyText02 wordBreak="break-all" noOfLines={1}>
              {value}
            </RippleBodyText02>
          </RippleTooltip>
        </RippleTD>
      );
    },
  }),
  columnHelper.display({
    id: 'status',
    header: function RenderHeader() {
      const { t } = useTranslation();

      return <RippleTH w="50%">{t('common:status')}</RippleTH>;
    },
    cell: function RenderCell() {
      const { t } = useTranslation();

      return (
        <RippleTD w="50%">
          <RippleBodyText02>
            {'-> '}
            {t('computer:updateStreamers.confirmModal.status_updating')}
          </RippleBodyText02>
        </RippleTD>
      );
    },
  }),
];

export function ConfirmModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { isOpen, targetComputerList } = useAtomValue(confirmModalAtom);
  const resetModal = useResetAtom(confirmModalAtom);

  const setRowSelectionState = useSetAtom(rowSelectionStateAtom);

  const { flashMessage } = useRippleFlashMessage();

  const updateMutation = useMutation({
    mutationFn: updateStreamers,
    onSuccess: (_response, targetComputerList) => {
      flashMessage({
        variant: 'info',
        title: t('computer:updateStreamers.modal.successMessage'),
      });
      setRowSelectionState((draft) => {
        targetComputerList.forEach((computerId) => {
          delete draft[computerId];
        });
      });
    },
    onError: () => {
      flashMessage({ variant: 'error', title: t('common:unexpectedError') });
    },
    onSettled: () => {
      resetModal();
    },
  });

  function handleConfirm() {
    updateMutation.mutate(targetComputerList.map(({ id }) => Number(id)));
  }

  const isDisabled = updateMutation.isLoading;

  return (
    <RippleModal isOpen={isOpen} onClose={resetModal} closeOnEsc={!isDisabled} closeOnOverlayClick={!isDisabled}>
      <RippleModalOverlay />
      <RippleModalContent w="100%" maxWidth="736px">
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:updateStreamers.modal.title')}</RippleModalTitle>
          <RippleModalSubTitle>
            {t('computer:updateStreamers.modal.subTitle', {
              targetComputerCounts: targetComputerList.length,
            })}
          </RippleModalSubTitle>
        </RippleModalHeader>

        <RippleModalBody display="flex" flexDirection="column">
          <RippleBodyText02 mb="24px">
            <Trans t={t} i18nKey="computer:updateStreamers.modal.description" />
          </RippleBodyText02>

          <RippleVirtuosoReactTable
            variant="compact"
            colorScheme="white"
            style={{ width: '100%' }}
            data={targetComputerList}
            columns={columns}
            tableOptions={{ enableSorting: false }}
            virtuosoOptions={getVirtuosoOptions}
          />
        </RippleModalBody>

        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={resetModal} isDisabled={isDisabled}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton
              data-testid="submit-button"
              variant="primary"
              onClick={handleConfirm}
              isLoading={updateMutation.isLoading}
              isDisabled={isDisabled}
            >
              {t('common:update')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

function getVirtuosoOptions<T>(table: Table<T>) {
  return { itemContent: renderItemContent, fixedHeaderContent: renderFixedHeaderContent(table) };
}

function renderFixedHeaderContent<T>(table: Readonly<Table<T>>) {
  return () =>
    table.getHeaderGroups().map((headerGroup) => (
      <RippleTR key={headerGroup.id} w="100%">
        {headerGroup.headers.map((header) => (
          <Fragment key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Fragment>
        ))}
      </RippleTR>
    ));
}

function renderItemContent<T>(_index: number, row: Row<T>) {
  return (
    <RippleTR isSelected={row.getIsSelected()} w="100%">
      {row.getVisibleCells().map((cell) => (
        <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
      ))}
    </RippleTR>
  );
}
