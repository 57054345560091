import { Box, HStack } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';

import { RippleCount, RippleMacNoBorder, RippleTypography, RippleWindowsNoBorder } from '@/design';

import { PolicyAssignment } from '../hooks';

export const GroupHeader = ({ rows }: { rows: Array<Row<PolicyAssignment>> }) => (
  <Box position="sticky" left="0px" px="8px" width="100%">
    <HStack direction="row" gap="8px" alignItems="center">
      {rows[0].original.policyNode.policy.platform === 'Windows' && <RippleWindowsNoBorder color="neutral.300" />}
      {rows[0].original.policyNode.policy.platform === 'macOS' && <RippleMacNoBorder color="neutral.300" />}
      <RippleTypography variant="heading07" maxW="400px" isTruncated wordBreak="break-all" whiteSpace="initial">
        {rows[0].original.policyNode.policy.platform}
      </RippleTypography>
      <RippleCount count={rows.length} variant="neutralLight" />
    </HStack>
  </Box>
);
