import { useTranslation } from 'next-i18next';

import { useBrandImageHandler, useWelcomeMessageHandler } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';

import { SectionBase } from '.';

export const AddHeaderSection = () => {
  const { t } = useTranslation();
  const { handleBrandImageField } = useBrandImageHandler();
  const { handleWelcomeMessageField } = useWelcomeMessageHandler();

  return (
    <SectionBase
      instruction={t('survey:add_a_brand_icon_or_a_welcome_message_for_your_survey_header')}
      buttonList={[
        { label: t('survey:brand_icon'), onClick: handleBrandImageField },
        { label: t('survey:welcome_message'), onClick: handleWelcomeMessageField },
      ]}
    />
  );
};
