import { useContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import { useTeamId, useTeamRole } from '@/models/TeamInformation';
import { getGroupList } from '@/services/group';
import { Group } from '@/services/group/types';

import { ColumnPermissionsContext, manageableGroupListAtom } from '../ComputerList';

const groupListPlaceholder: Array<Group> = [];

export function useGroupData() {
  const { t } = useTranslation();
  const teamId = useTeamId();
  const { isGroupAdmin, isMember } = useTeamRole();

  const columnPermissions = useContext(ColumnPermissionsContext);

  const setManageableGroupList = useSetAtom(manageableGroupListAtom);

  const groupListQuery = useQuery({
    queryKey: ['allComputers', 'groupList', teamId],
    queryFn: async () => {
      const groupList = await getGroupList(teamId, {
        group_type: 'computer',
        members_count: false,
        computers_count: false,
        admins_count: false,
        member_status: [],
      });
      setManageableGroupList(groupList.map(({ id, name }) => ({ id: String(id), name })));
      return groupList;
    },
    cacheTime: 0,
    enabled: columnPermissions.group,
  });
  const manageableGroupList = groupListQuery.data ?? groupListPlaceholder;

  const groupList = useMemo<Array<Pick<Group, 'id' | 'name'>>>(() => {
    const isMemberInDefaultGroup = isMember && manageableGroupList.length === 0;

    if (isMemberInDefaultGroup) return [{ id: 0, name: t('computer:defaultGroup') }];
    if (isGroupAdmin || isMember) return manageableGroupList; // No default group

    return [{ id: 0, name: t('computer:defaultGroup') }].concat(manageableGroupList);
  }, [manageableGroupList, isGroupAdmin, isMember, t]);

  return { groupList, groupListQuery };
}
