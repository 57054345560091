import { useQuery } from '@tanstack/react-query';

import { getMessageService } from '@/services/users';

export const useLatestMessageQuery = ({ enabled = true, viewedMessages }: { enabled: boolean; viewedMessages: Array<number> }) => {
  return useQuery({
    queryKey: ['policy', 'latest-message'],
    queryFn: () => getMessageService.execute({ type: 'popup', page: 'policy', viewedMessages }),
    cacheTime: 0,
    enabled,
  });
};
