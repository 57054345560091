import { useRouter } from 'next/router';

import { Box, Flex, Portal, VStack, forwardRef, useDisclosure } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleIconButton,
  RippleInfo16,
  RippleMore,
  RipplePopover,
  RipplePopoverArrow,
  RipplePopoverBody,
  RipplePopoverCloseButton,
  RipplePopoverContent,
  RipplePopoverTrigger,
  RippleSkeleton,
  RippleTooltip,
  RippleTypography,
  useRippleFlashMessage,
} from '@/design';
import type { RippleIconButtonProps } from '@/design';
import type { ErrorResponse } from '@/services/common/types';
import type { ComputerActionSetting, ComputerActionSettingMap, ComputerData } from '@/services/computers';
import { getTeamComputerActionSettingMap, getUserComputerActionSettingMap } from '@/services/computers';
import { NestedMenu, NestedMenuContent, NestedMenuGroup, NestedMenuItem, NestedMenuList, NestedMenuTrigger } from '@/showcase';
import type { NestedMenuItemProps, NestedMenuProps } from '@/showcase';

import { useAddNotes } from '../../AddNotes';
import { useAssignGroup } from '../../AssignGroup';
import { useBackgroundActions } from '../../BackgroundActions';
import { ComputerGroupMenu } from '../../ComputerGroupMenu';
import { useDeleteComputers } from '../../DeleteComputers';
import { useDisconnectSession } from '../../DisconnectSession';
import { useRebootComputer } from '../../RebootComputer';
import { useRemoteCommand } from '../../RemoteCommand';
import { useRenameComputer } from '../../RenameComputer';
import { useWakeComputerModalActions } from '../../WakeComputer/WakeComputerModalAtom';
import { useAccessPermission } from '../../actions';
import { computerScopeAtom, manageableGroupListAtom } from '../../atoms';
import type { ComputerScope } from '../../types';
import { useShowDefaultGroup } from '../../utils';
import { getMoreActionsQueryKey } from './utils';

type MoreActionsProps = {
  isDisabled: boolean;
  computerData: Pick<ComputerData, 'id' | 'name' | 'note' | 'deployed'>;
  /** @deprecated Use `computerData` instead */
  computerId: number;
  /** @deprecated Use `computerData` instead */
  computerName: string;
  /** @deprecated Use `computerData` instead */
  note: string | null;
};
export function MoreActions({ isDisabled, computerData, computerId, computerName, note }: MoreActionsProps): React.JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const computerScope = useAtomValue(computerScopeAtom);
  const { t } = useTranslation();

  const { flashMessage } = useRippleFlashMessage();

  const { isFetching, data: actionSettingMap = {} } = useQuery({
    queryKey: getMoreActionsQueryKey({ computerScope, computerId }),
    queryFn: () => {
      switch (computerScope) {
        case 'user': {
          return getUserComputerActionSettingMap(computerId);
        }
        case 'team': {
          return getTeamComputerActionSettingMap(computerId);
        }
      }
    },
    retry(failureCount, error: ErrorResponse) {
      if (error.errorReason.error === 'resource_not_exists') return false;
      return failureCount < 3;
    },
    staleTime: 30000, // 30 seconds
    enabled: !isDisabled && isOpen,
    onError: () => {
      onClose();
      flashMessage({ variant: 'error', title: t('common:unexpectedError') });
    },
  });

  const menuContent = computeMenuContent();

  const boundary: NestedMenuProps['boundary'] = document.querySelector('main') ?? 'scrollParent';

  return (
    <NestedMenu placement="auto-end" isOpen={isOpen} onClose={onClose} onOpen={onOpen} boundary={boundary}>
      {({ isOpen }) => (
        <>
          <NestedMenuTrigger>
            <TriggerButton data-testid={`gear-menu-button-${computerData.id}`} isActive={isOpen} isDisabled={isDisabled} />
          </NestedMenuTrigger>
          <Portal>
            <NestedMenuContent boxShadow="8px" w="336px" borderRadius="4px" zIndex={20}>
              {menuContent}
            </NestedMenuContent>
          </Portal>
        </>
      )}
    </NestedMenu>
  );

  function computeMenuContent(): React.JSX.Element {
    if (isFetching) return <LoadingContent />;

    const hasNoSettings = checkActionSettingGroup(...Object.values(actionSettingMap)) === false;
    if (hasNoSettings)
      return (
        <Flex w="100%" p="12px" justifyContent="center">
          <RippleBodyText02>{t('computer:moreActions.noAvailableFunction')}</RippleBodyText02>
        </Flex>
      );

    return (
      <ActionMenuList
        computerData={computerData}
        computerScope={computerScope}
        computerId={computerId}
        computerName={computerName}
        note={note}
        actionSettingMap={actionSettingMap}
      />
    );
  }
}

function LoadingContent(): React.JSX.Element {
  return (
    <VStack spacing="8px" p="8px" alignItems="stretch" w="336px">
      <RippleSkeleton />
      <RippleSkeleton />
      <RippleSkeleton />
      <RippleSkeleton />
      <RippleSkeleton />
    </VStack>
  );
}

type ActionMenuListProps = {
  computerData: Pick<ComputerData, 'id' | 'name' | 'note' | 'deployed' | 'from_external_shared'>;
  computerScope: ComputerScope;
  actionSettingMap: ComputerActionSettingMap;

  /** @deprecated Use `computerData` instead */
  computerId: number;
  /** @deprecated Use `computerData` instead */
  computerName: string;
  /** @deprecated Use `computerData` instead */
  note: string | null;
};
function ActionMenuList({
  computerScope,
  computerData,
  computerId,
  computerName,
  note,
  actionSettingMap,
}: ActionMenuListProps): React.JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();

  const deleteComputer = useDeleteComputers();
  const backgroundActions = useBackgroundActions();
  const remoteCommand = useRemoteCommand();
  const addNotes = useAddNotes();
  const renameComputer = useRenameComputer();
  const rebootComputer = useRebootComputer();
  const { handleOpenSelector } = useWakeComputerModalActions();
  const disconnectSession = useDisconnectSession();
  const accessPermission = useAccessPermission();

  const showEndpointManagement = computeShowEndpointManagement(actionSettingMap);
  const showBackgroundActions = computeShowBackgroundActions(actionSettingMap);

  return (
    <NestedMenuList>
      {checkActionSettingGroup(
        actionSettingMap.force_disconnect,
        actionSettingMap.reboot_computer,
        actionSettingMap.wake_computer,
        actionSettingMap.assign_computer_group,
        actionSettingMap.delete_computer,
        actionSettingMap.change_name,
        actionSettingMap.add_note,
      ) && (
        <NestedMenuGroup>
          {/* Disconnect current session */}
          {checkActionSetting(actionSettingMap.force_disconnect, ({ menuItemProps }) => (
            <NestedMenuGroup>
              <NestedMenuItem
                data-testid="force-disconnect"
                {...menuItemProps}
                onClick={() => {
                  disconnectSession.execute({ computerId });
                }}
              />
            </NestedMenuGroup>
          ))}

          {/* Reboot computer */}
          {checkActionSetting(actionSettingMap.reboot_computer, ({ menuItemProps }) => (
            <NestedMenuItem
              data-testid="reboot-computer"
              {...menuItemProps}
              onClick={() => {
                rebootComputer.execute({ computerId, computerName });
              }}
            />
          ))}

          {/* Wake computer */}
          {checkActionSetting(actionSettingMap.wake_computer, ({ menuItemProps }) => (
            <NestedMenuItem
              data-testid="wake-computer"
              {...menuItemProps}
              onClick={() => {
                handleOpenSelector({
                  wakeComputer: {
                    id: computerId,
                    name: computerName,
                  },
                });
              }}
            />
          ))}

          {/* Assign computer group */}
          {checkActionSetting(actionSettingMap.assign_computer_group, ({ menuItemProps }) => (
            <NestedMenuItem data-testid="assign-computer-group" {...menuItemProps} subMenu>
              <AssignGroupAction computerId={computerId} computerName={computerName} />
            </NestedMenuItem>
          ))}

          {/* Delete computer */}
          {checkActionSetting(actionSettingMap.delete_computer, ({ menuItemProps }) => (
            <NestedMenuItem
              data-testid="delete-computer"
              {...menuItemProps}
              onClick={() => {
                deleteComputer.execute({
                  targetComputerList: [
                    { id: String(computerId), name: computerName, isFromExternalShared: Boolean(computerData.from_external_shared) },
                  ],
                });
              }}
            />
          ))}

          {/* Rename computer */}
          {checkActionSetting(actionSettingMap.change_name, ({ menuItemProps }) => (
            <NestedMenuItem
              data-testid="rename-computer"
              {...menuItemProps}
              onClick={() => {
                renameComputer.execute({ computerId, name: computerName });
              }}
            />
          ))}

          {/* Add note */}
          {checkActionSetting(actionSettingMap.add_note, ({ menuItemProps }) => (
            <NestedMenuItem
              data-testid="add-note"
              {...menuItemProps}
              onClick={() => {
                addNotes.execute({ computerId, note });
              }}
            />
          ))}
        </NestedMenuGroup>
      )}

      {(showEndpointManagement || showBackgroundActions) && (
        <NestedMenuGroup>
          {showEndpointManagement && (
            <NestedMenuItem data-testid="endpoint-management" label={t('computer:endpoint_management')} subMenu>
              {/* Check for updates */}
              {checkActionSetting(actionSettingMap.check_for_updates, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="check-for-updates"
                  {...menuItemProps}
                  onClick={() => {
                    router.push(`/property/updates/${computerId}`);
                  }}
                />
              ))}

              {/* Endpoint Security */}
              {checkActionSetting(actionSettingMap.antivirus, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="endpoint-security"
                  {...menuItemProps}
                  onClick={() => {
                    router.push(`/property/endpoint_security/${computerId}`);
                  }}
                />
              ))}

              {/* Vulnerability Score */}
              {checkActionSetting(actionSettingMap.vulnerability_score, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="vulnerability-score"
                  {...menuItemProps}
                  onClick={() => {
                    router.push(`/property/vulnerability_score/${computerId}`);
                  }}
                />
              ))}

              {/* See alerts */}
              {checkActionSetting(actionSettingMap.see_alerts, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="see-alerts"
                  {...menuItemProps}
                  onClick={() => {
                    router.push(`/property/alerts/${computerId}`);
                  }}
                />
              ))}

              {/* Schedules */}
              {checkActionSetting(actionSettingMap.schedules, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="schedules"
                  {...menuItemProps}
                  onClick={() => {
                    router.push(`/property/scheduled_actions/${computerId}`);
                  }}
                />
              ))}

              {/* System inventory */}
              {checkActionSetting(actionSettingMap.check_for_inventory, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="system-inventory"
                  {...menuItemProps}
                  onClick={() => {
                    router.push(`/property/inventory/${computerId}`);
                  }}
                />
              ))}

              {/* View event logs */}
              {checkActionSetting(actionSettingMap.check_for_event_logs, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="view-event-logs"
                  {...menuItemProps}
                  onClick={() => {
                    router.push(`/property/event_logs/${computerId}`);
                  }}
                />
              ))}
            </NestedMenuItem>
          )}

          {showBackgroundActions && (
            <NestedMenuItem data-testid="background-actions" label={t('computer:background_actions')} subMenu>
              {/* Task manager */}
              {checkActionSetting(actionSettingMap.task_manager, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="task-manager"
                  {...menuItemProps}
                  onClick={() => {
                    backgroundActions.execute({ computerScope, computerId, kind: 'task_manager' });
                  }}
                />
              ))}

              {/* Service manager */}
              {checkActionSetting(actionSettingMap.service_manager, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="service-manager"
                  {...menuItemProps}
                  onClick={() => {
                    backgroundActions.execute({ computerScope, computerId, kind: 'service_manager' });
                  }}
                />
              ))}

              {/* Registry editor */}
              {checkActionSetting(actionSettingMap.registry_editor, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="registry-editor"
                  {...menuItemProps}
                  onClick={() => {
                    backgroundActions.execute({ computerScope, computerId, kind: 'registry_editor' });
                  }}
                />
              ))}

              {/* Device manager */}
              {checkActionSetting(actionSettingMap.device_manager, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="device-manager"
                  {...menuItemProps}
                  onClick={() => {
                    backgroundActions.execute({ computerScope, computerId, kind: 'device_manager' });
                  }}
                />
              ))}

              {/* Remote command */}
              {checkActionSetting(actionSettingMap.check_for_command_prompt, ({ menuItemProps }) => (
                <NestedMenuItem
                  data-testid="remote-command"
                  {...menuItemProps}
                  onClick={() => {
                    remoteCommand.execute({ computerId });
                  }}
                />
              ))}
            </NestedMenuItem>
          )}
        </NestedMenuGroup>
      )}

      {/* See user list */}
      {checkActionSetting(actionSettingMap.msp_access_permission, ({ menuItemProps }) => (
        <NestedMenuGroup>
          <NestedMenuItem
            data-testid="msp-access-permission"
            {...menuItemProps}
            onClick={() => {
              // TODO: Temporary redirect to rails page, implement user list modal later
              router.push(`/property/accessibility/${computerId}`);
            }}
          />
        </NestedMenuGroup>
      ))}

      {/* Access Permission */}
      {checkActionSetting(actionSettingMap.access_permission, ({ menuItemProps }) => (
        <NestedMenuGroup>
          <NestedMenuItem
            data-testid="access-permission"
            {...menuItemProps}
            onClick={() => {
              accessPermission.execute({ computerData });
            }}
          />
        </NestedMenuGroup>
      ))}

      {checkActionSettingGroup(actionSettingMap.properties) && (
        <NestedMenuGroup>
          {/* Properties */}
          {checkActionSetting(actionSettingMap.properties, ({ menuItemProps }) => (
            <NestedMenuItem
              data-testid="properties"
              {...menuItemProps}
              onClick={() => {
                router.push(`/property/general/${computerId}`);
              }}
            />
          ))}
        </NestedMenuGroup>
      )}
    </NestedMenuList>
  );
}

function computeShowBackgroundActions(actionSettingMap: ComputerActionSettingMap) {
  const settingKeys = ['task_manager', 'service_manager', 'registry_editor', 'device_manager', 'check_for_command_prompt'] as const;

  return checkActionSettingGroup(...settingKeys.map((key) => actionSettingMap[key]));
}

function computeShowEndpointManagement(actionSettingMap: ComputerActionSettingMap) {
  const settingKeys = [
    'check_for_updates',
    'antivirus',
    'vulnerability_score',
    'see_alerts',
    'schedules',
    'check_for_inventory',
    'check_for_event_logs',
  ] as const;

  return checkActionSettingGroup(...settingKeys.map((key) => actionSettingMap[key]));
}

function checkActionSetting(
  actionSetting: ComputerActionSetting | undefined,
  render: ({ menuItemProps }: { menuItemProps: Pick<NestedMenuItemProps, 'label' | 'isDisabled' | 'tooltip'> }) => React.JSX.Element,
): React.JSX.Element | null {
  if (actionSetting?.display) {
    const { text, active, tooltip, tooltip_message } = actionSetting;

    const menuItemProps: Pick<NestedMenuItemProps, 'label' | 'isDisabled' | 'tooltip'> = {
      label: text,
      isDisabled: !active,
      tooltip: tooltip && tooltip_message ? <TooltipPopover content={tooltip_message} /> : undefined,
    };
    return render({ menuItemProps });
  }
  return null;
}

function checkActionSettingGroup(...actionSettings: Array<ComputerActionSetting | undefined>): boolean {
  return actionSettings.some((setting) => setting?.display);
}

type AssignGroupActionProps = { computerId: number; computerName: string };
function AssignGroupAction({ computerId, computerName }: AssignGroupActionProps): React.JSX.Element {
  const showDefaultGroup = useShowDefaultGroup();

  const groupList = useAtomValue(manageableGroupListAtom);
  const assignGroup = useAssignGroup();

  return (
    <ComputerGroupMenu
      menuHeight={345}
      showAllGroup={false}
      showDefaultGroup={showDefaultGroup}
      showFromOtherGroup={false}
      groups={groupList}
      onSelect={(selectedGroup) => {
        assignGroup.execute({
          targetGroup: { id: Number(selectedGroup.id), name: selectedGroup.name },
          targetComputerList: [{ id: String(computerId), name: computerName }],
        });
      }}
    />
  );
}

const TriggerButton = forwardRef<Omit<RippleIconButtonProps, 'aria-label'>, 'button'>((props, ref) => {
  const { t } = useTranslation();

  return (
    <RippleTooltip label={t('common:more')}>
      <RippleIconButton
        ref={ref}
        aria-label="more actions"
        color="blue.100"
        _disabled={{ color: 'neutral.80' }}
        icon={<RippleMore color="inherit" />}
        {...props}
      />
    </RippleTooltip>
  );
});

type TooltipPopoverProps = { content: string };
function TooltipPopover({ content }: TooltipPopoverProps): React.JSX.Element {
  return (
    <RipplePopover trigger="hover">
      <RipplePopoverTrigger>
        <Box as="span" ml="4px">
          <RippleInfo16 />
        </Box>
      </RipplePopoverTrigger>
      <Portal>
        <RipplePopoverContent shadow="8px" p="8px">
          <RipplePopoverArrow />
          <RipplePopoverCloseButton top="8px" right="8px" />
          {/* NOTE: tooltip_message may contains html tags e.g. <a />, currently use dangerouslySetInnerHTML as workaround */}
          <RipplePopoverBody>
            <RippleTypography
              as="div"
              variant="body02"
              sx={{ a: { color: 'blue.100', fontWeight: 600 } /* Ref: hypertext02 style */ }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </RipplePopoverBody>
        </RipplePopoverContent>
      </Portal>
    </RipplePopover>
  );
}
