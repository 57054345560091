import { useMutation } from '@tanstack/react-query';
import merge from 'lodash/merge';

import { useTeamId } from '@/models/TeamInformation';
import { queryClient } from '@/pages/_app';
import {
  Platform,
  assignEmmPolicyToGroupsService,
  assignEmmPolicyToServersService,
  removeEmmPolicyFromGroupsByPlatformService,
  unassignEmmPolicyToGroupsService,
  unassignEmmPolicyToServersService,
} from '@/services/teams/emm_policies';

import { useGroupPolicyListQuery } from './useGroupPolicyListQuery';
import { useServerPolicyListQuery } from './useServerPolicyListQuery';

type UseAssignPolicyMutationProps = {
  onSuccess: (data: {
    groups: { success: Array<number>; fail: Array<number> };
    servers: { success: Array<number>; fail: Array<number> };
  }) => void;
};

type PolicyAssignMutationProps = {
  policyId: number;
  platform: Platform;
  groupsToAssign: Array<number>;
  groupsToUnassign: Array<number>;
  groupsToUnassignByPlatform: Array<number>;
  serversToAssign: Array<number>;
  serversToUnassign: Array<number>;
};

export const useAssignPolicyMutation = ({ onSuccess }: UseAssignPolicyMutationProps) => {
  const teamId = useTeamId();
  const groupPolicyListQueryKey = useGroupPolicyListQuery({ enabled: false }).queryKey;
  const serverPolicyListQueryKey = useServerPolicyListQuery({ enabled: false }).queryKey;
  return useMutation({
    mutationFn: async ({
      policyId,
      platform,
      groupsToAssign,
      groupsToUnassign,
      groupsToUnassignByPlatform,
      serversToAssign,
      serversToUnassign,
    }: PolicyAssignMutationProps) => {
      // Groups to assign
      const groupsAssignRequest =
        groupsToAssign.length > 0
          ? assignEmmPolicyToGroupsService.execute(teamId, policyId, { group_ids: groupsToAssign }).catch(() => ({
              success: [] as Array<number>,
              fail: groupsToAssign,
            }))
          : { success: [] as Array<number>, fail: [] as Array<number> };
      // Groups to unassign
      const groupsUnAssignRequest =
        groupsToUnassign.length > 0
          ? unassignEmmPolicyToGroupsService.execute(teamId, policyId, { group_ids: groupsToUnassign }).catch(() => ({
              success: [] as Array<number>,
              fail: groupsToUnassign,
            }))
          : { success: [] as Array<number>, fail: [] as Array<number> };
      // Groups to unassign by platform (assign to team default policy)
      const groupsUnassignByPlatformRequest =
        groupsToUnassignByPlatform.length > 0
          ? removeEmmPolicyFromGroupsByPlatformService.execute(teamId, { group_ids: groupsToUnassignByPlatform, platform }).catch(() => ({
              success: [] as Array<number>,
              fail: groupsToUnassignByPlatform,
            }))
          : { success: [] as Array<number>, fail: [] as Array<number> };
      // Servers to assign
      const serversAssignRequest =
        serversToAssign.length > 0
          ? assignEmmPolicyToServersService.execute(teamId, policyId, { server_ids: serversToAssign }).catch(() => ({
              success: [] as Array<number>,
              fail: serversToAssign,
            }))
          : { success: [] as Array<number>, fail: [] as Array<number> };
      // Servers to unassign
      const serversUnAssignRequest =
        serversToUnassign.length > 0
          ? unassignEmmPolicyToServersService.execute(teamId, policyId, { server_ids: serversToUnassign }).catch(() => ({
              success: [] as Array<number>,
              fail: serversToUnassign,
            }))
          : { success: [] as Array<number>, fail: [] as Array<number> };

      const [groupAssignResults, serverAssignResults, groupsUnassignResults, serversUnAssignResults, groupsUnassignByPlatformResult] =
        await Promise.all([
          groupsAssignRequest,
          serversAssignRequest,
          groupsUnAssignRequest,
          serversUnAssignRequest,
          groupsUnassignByPlatformRequest,
        ]);
      return {
        groups: merge({}, groupAssignResults, groupsUnassignResults, groupsUnassignByPlatformResult),
        servers: merge({}, serverAssignResults, serversUnAssignResults),
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: groupPolicyListQueryKey });
      await queryClient.invalidateQueries({ queryKey: serverPolicyListQueryKey });
    },
    onSuccess,
  });
};
