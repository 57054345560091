import { useContext } from 'react';

import { useAtomValue } from 'jotai';

import { featureControl } from '@/feature/toggle';
import { settingControlAtom } from '@/modules/TeamSettings/atoms';
import { SettingControlContext } from '@/modules/TeamSettings/contexts';
import { SettingControlContextValue } from '@/modules/TeamSettings/types';

export const useSettingControlContext = featureControl.getToggle('PCP_2003__TeamSettings__SettingControlContext_refactor')
  ? useSettingControlContext_new
  : useSettingControlContext_deprecated;

function useSettingControlContext_deprecated(): SettingControlContextValue {
  return useContext(SettingControlContext);
}

function useSettingControlContext_new(): SettingControlContextValue {
  return useAtomValue(settingControlAtom);
}
