import { ReactElement, useEffect, useRef } from 'react';

import { HStack, VStack } from '@chakra-ui/react';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AddFieldSection } from '@/modules/ServiceDesk/SurveyForm/Editor/FormEditor/EmptyState';
import {
  useCurrentOpenField,
  useResetCurrentOpenField,
  useValidateAndAutoFillEmptyField,
} from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { ClickItemHandler, SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { QuestionnaireKind } from '@/services/serviceDesk/surveys/type';

import { SUBMIT_BUTTON_UUID } from '../constant';
import { ADD_FIELD_MAP } from '../types';
import { ADD_FIELD_BUTTON_HEIGHT, AddFieldButton, Questionnaire } from './';
import { ActionButtonsEditMode, Header } from './EditMode';
import { ActionButtons } from './ViewMode';

const PADDING_TOP = 24;

export const FormEditor = () => {
  const { validateAndAutoFillEmptyField } = useValidateAndAutoFillEmptyField();
  const [currentOpenField, setCurrentOpenField] = useCurrentOpenField();
  const resetCurrentOpenField = useResetCurrentOpenField();
  const { setValue, watch, control } = useFormContext<SurveyFormValue>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questionnaire',
  });

  const questionnaire = watch('questionnaire');
  const isNoQuestionnaire = questionnaire.length === 0;

  const prevQuestionnaireLength = useRef<number>(fields.length);

  const lastFieldUuid = fields.length > 0 ? fields[fields.length - 1].uuid : '';

  useEffect(() => {
    // To auto focus on last field added by user
    if (questionnaire.length > prevQuestionnaireLength.current) {
      setCurrentOpenField({ uuid: lastFieldUuid, autoFocusType: 'label' });
    }

    prevQuestionnaireLength.current = questionnaire.length;
  }, [questionnaire.length, lastFieldUuid, setCurrentOpenField]);

  const addField = ({ kind }: { kind: QuestionnaireKind }) => {
    const addedField = ADD_FIELD_MAP[kind];
    append({ ...addedField, order: fields.length, uuid: window.crypto.randomUUID() });
  };

  const autoFillCurrentFieldAndAddNewField = ({ kind }: { kind: QuestionnaireKind }) => {
    validateAndAutoFillEmptyField();
    addField({ kind });
  };

  const makeClickFieldItemHandler =
    ({ fieldUuid, autoFocusType }: ClickItemHandler) =>
    (event: { preventDefault: () => void }) => {
      event.preventDefault();
      validateAndAutoFillEmptyField();
      setCurrentOpenField({ uuid: fieldUuid, autoFocusType });
    };

  const makeUpdateOptionsHandler = (index: number) => (options: Array<{ option: string }> | null) => {
    if (options) setValue(`questionnaire.${index}.options`, options);
  };

  const makeRemoveFieldHandler = (index: number) => () => {
    remove(index);
    resetCurrentOpenField();
  };

  const handleSave = () => {
    validateAndAutoFillEmptyField({ isResetCurrentOpenField: true });
  };

  // Drag & Drop Methods
  const handleDragEnd = (event: DragEndEvent) => {
    if (!(event.active && event.over)) return;

    const { active, over } = event;

    if (active.id !== over.id) {
      const originalIndex: number = active.data.current?.sortable.index;
      const droppedIndex: number = over.data.current?.sortable.index;

      setValue('questionnaire', arrayMove(questionnaire, originalIndex, droppedIndex));
    }
  };

  return (
    <HStack w="800px" gap="0" flexDirection="column" alignItems="flex-start">
      <AddFieldButton addField={autoFillCurrentFieldAndAddNewField} />
      <FormWrapper>
        <>
          <Header isNoQuestionnaire={isNoQuestionnaire} onSaveField={handleSave} />
          <>
            {isNoQuestionnaire ? (
              <AddFieldSection addField={addField} />
            ) : (
              <Questionnaire
                fields={fields}
                getClickFieldHandler={makeClickFieldItemHandler}
                getClickOptionsFieldHandler={makeUpdateOptionsHandler}
                getRemoveFieldHandler={makeRemoveFieldHandler}
                onSaveField={handleSave}
                onDragFieldEnd={handleDragEnd}
              />
            )}
          </>
          {currentOpenField?.uuid === SUBMIT_BUTTON_UUID ? <ActionButtonsEditMode onSave={handleSave} /> : <ActionButtons />}
        </>
      </FormWrapper>
    </HStack>
  );
};

const FormWrapper = ({ children }: { children: ReactElement }) => {
  return (
    <VStack
      w="100%"
      p={`${PADDING_TOP}px 0 32px 0`}
      boxShadow="0px 4px 8px 2px rgba(0, 0, 0, 0.24)"
      borderRadius="12px"
      gap="0"
      mt={`-${ADD_FIELD_BUTTON_HEIGHT}px`}
    >
      {children}
    </VStack>
  );
};
