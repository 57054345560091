import { Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleBodyText02, RippleInput } from '@/design';
import { SUBMIT_BUTTON_MAX_LENGTH } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { useCurrentOpenFieldValue } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

import { ActionBar, FieldTitle, Highlighter } from './../EditMode';

type ActionButtonsEditModeProps = {
  onSave: () => void;
};

export function ActionButtonsEditMode({ onSave }: ActionButtonsEditModeProps) {
  const { register } = useFormContext<SurveyFormValue>();
  const { t } = useTranslation();
  const currentOpenField = useCurrentOpenFieldValue();

  return (
    <Box w="100%" p="0px 48px 8px" position="relative">
      <Highlighter />
      <FieldTitle title={t('survey:action_button')} />
      <HStack alignItems="center" gap="12px" mt="4px" mb="12px">
        <RippleBodyText02>{t('survey:label')}</RippleBodyText02>
        <RippleInput
          autoFocus={currentOpenField?.autoFocusType === 'label'}
          id="editing_action_button_label"
          aria-describedby="editing_action_button_label"
          placeholder={t('survey:enter_the_action_button_text')}
          maxLength={SUBMIT_BUTTON_MAX_LENGTH}
          size="lg"
          type="text"
          flex={1}
          {...register('action_button_label')}
        />
      </HStack>
      <ActionBar isShowRequired={false} isShowDeleteButton={false} onSave={onSave} />
    </Box>
  );
}
