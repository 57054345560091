import { Fragment, useState } from 'react';

import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'next-i18next';
import { Controller, UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import {
  RippleAdd,
  RippleBodyText02,
  RippleCheckbox,
  RippleCheckboxGroup,
  RippleIconButton,
  RippleInput,
  RippleMinus,
  RippleTextField,
} from '@/design';
import colors from '@/design/theme/colors';
import { LABEL_MAX_LENGTH, MAX_OPTION_COUNT, OPTION_MAX_LENGTH } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { useCurrentOpenFieldValue, useScrollToLastField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { checkboxFieldAPISchema } from '@/services/serviceDesk/surveys/type';
import preSanitize from '@/utils/pre-sanitize';

import { ActionBar, DragButton, DragListWrapper, DragWrapper, FieldTitle, Highlighter } from '.';

const defaultOptionSchema = checkboxFieldAPISchema.shape.default_option;

type CheckboxFieldEditModeProps = {
  fieldIndex: number;
  isRequireProps: UseFormRegisterReturn;
  questionProps: UseFormRegisterReturn;
  onDelete: () => void;
  onSave: () => void;
  options: Array<{ option: string }> | null;
  updateOptions: (options: Array<{ option: string }> | null) => void;
};

export function CheckboxFieldEditMode({
  fieldIndex,
  isRequireProps,
  questionProps,
  onDelete,
  onSave,
  options,
  updateOptions,
}: CheckboxFieldEditModeProps) {
  const { t } = useTranslation();
  const { labelRef } = useScrollToLastField();

  const currentOpenField = useCurrentOpenFieldValue();
  const { control, setValue } = useFormContext<SurveyFormValue>();

  // Handle add option logic
  const [newOption, setNewOption] = useState('');
  const [isSanitizedNewOptionError, setIsSanitizedNewOptionError] = useState(false);

  const newOptionSanitized = preSanitize(newOption).trim();

  const isNewOptionDuplicate = options?.some(({ option }) => option === newOption) ?? false;
  const isNewOptionSanitizedDuplicate = options?.some(({ option }) => option === newOptionSanitized) ?? false;

  const isDuplicateError = isNewOptionDuplicate || isSanitizedNewOptionError;
  const isReachOptionAmountLimit = options?.length === MAX_OPTION_COUNT;
  const isDisabledAddOptionButton = newOption === '' || isDuplicateError || isReachOptionAmountLimit;

  const addNewOption = () => {
    if (newOptionSanitized === '') {
      setNewOption('');
    } else if (isNewOptionSanitizedDuplicate) {
      setNewOption(newOptionSanitized);
      setIsSanitizedNewOptionError(true);
    } else {
      updateOptions(options ? [...options, { option: newOptionSanitized }] : [{ option: newOptionSanitized }]);
      setNewOption('');
    }
  };

  // Drag & Drop methods
  const handleDragEnd = (event: DragEndEvent) => {
    if (!options || !(event.active && event.over)) return;

    const { active, over } = event;

    if (active.id !== over.id) {
      const originalIndex: number = active.data.current?.sortable.index;
      const droppedIndex: number = over.data.current?.sortable.index;
      updateOptions(arrayMove(options, originalIndex, droppedIndex));
    }
  };

  return (
    <Box w="100%" p="0px 48px 8px" position="relative">
      <Highlighter />
      <FieldTitle title={t('survey:check_box')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-combo-box"
        aria-describedby="edit-combo-box"
        maxLength={LABEL_MAX_LENGTH}
        variant="flushed"
        sx={{
          caretColor: colors.blue[100],
        }}
        _focus={{ borderColor: isDuplicateError ? 'red.100' : 'blue.100' }}
        fontSize="16px"
        size="sm"
        type="text"
        placeholder={t('survey:add_name_for_this_field')}
        m="4px 0 12px"
        {...questionProps}
        ref={(e) => {
          questionProps.ref(e);
          labelRef.current = e;
        }}
      />
      <>
        <Flex alignItems="flex-start" mb="12px" gap="8px" justify="space-between">
          <RippleTextField
            autoFocus={currentOpenField?.autoFocusType === 'input'}
            id="edit-combo-box-options"
            aria-describedby="edit-combo-box-options"
            maxLength={OPTION_MAX_LENGTH}
            sx={{ width: '100%' }}
            size="sm"
            type="text"
            _placeholder={{
              color: 'dark.40',
              fontStyle: 'italic',
              fontWeight: 400,
            }}
            placeholder={t('survey:add_an_option')}
            value={newOption}
            onChange={(e) => {
              setIsSanitizedNewOptionError(false);
              setNewOption(e.target.value);
            }}
            isInvalid={isNewOptionDuplicate}
            helperText={isDuplicateError ? t('survey:the_option_cannot_be_duplicated') : ''}
          />
          <HStack h="40px">
            <RippleIconButton
              isDisabled={isDisabledAddOptionButton}
              variant="ghost"
              aria-label="add option"
              icon={<RippleAdd color={isDisabledAddOptionButton ? 'dark.40' : 'blue.100'} />}
              onClick={addNewOption}
            />
            <RippleBodyText02 w="48px" textAlign="center">
              {t('common:default')}
            </RippleBodyText02>
          </HStack>
        </Flex>

        <Controller
          control={control}
          name={`questionnaire.${fieldIndex}.default_option`}
          render={({ field: { value, onChange } }) => {
            const validationResult = defaultOptionSchema.safeParse(value);
            if (!validationResult.success) {
              return <></>;
            }
            const currentDefaultOption = validationResult.data;
            return (
              <RippleCheckboxGroup value={currentDefaultOption} onChange={onChange}>
                <VStack spacing="8px" alignItems="stretch" mb="8px">
                  {options && (
                    <DragListWrapper
                      items={options.map((optionInfo) => {
                        // @dnd-kit needs `id` for identification
                        return { ...optionInfo, id: optionInfo.option };
                      })}
                      onDragEnd={handleDragEnd}
                    >
                      <Fragment>
                        {options.map(({ option }) => (
                          <DragWrapper key={option} id={option} style={{ _hover: { bg: 'neutral.10' } }}>
                            <Flex gap="8px" alignItems="center" position="relative">
                              <DragButton style={{ left: '0px', opacity: 1, _hover: { bg: 'transparent' } }} />
                              <RippleBodyText02 flex="1" pl="40px">
                                {option}
                              </RippleBodyText02>
                              <RippleIconButton
                                variant="ghost"
                                aria-label="remove-option"
                                icon={<RippleMinus color="neutral.300" />}
                                onClick={() => {
                                  if (currentDefaultOption.includes(option)) {
                                    setValue(
                                      `questionnaire.${fieldIndex}.default_option`,
                                      currentDefaultOption.filter((item) => item !== option),
                                    );
                                  }
                                  updateOptions(options.filter((item) => item.option !== option));
                                }}
                              />
                              <Center w="48px">
                                <RippleCheckbox value={option} />
                              </Center>
                            </Flex>
                          </DragWrapper>
                        ))}
                      </Fragment>
                    </DragListWrapper>
                  )}
                </VStack>
              </RippleCheckboxGroup>
            );
          }}
        />
      </>
      <ActionBar isShowRequired={false} switchProps={isRequireProps} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
}
