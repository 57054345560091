import * as Sentry from '@sentry/nextjs';
import groupBy from 'lodash/groupBy';

import { Group } from '@/services/group';
import { PLATFORMS, Policy } from '@/services/teams/emm_policies';
import { getOSType } from '@/utils/computers';

import { PolicyWithInheritedDepth } from '../../types';
import { createPreparedDefaultPolicy, getPlatformByOs } from '../../utils';
import { ComputerDataForPolicy } from '../useComputerListQuery';
import { GroupPolicyRelation, PolicyAssignmentList, ServerPolicyRelation, TeamPolicy } from './types';

/** Get the team default policy */
export const getTeamPolicy = (policies?: Array<Policy>) => {
  const preparedWinPolicy = createPreparedDefaultPolicy('Windows');
  const preparedMacPolicy = createPreparedDefaultPolicy('macOS');
  if (!policies) {
    return {
      [PLATFORMS.Windows]: preparedWinPolicy,
      [PLATFORMS.macOS]: preparedMacPolicy,
    };
  }
  return {
    [PLATFORMS.Windows]: policies.find((policy) => policy.platform === 'Windows' && policy.super_root) ?? preparedWinPolicy,
    [PLATFORMS.macOS]: policies.find((policy) => policy.platform === 'macOS' && policy.super_root) ?? preparedMacPolicy,
  };
};

/** Get the group policy relation map */
export const getGroupPolicyRelation = ({
  teamPolicy,
  groups,
  groupPolicyMap,
  policyMap,
}: {
  teamPolicy: { [PLATFORMS.Windows]: Policy; [PLATFORMS.macOS]: Policy };
  groups?: Array<Pick<Group, 'id' | 'name'>>;
  groupPolicyMap?: Record<Group['id'], { [PLATFORMS.Windows]: Policy['id']; [PLATFORMS.macOS]: Policy['id'] } | undefined>;
  policyMap?: Record<Policy['id'], Policy>;
}): GroupPolicyRelation => {
  if (!groups || !groupPolicyMap || !policyMap) {
    return {};
  }
  return groups.reduce<GroupPolicyRelation>((acc, group) => {
    const groupPolicies = groupPolicyMap[group.id];
    // There is not any policy assigned to the group, the group will follow the team's default policy
    if (!groupPolicies) {
      acc[group.id] = {
        group,
        policies: {
          Windows: { policy: teamPolicy['Windows'], isFollowTeam: true },
          macOS: { policy: teamPolicy['macOS'], isFollowTeam: true },
        },
      };
      return acc;
    }

    acc[group.id] = {
      group,
      policies: {
        Windows:
          // if there is a policy assigned to the group, the group will not follow the team's default policy
          PLATFORMS.Windows in groupPolicies
            ? { policy: policyMap[groupPolicies['Windows']], isFollowTeam: false }
            : { policy: teamPolicy['Windows'], isFollowTeam: true },
        macOS:
          // if there is a policy assigned to the group, the group will not follow the team's default policy
          PLATFORMS.macOS in groupPolicies
            ? { policy: policyMap[groupPolicies['macOS']], isFollowTeam: false }
            : { policy: teamPolicy['macOS'], isFollowTeam: true },
      },
    };
    return acc;
  }, {});
};

/**
 * Get the server policy relation map
 * There are basically 3 types of server policy relation:
 * 1. Server has policy assigned
 * 2. Server is following group and group has policy assigned, server will follow group's policy
 * 3. Server does'nt have group or group has no policy assigned, the server will follow the team's default policy
 */
export const getServerPolicyRelation = ({
  teamPolicy,
  groupPolicyRelation,
  serverPolicyMap,
  servers,
  policyMap,
}: {
  teamPolicy: TeamPolicy;
  groupPolicyRelation?: GroupPolicyRelation;
  servers?: Array<ComputerDataForPolicy>;
  serverPolicyMap?: Record<
    number,
    {
      emm_policy_id: number;
      server_id: number;
    }
  >;
  policyMap?: Record<Policy['id'], Policy>;
}): ServerPolicyRelation => {
  if (!servers || !serverPolicyMap || !policyMap) {
    return {};
  }
  return servers.reduce<ServerPolicyRelation>((acc, server) => {
    const platform = getPlatformByOs(server.version);
    // Unsupported platform
    if (!platform) {
      return acc;
    }

    // Type 1: Server has policy assigned
    if (serverPolicyMap[server.id]) {
      const policyId = serverPolicyMap[server.id].emm_policy_id;
      const policy = policyMap[policyId];
      const parentPolicy = policy.parent_id ? policyMap[policy.parent_id] : null;
      const isCustomized = policy.policy_kind === 'server';
      if (!isCustomized) {
        acc[server.id] = {
          groupId: server.group_id,
          isFollowGroup: false,
          server,
          policy,
          platform,
          parentPolicy,
          isCustomized,
        };
        return acc;
      }
      if (!policy.parent_id) {
        Sentry.captureException(`Customized policy has no parent, Policy ID: ${policy.id}`);
        return acc;
      }
      acc[server.id] = {
        groupId: server.group_id,
        isFollowGroup: false,
        server,
        policy,
        platform,
        parentPolicy,
        isCustomized,
      };
      return acc;
    }

    // Server is following group
    const groupId = server.group_id;
    const groupPolicy = groupId ? groupPolicyRelation?.[groupId]?.policies[platform].policy : null;

    if (groupPolicy) {
      // Type 2: Server is follow group, and group has policy assigned
      const groupParentPolicyId = groupPolicy.parent_id;
      const groupParentPolicy = groupParentPolicyId ? policyMap[groupParentPolicyId] : null;

      acc[server.id] = {
        groupId: server.group_id,
        policy: groupPolicy,
        parentPolicy: groupParentPolicy,
        isFollowGroup: true,
        isCustomized: false,
        server,
        platform,
      };

      return acc;
    }

    // Type 3: Server does'nt have group or group has no policy assigned, the server will follow the team's default policy
    acc[server.id] = {
      groupId: server.group_id,
      isFollowGroup: true,
      isCustomized: false,
      policy: teamPolicy[platform],
      server,
      platform,
      parentPolicy: null, // Team default policy has no parent
    };
    return acc;
  }, {});
};

type GetPolicyAssignmentListProps = {
  teamPolicy: TeamPolicy;
  groupPolicyRelation?: GroupPolicyRelation;
  serverPolicyRelation?: ServerPolicyRelation;
  policies?: Array<PolicyWithInheritedDepth>;
  groups?: Array<Pick<Group, 'id' | 'name'>>;
  servers?: Array<ComputerDataForPolicy>;
  formatDateTime: (date: string) => string;
};

export const getPolicyAssignmentList = ({
  teamPolicy,
  groupPolicyRelation,
  serverPolicyRelation,
  policies,
  groups,
  servers,
  formatDateTime,
}: GetPolicyAssignmentListProps): PolicyAssignmentList => {
  // No policy, all servers and group will follow the team's default policy
  if (!policies) {
    const winPolicy = teamPolicy['Windows'];
    const macPolicy = teamPolicy['macOS'];
    const serverByOs = servers?.reduce(
      (acc, server) => {
        const { type } = getOSType(server.version);
        if (type === 'win') {
          acc.win += 1;
        } else if (type === 'mac') {
          acc.mac += 1;
        }
        return acc;
      },
      { mac: 0, win: 0 },
    ) ?? { mac: 0, win: 0 };

    return [
      {
        policyNode: {
          policy: winPolicy,
          children: [],
          depth: 0,
        },
        groupCount: groups?.length ?? 0,
        serverCount: serverByOs?.win,
        overriddenCount: 0,
        formatedLastUpdated: '',
      },
      {
        policyNode: {
          policy: macPolicy,
          children: [],
          depth: 0,
        },
        groupCount: groups?.length ?? 0,
        serverCount: serverByOs?.mac,
        overriddenCount: 0,
        formatedLastUpdated: '',
      },
    ];
  }

  const serversWithSamePolicy = groupBy(serverPolicyRelation, (node) => node.policy.id);
  const customizedPolicyMap = groupBy(
    policies.filter((node) => node.policy.policy_kind === 'server'),
    (node) => node.policy.parent_id,
  );

  return policies.map((policy) => {
    const platform = policy.policy.platform;
    const id = policy.policy.id;
    const groups = groupPolicyRelation
      ? Object.values(groupPolicyRelation).filter((group) => group.policies[platform].policy.id === id)
      : [];
    const servers = serversWithSamePolicy[id]?.filter((server) => server.platform === platform) ?? [];
    const serverCount = servers.length;
    const overriddenCount = customizedPolicyMap[policy.policy.id]?.length ?? 0;
    const formatedLastUpdated = policy.policy.updated_at ? formatDateTime(policy.policy.updated_at) : '';
    return {
      policyNode: policy,
      groupCount: groups.length,
      formatedLastUpdated,
      serverCount,
      overriddenCount,
    };
  });
};
