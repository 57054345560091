import { Survey } from '@/services/serviceDesk/surveys';

import { SurveyFormMode } from '../types';

export type CurrentSurveyFormNameList = Array<{ name: Survey['name']; formId: Survey['id'] }>;
type IsDuplicateSurveyFormName = {
  currentSurveyFormNameList: CurrentSurveyFormNameList;
  currentSurveyFormId: number;
  mode: SurveyFormMode;
  surveyFormName: string;
};

export const isDuplicateSurveyFormName = ({
  currentSurveyFormNameList,
  currentSurveyFormId,
  mode,
  surveyFormName,
}: IsDuplicateSurveyFormName) => {
  if (mode === 'edit') {
    // Avoid validating current form itself
    return currentSurveyFormNameList
      .filter((formInfo) => formInfo.formId !== currentSurveyFormId)
      .map((formInfo) => formInfo.name.toLocaleLowerCase())
      .includes(surveyFormName.toLocaleLowerCase());
  }
  return currentSurveyFormNameList.map((formInfo) => formInfo.name.toLocaleLowerCase()).includes(surveyFormName.toLocaleLowerCase());
};
