import axios from 'axios';
import { z } from 'zod';

import { TeamKind } from '@/models/TeamInformation/types';
import { isValidateDateTime } from '@/utils/datetime';

import { GranularControlKeys } from '../teams/team_members/granular_controls/utils';
import { checkResponse } from '../utils';

const method = 'POST';

const getUrl = () => '/api/web/v1/users/info_query';

const execute = <Q extends QueryMap, T extends TeamKind>(query: Q, teamKinds: Array<T>) => {
  return checkResponse(axios.request({ method, url: getUrl(), data: query }), generateSchema(query, teamKinds));
};

export const infoQueryService = {
  method,
  getUrl,
  execute,
} as const;

const LicenseInfoSchema = z.object({
  end_users: z.number(),
  technicians: z.number(),
  end_users_count: z.number(),
  technicians_count: z.number(),
  occupied_end_users_count: z.number(),
  occupied_technicians_count: z.number(),
  technician_required_granular_controls: z.array(
    z.union([
      z.literal(GranularControlKeys.attended_access),
      z.literal(GranularControlKeys.one_to_many),
      z.literal(GranularControlKeys.command_prompt),
    ]),
  ),
});

export function generateSchema<Q extends QueryMap, T extends TeamKind>(query: Q, teamKinds: ReadonlyArray<T>) {
  return z.custom<Record<T, TeamKindResult<Q>>>((data: any) => {
    return teamKinds.every((teamKind) => {
      const teamKindResult = data[teamKind];

      if (!teamKindResult) return false;

      let result = true;
      if (query.seat_permissions) {
        result =
          result &&
          teamKindResult.seat_permissions &&
          query.seat_permissions.every((seatPermission) => seatPermission in teamKindResult.seat_permissions);
      }

      if (query.team_permissions) {
        result =
          result &&
          teamKindResult.team_permissions &&
          query.team_permissions.every((teamPermission) => teamPermission in teamKindResult.team_permissions);
      }

      if (query.team_info) {
        query.team_info.forEach((teamInfo) => {
          if (teamInfo === 'license_info') {
            result = LicenseInfoSchema.safeParse(teamKindResult.team_info.license_info).success;
          } else if (teamInfo === 'expires_at') {
            result = isValidateDateTime(teamKindResult.team_info.expires_at);
          } else if (teamInfo === 'is_expired') {
            result = 'is_expired' in teamKindResult.team_info;
          } else {
            const error: never = teamInfo;
            throw new Error(error);
          }
        });
      }

      return result;
    });
  });
}

// TODO: Complete the query type
type QueryMap = {
  seat_permissions?: ReadonlyArray<SeatPermissionKey>;
  team_permissions?: ReadonlyArray<TeamPermissionKey>;
  team_info?: ReadonlyArray<TeamInfoKey>;
};

type SeatPermissionKey = 'premier_pack';
type TeamPermissionKey = 'premier_pack' | 'preference_policy';
type TeamInfoKey = 'license_info' | 'expires_at' | 'is_expired';

type TeamKindResult<Q extends QueryMap> = SeatPermissionResult<Q> & TeamPermissionResult<Q> & TeamInfoResult<Q>;

type SeatPermissionResult<Q extends QueryMap> = Q['seat_permissions'] extends Array<SeatPermissionKey>
  ? { seat_permissions: Record<Q['seat_permissions'][number], boolean> }
  : { seat_permissions?: never };

type TeamPermissionResult<Q extends QueryMap> = Q['team_permissions'] extends Array<TeamPermissionKey>
  ? { team_permissions: Record<Q['team_permissions'][number], boolean> }
  : { team_permissions?: never };

type TeamInfoResult<Q extends QueryMap> = Q['team_info'] extends Array<TeamInfoKey>
  ? {
      team_info: Record<
        Q['team_info'][number],
        Q['team_info'][number] extends 'license_info'
          ? z.infer<typeof LicenseInfoSchema>
          : Q['team_info'][number] extends 'expires_at'
          ? string
          : boolean
      >;
    }
  : { team_info?: never };
