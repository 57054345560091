import { Box, Flex } from '@chakra-ui/react';

import { SurveyFormScenario } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { BrandImageMap } from '@/services/serviceDesk/surveys/type';
import { ViewSurvey } from '@/services/serviceDesk/surveys/viewSurvey';

import { BrandImage, WelcomeMessage } from './';

type HeaderProps = {
  scenario: SurveyFormScenario;
  welcomeTitle: ViewSurvey['welcome_message']['title'];
  welcomeSubtitle: ViewSurvey['welcome_message']['subtitle'];
  brandImage: ViewSurvey['brand_image'];
  brandImageUrl: ViewSurvey['brand_image_url'];
};

export const Header = ({ scenario, welcomeTitle, welcomeSubtitle, brandImage, brandImageUrl }: HeaderProps) => {
  const noWelcomeMessageField = welcomeTitle === null && welcomeSubtitle === null;
  const isShowBrandImage = brandImage !== null;
  const isShowSplashtopLogo = brandImage === BrandImageMap.splashtop;

  const isShowWelcomeMessage = !noWelcomeMessageField;
  const isShowHeader = isShowWelcomeMessage || isShowBrandImage;
  return (
    <>
      {isShowHeader && (
        <Flex w="88%" direction="column" align="center">
          {isShowBrandImage && (
            <BrandImage scenario={scenario} isShowSplashtopLogo={isShowSplashtopLogo} customLogoSrc={brandImageUrl || ''} />
          )}
          {isShowWelcomeMessage && <WelcomeMessage scenario={scenario} title={welcomeTitle} subtitle={welcomeSubtitle} />}
          <HeaderDivider />
        </Flex>
      )}
    </>
  );
};

export const HeaderDivider = () => <Box w="100%" my="24px" h="1px" bg="neutral.100" />;
