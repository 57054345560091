import { ChangeEventHandler } from 'react';

import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleButton, RippleHeading06, RippleSection, RippleSheetAction, RippleTextField } from '@/design';
import { SurveyFormValue, SurveyFormValueKey } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import preSanitize from '@/utils/pre-sanitize';

import { DESCRIPTION_MAX_LENGTH, FORM_NAME_MAX_LENGTH, SURVEY_FORM_CONTENT_WIDTH } from './constant';
import { SurveyFormMode } from './types';
import { type CurrentSurveyFormNameList, isDuplicateSurveyFormName } from './utils';

type GeneralProps = {
  mode: SurveyFormMode;
  surveyFormId: number;
  currentSurveyFormNameList: CurrentSurveyFormNameList;
  onClickNextStep: () => void;
  onClickPreviousStep: () => void;
};

export const General = ({ mode, surveyFormId, currentSurveyFormNameList, onClickNextStep, onClickPreviousStep }: GeneralProps) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useFormContext<SurveyFormValue>();

  const handleFieldChange: (fieldName: SurveyFormValueKey) => ChangeEventHandler<HTMLInputElement> = (fieldName) => (e) => {
    setValue(fieldName, e.target.value);
    // Clear errors set when submitting the form
    if (errors[fieldName]) {
      clearErrors(fieldName);
    }
  };

  const handleNextStep = () => {
    const [name, description] = getValues(['name', 'description']);
    const sanitizedFormName = preSanitize(name).trim();

    setValue('name', sanitizedFormName);
    setValue('description', preSanitize(description).trim());

    const isFormNameValid = sanitizedFormName !== '';
    const isDuplicateFormName = isDuplicateSurveyFormName({
      currentSurveyFormNameList,
      mode,
      surveyFormName: sanitizedFormName,
      currentSurveyFormId: surveyFormId,
    });

    if (!isFormNameValid || isDuplicateFormName) {
      setError('name', { message: t('survey:survey_name_cannot_be_blank_or_duplicated') });
    } else {
      onClickNextStep();
    }
  };
  return (
    <>
      <HStack w={SURVEY_FORM_CONTENT_WIDTH} mt="48px" gap="24px" flexDirection="column" alignItems="flex-start">
        <RippleHeading06>{t('survey:general')}</RippleHeading06>
        <RippleSection name={t('common:name')} w="56%">
          <RippleTextField
            size="sm"
            placeholder={t('survey:survey_name')}
            maxLength={FORM_NAME_MAX_LENGTH}
            {...register('name')}
            isInvalid={Boolean(errors.name)}
            helperText={errors?.name?.message}
            onChange={handleFieldChange('name')}
          />
        </RippleSection>
        <RippleSection name={t('survey:description')} w="56%">
          <RippleTextField
            size="sm"
            placeholder={t('survey:survey_description')}
            maxLength={DESCRIPTION_MAX_LENGTH}
            {...register('description')}
          />
        </RippleSection>
      </HStack>
      <RippleSheetAction>
        <RippleButton size="sm" variant="grayScaleGhost" onClick={onClickPreviousStep}>
          {t('common:cancel')}
        </RippleButton>
        <RippleButton
          size="sm"
          onClick={() => {
            handleNextStep();
          }}
        >
          {t('common:next')}
        </RippleButton>
      </RippleSheetAction>
    </>
  );
};
