import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    load: 'currentOnly', // https://github.com/i18next/i18next/issues/1467
    fallbackLng: false,
    ns: ['common'],
    defaultNS: 'common',
    supportedLngs: ['en', 'de', 'es', 'fr', 'it', 'ja', 'ko', 'zh-CN', 'zh-TW', 'pt', 'he'],

    backend: {
      loadPath: '/next/locales/{{lng}}/{{ns}}.json',
    },

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
