import { useEffect, useMemo, useState } from 'react';

import { HStack, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  RippleButton,
  RippleInput,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleSingleSelect,
  RippleSingleSelectOption,
  RippleSwitch,
  RippleTextarea,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import { PLATFORMS, Policy } from '@/services/teams/emm_policies';
import preSanitize from '@/utils/pre-sanitize';

import { MAX_POLICY_DEPTH, MAX_POLICY_DESC_LENGTH, MAX_POLICY_NAME_LENGTH } from '../constants';
import { PolicyAssignmentList } from '../hooks';
import { getPlatformString } from '../utils';

type CreatePolicyModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  policesByPlatform: Record<Policy['platform'], PolicyAssignmentList>;
  parentPolicy: Policy | null;
  onClose: () => void;
  onCreate: (policy: {
    name: string;
    description: string;
    active: boolean;
    platform: Policy['platform'];
    parent_id: number | null;
  }) => void;
};

export const CreatePolicyModal = ({ isOpen, isLoading, policesByPlatform, parentPolicy, onClose, onCreate }: CreatePolicyModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [platform, setPlatform] = useState<Policy['platform']>(parentPolicy?.platform ?? 'Windows');
  const [parentPolicyId, setParentPolicyId] = useState<number | null>(parentPolicy?.id ?? null);
  const [enabled, setEnabled] = useState(true);
  const isCreatingChildPolicy = Boolean(parentPolicy);

  const nameSet = useMemo(
    () =>
      new Set(
        Object.values(policesByPlatform)
          .flat()
          .map(({ policyNode }) => policyNode.policy.name),
      ),
    [policesByPlatform],
  );

  const isNameDuplicated = nameSet.has(name);
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > MAX_POLICY_NAME_LENGTH) {
      return;
    }
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > MAX_POLICY_DESC_LENGTH) {
      return;
    }
    setDescription(e.target.value);
  };

  const handleNameBlur = () => {
    setName((name) => preSanitize(name).trim());
  };

  const handleCreate = () => {
    onCreate({
      name,
      description,
      active: enabled,
      platform,
      parent_id: parentPolicyId,
    });
  };

  const handleClose = () => {
    setName('');
    setDescription('');
    setPlatform('Windows');
    setParentPolicyId(null);
    setEnabled(true);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setPlatform(parentPolicy?.platform ?? PLATFORMS.Windows);
      setParentPolicyId(parentPolicy?.id ?? null);
    }
  }, [isOpen, parentPolicy?.id, parentPolicy?.platform]);

  return (
    <RippleModal isOpen={isOpen} onClose={onClose}>
      <RippleModalOverlay />
      <RippleModalContent w="640px">
        <RippleModalHeader>
          <RippleTypography variant="heading05">{t('emm-policy:create_policy')}</RippleTypography>
        </RippleModalHeader>
        <RippleModalBody>
          <Stack gap="32px" alignItems="stretch">
            <Stack gap="8px">
              <RippleTypography variant="heading08">{t('common:name')}</RippleTypography>
              <Stack gap="4px">
                <RippleInput
                  size="sm"
                  placeholder={t('common:name')}
                  value={name}
                  onBlur={handleNameBlur}
                  onChange={handleNameChange}
                  isInvalid={isNameDuplicated}
                />
                {isNameDuplicated && (
                  <RippleTypography variant="body03" color="red.100">
                    {t('common:duplicated_name')}
                  </RippleTypography>
                )}
              </Stack>
            </Stack>
            <Stack gap="8px">
              <RippleTypography variant="heading08">{t('emm-policy:description')}</RippleTypography>
              <RippleTextarea placeholder={t('emm-policy:description')} rows={3} value={description} onChange={handleDescriptionChange} />
            </Stack>
            <HStack gap="24px">
              <Stack gap="8px" flex="1">
                <RippleTypography variant="heading08">{t('emm-policy:platform')}</RippleTypography>
                <RippleSingleSelect
                  placeholder={t('emm-policy:platform')}
                  value={platform}
                  isDisabled={isCreatingChildPolicy}
                  onChange={(platform) => {
                    if (platform) {
                      setPlatform(platform);
                      setParentPolicyId(null);
                    }
                  }}
                >
                  {Object.keys(PLATFORMS).map((platform) => (
                    <RippleSingleSelectOption value={platform} key={platform} title={platform}>
                      {getPlatformString(platform)}
                    </RippleSingleSelectOption>
                  ))}
                </RippleSingleSelect>
              </Stack>
              <Stack gap="8px" flex="1" overflow="hidden">
                <RippleTypography variant="heading08">{t('emm-policy:parent')}</RippleTypography>
                <RippleTooltip
                  label={
                    policesByPlatform[platform].find((policy) => policy.policyNode.policy.id === parentPolicyId)?.policyNode.policy.name ??
                    t('common:none')
                  }
                >
                  <RippleSingleSelect
                    placeholder={t('emm-policy:parent')}
                    isDisabled={isCreatingChildPolicy}
                    value={parentPolicyId ? String(parentPolicyId) : ''}
                    onChange={(id) => setParentPolicyId(id ? Number(id) : null)}
                  >
                    <RippleSingleSelectOption value="">{t('common:none')}</RippleSingleSelectOption>
                    {policesByPlatform[platform]
                      .toSorted((a, b) => a.policyNode.policy.name.localeCompare(b.policyNode.policy.name))
                      .map(({ policyNode }) => (
                        <RippleSingleSelectOption
                          value={String(policyNode.policy.id)}
                          key={policyNode.policy.id}
                          title={policyNode.policy.name}
                          isDisabled={policyNode.depth >= MAX_POLICY_DEPTH}
                        >
                          <RippleTooltip aria-label={policyNode.policy.name} label={policyNode.policy.name}>
                            {policyNode.policy.name}
                          </RippleTooltip>
                        </RippleSingleSelectOption>
                      ))}
                  </RippleSingleSelect>
                </RippleTooltip>
              </Stack>
            </HStack>
            {/* TODO: Support assign to group admin when spec is ready */}
            {/* <Stack gap="8px">
              <RippleTypography variant="heading08">Group admin</RippleTypography>
              <RippleSingleSelect placeholder="Group admin" value="1" onChange={() => {}}>
                <RippleSingleSelectOption value="1">Admin 1</RippleSingleSelectOption>
              </RippleSingleSelect>
            </Stack> */}
            <HStack gap="12px">
              <RippleTypography variant="heading08">{t('common:enabled')}</RippleTypography>
              <RippleSwitch size="md" isChecked={enabled} onChange={(e) => setEnabled(e.target.checked)} />
            </HStack>
          </Stack>
        </RippleModalBody>
        <RippleModalFooter gap="8px">
          <RippleButton size="sm" variant="grayScaleGhost" onClick={handleClose}>
            {t('common:cancel')}
          </RippleButton>
          <RippleButton size="sm" onClick={handleCreate} isDisabled={!name || isNameDuplicated} isLoading={isLoading}>
            {t('common:create')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
