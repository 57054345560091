import { featureControl } from '@/feature/toggle';
import type { Team } from '@/services/common/types';

/**
 * **Should pass `srs` team metadata**
 *
 * New SOS team is based on SRS Enterprise team (STE)
 */
export function isNewSOS(srsTeamMetadata: Pick<Team, 'plan'> | null | undefined): boolean {
  const matchedPlan: Array<string> = [
    'ste_lite_sos_plus',
    'ste_lite_sos_basic',
    ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
      ? ['ste_lite_sos_unlimited']
      : []),
  ];

  return matchedPlan.includes(srsTeamMetadata?.plan ?? '');
}

/**
 * **Should pass `srs` team metadata**
 *
 * New SOS team is based on SRS Enterprise team (STE)
 * New SOS Basic team have the same features to old SOS team
 * **ONLY** have **attended** features
 */
export function isNewSOSBasic(srsTeamMetadata: Pick<Team, 'plan'> | null | undefined): boolean {
  return srsTeamMetadata?.plan === 'ste_lite_sos_basic';
}

/**
 * **Should pass `srs` team metadata**
 *
 * New SOS team is based on SRS Enterprise team (STE)
 */
export function isNewSOSPlus(srsTeamMetadata: Pick<Team, 'plan'> | null | undefined): boolean {
  return (
    srsTeamMetadata?.plan === 'ste_lite_sos_plus' ||
    (featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment') &&
      srsTeamMetadata?.plan === 'ste_lite_sos_unlimited')
  );
}
