import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { RippleInput } from '@/design';
import colors from '@/design/theme/colors';
import { ActionBar, FieldTitle, Highlighter } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode';
import { LABEL_MAX_LENGTH, PLACEHOLDER_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useCurrentOpenFieldAtom } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { useScrollToLastField } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useScrollToLastField';

type BriefAnswerFieldEditModeProps = {
  isRequireProps: UseFormRegisterReturn;
  labelProps: UseFormRegisterReturn;
  onDelete: () => void;
  onSave: () => void;
  placeholderProps: UseFormRegisterReturn;
};

export function BriefAnswerFieldEditMode({
  isRequireProps,
  labelProps,
  onDelete,
  onSave,
  placeholderProps,
}: BriefAnswerFieldEditModeProps) {
  const { t } = useTranslation();
  const { labelRef } = useScrollToLastField();
  const currentOpenField = useCurrentOpenFieldAtom();

  return (
    <Box w="100%" p="0px 36px 12px" position="relative">
      <Highlighter />
      <FieldTitle title={t('support-forms:shortTextField')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-brief-answer-title"
        aria-describedby="edit-brief-answer-title"
        maxLength={LABEL_MAX_LENGTH}
        variant="flushed"
        sx={{
          caretColor: colors.blue[100],
        }}
        fontSize="12px"
        size="sm"
        type="text"
        placeholder={t('support-forms:addName')}
        _placeholder={{
          fontStyle: 'italic',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'dark.40',
        }}
        mb="12px"
        {...labelProps}
        ref={(e) => {
          labelProps.ref(e);
          labelRef.current = e;
        }}
      />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'input'}
        id="edit-brief-answer-placeholder"
        aria-describedby="edit-brief-answer-placeholder"
        maxLength={PLACEHOLDER_MAX_LENGTH}
        variant="editPlaceholder"
        size="sm"
        type="text"
        placeholder={t('support-forms:addPlaceholderText')}
        mb="12px"
        {...placeholderProps}
      />
      <ActionBar switchProps={isRequireProps} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
}
