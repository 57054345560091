import { ReactElement } from 'react';

import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleBadge, RippleTypography } from '@/design';
import { TeamMemberStatus } from '@/services/team/types';
import { TeamMemberRoleIcon, TeamMemberRoleTag, getTeamMemberRoleLabelMap } from '@/showcase';

export function UserRoleCell({ roleTag, hasHoverStyle }: { roleTag: TeamMemberRoleTag | null; hasHoverStyle?: boolean }) {
  const { t } = useTranslation();

  if (roleTag === null) {
    return null;
  }

  const labelMap = getTeamMemberRoleLabelMap(t);
  const label = labelMap[roleTag];

  return (
    <HStack spacing="8px">
      <TeamMemberRoleIcon roleTag={roleTag} />
      <RippleTypography
        variant="body02"
        borderStyle="dotted"
        borderBottomWidth="2px"
        borderColor="transparent"
        _hover={
          hasHoverStyle
            ? {
                borderColor: 'blue.100',
              }
            : undefined
        }
      >
        {label}
      </RippleTypography>
    </HStack>
  );
}

const statusLabelMap: Record<TeamMemberStatus, () => ReactElement> = {
  enabled: function Label() {
    const { t } = useTranslation();

    return (
      <RippleBadge variant="secondary" colorScheme="success">
        {t('userManagement:userStatusEnabled')}
      </RippleBadge>
    );
  },
  invited: function Label() {
    const { t } = useTranslation();

    return (
      <RippleBadge variant="secondary" colorScheme="darkBlue">
        {t('userManagement:userStatusInvited')}
      </RippleBadge>
    );
  },
  disabled: function Label() {
    const { t } = useTranslation();

    return (
      <RippleBadge variant="primary" colorScheme="grey">
        {t('userManagement:userStatusDisabled')}
      </RippleBadge>
    );
  },
  declined: function Label() {
    const { t } = useTranslation();

    return (
      <RippleBadge variant="primary" colorScheme="grey">
        {t('userManagement:userStatusDeclined')}
      </RippleBadge>
    );
  },
  quit: function Label() {
    const { t } = useTranslation();

    return (
      <RippleBadge variant="secondary" colorScheme="danger">
        {t('userManagement:userStatusQuit')}
      </RippleBadge>
    );
  },
};

export function UserStatusCell({ status }: { status: TeamMemberStatus }) {
  const Label = statusLabelMap[status];
  return (
    <HStack spacing="8px">
      <Label />
    </HStack>
  );
}
