import axios from 'axios';
import { z } from 'zod';

import { twoStepVerificationErrorSchema } from '@/services/schemas';
import { policyItemValueSchema } from '@/services/teams/emm_policies/streamer_policies/type';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

/*
 * Update Streamer Preference Policy API
 * Update a streamer preference policy
 * @param teamId
 * @param emmPolicyId
 * @doc Return a list of available streamer preference policy items
 */

const updateStreamerPreferencePoliciesUrl = (teamId: number, emmPolicyId: number) =>
  `/api/web/v1/teams/${teamId}/emm_policies/${emmPolicyId}/streamer_policy`;

const createItemRequestDataSchema = z.object({
  code: z.string(),
  operator: z.union([z.literal('+'), z.literal('-')]),
  value: policyItemValueSchema.optional(),
});
export type CreateItemRequestData = z.infer<typeof createItemRequestDataSchema>;

const updateItemRequestDataSchema = z.object({
  id: z.number(),
  code: z.string(),
  operator: z.union([z.literal('+'), z.literal('-')]),
  value: policyItemValueSchema.optional(),
});

const updateStreamerPreferencePoliciesSchema = z.object({
  create_items: z.array(createItemRequestDataSchema),
  update_items: z.array(updateItemRequestDataSchema),
  delete_items: z.array(z.number()),
});
export type UpdateStreamerPreferencePoliciesBody = z.infer<typeof updateStreamerPreferencePoliciesSchema>;

const responseDataSchema = z.object({
  emm_policy_id: z.number().nullable(),
  parent_id: z.number().nullable(),
});

export function updateStreamerPolicies(
  teamId: number,
  emmPolicyId: number,
  updateStreamerPreferencePoliciesBody: UpdateStreamerPreferencePoliciesBody,
) {
  return checkResponse(
    axios.patch(updateStreamerPreferencePoliciesUrl(teamId, emmPolicyId), {
      ...updateStreamerPreferencePoliciesBody,
    }),
    responseDataSchema,
  );
}

const errorHandling = {
  ...createGenericErrorHandlers(),
  require2SV: (response: any) => {
    return twoStepVerificationErrorSchema.safeParse(response).success;
  },
} as const;

export const updateStreamerPoliciesService = {
  getUrl: updateStreamerPreferencePoliciesUrl,
  method: 'PATCH',
  execute: updateStreamerPolicies,
  errorHandling,
};
