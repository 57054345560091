import { useAttendedTeamInformation, useUnattendedTeamInformation } from '@/models/TeamInformation';
import { shouldShowServiceDeskChannelByEnterprise, shouldShowServiceDeskChannelBySOS } from '@/modules/ServiceDesk';
import { Team } from '@/services/common/types';
import { compareToCurrentDate } from '@/utils/useTeamInformation';

type UseTeamInfoForChannels = {
  teamType: 'sos' | 'srs' | '';
  teamId: number;
  memberStatus: Team['member_status'];
  isExpired: boolean;
  canAccessTeamSetting: boolean;
};

export function useTeamInfoForChannels(): UseTeamInfoForChannels {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();

  if (attendedTeamInformation && shouldShowServiceDeskChannelBySOS(attendedTeamInformation)) {
    const {
      team_id: teamId,
      member_status: memberStatus,
      expires_at,
      team_member_permissions: { team_settings_management },
    } = attendedTeamInformation;
    return {
      teamType: 'sos',
      teamId,
      memberStatus,
      isExpired: compareToCurrentDate(expires_at),
      canAccessTeamSetting: team_settings_management ?? false,
    };
  } else if (unattendedTeamInformation && shouldShowServiceDeskChannelByEnterprise(unattendedTeamInformation)) {
    const {
      team_id: teamId,
      member_status: memberStatus,
      expires_at,
      team_member_permissions: { team_settings_management },
    } = unattendedTeamInformation;
    return {
      teamType: 'srs',
      teamId,
      memberStatus,
      isExpired: compareToCurrentDate(expires_at),
      canAccessTeamSetting: team_settings_management ?? false,
    };
  }

  return {
    teamType: '',
    teamId: -1,
    memberStatus: 'disabled',
    isExpired: true,
    canAccessTeamSetting: false,
  };
}
