/**
 * Remove groups policy by platform
 * @see https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/emm/emm_policies.md#remove-policy-from-groups-by-platform-api
 */
import axios from 'axios';
import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { checkResponse, createGenericErrorHandlers } from '@/services/utils';

import { policyPlatformEnum } from './types';

const METHOD = 'POST';
const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/emm_policies/remove_policy_from_groups_by_platform`;

const payloadSchema = z.object({ group_ids: z.array(z.number()), platform: policyPlatformEnum });
type Payload = z.infer<typeof payloadSchema>;

const responseSchema = z.object({ success: z.array(z.number()), fail: z.array(z.number()) });

const execute = (teamId: number, payload: Payload) =>
  checkResponse(axios({ method: METHOD, url: getUrl(teamId), data: payload }), responseSchema);

const errorHandling = {
  ...createGenericErrorHandlers(),
  /**
   * @code 40409
   * - Group IDs exceed maximum limit (5000)
   */
  exceedNumberLimit: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal('40409'), data: z.object({ error: z.literal('exceed_number_limit') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
  /**
   * @code 40422
   * - Group ID is empty
   * - Platform is empty
   */
  wrongParams: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal('40422'), data: z.object({ error: z.literal('wrong_params') }) }),
    );
    return errorSchema.safeParse(response).success;
  },
} as const;

export const removeEmmPolicyFromGroupsByPlatformService = { getUrl, method: METHOD, execute, errorHandling };
