import { z } from 'zod';

import { featureControl } from '@/feature/toggle';
import { TeamKind } from '@/modules/TeamSettings/types';
import type { GranularControlKey } from '@/services/team/types';

import { SupportSessionKindInNumber } from '../serviceDesk/supportSession';

// Keys to read config from backend
export const settingKeys = [
  'file',
  'file_transfer',
  'off_session_file',
  'remote_print',
  'device_redirect',
  'remote_mic',
  'copy_paste',
  'keystroke',
  'wake_on_lan',
  'remote_reboot',
  'chat',
  'chat_transcript',
  'off_session_chat',
  'off_session_chat_transcript',
  'voice_call',
  'sos_voice_call',
  'session_recording',
  'sos_session_recording',
  'share_screen',
  'sos_share_screen',
  'multi_session',
  'sos_multi_session',
  'watermark',
  'sos_watermark',
  'command_prompt',
  'rdp_computer',
  'one_to_many',
  'create_group_admin',
  'os_logon_info',
  'vulnerability_score',
  'member_list',
  'display_group_to_member',
  'member_share_computer',
  'save_credential',
  'save_security_code',
  'client_stay_login',
  'client_idle_logout',
  'multiple_member_session',
  'member_concurrent_session',
  'member_disconnect_session',
  'member_reboot_computer',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'session_notification',
  'file_session_notification',
  'cmpt_session_notification',
  'sos_session_notification',
  'sos_file_session_notification',
  'sos_cmpt_session_notification',
  'web_src',
  'sos_member_list',
  'force_member_2step_auth',
  'force_admin_2step_auth',
  'schedule_time_zone',
  'sta_management_tab',
  '2sv_trust',
  '2sv_trust_ttl',
  '2sv_replace_device_auth',
  'sos_accept_team',
  'sos_decline_team',
  'sos_quit_team',
  'sos_seat_enabled',
  'sos_seat_disabled',
  'sos_user_removed',
  'sos_exceeding_max_user_license_limit',
  'stb_accept_team',
  'stb_decline_team',
  'stb_quit_team',
  'stb_computer_added',
  'stb_computer_removed',
  'team_change_role_to_manager',
  'team_change_role_to_member',
  'stb_seat_enabled',
  'stb_seat_disabled',
  'stb_exceeding_max_user_license_limit',
  'stb_user_removed',
  'srs_to_online',
  'srs_to_offline',
  'srs_conn_notification',
  'srs_disconn_notification',
  'srs_conn_notify_all_pcs',
  'srs_disconn_notify_all_pcs',
  'srs_to_online_notify_all_pcs',
  'srs_to_offline_notify_all_pcs',
  'device_auth_notify',
  'device_auth_notify_browser',
  'device_auth_ttl',
  'device_auth_ttl_browser',
  'srs_download',
  'extra_src_management',
  'blank_computer_list',
  'pcp_idle_timeout',
  'threats_notification',
  'granular_control',
  'scim',
  'vnc_computer',
  'computer_notes_permission',
  'sos_web_src',
  'premium_tool',
  'premium_session_notification',
  'macaddr_whitelist',
  'multiple_concurrent_licenses',
  'service_desk_private_customer_survey',
  'relay_recording',
  'sos_relay_recording',
  'relay_recording_access',
  'relay_recording_access_mode',
  'sos_relay_recording_access',
  'sos_relay_recording_access_mode',
  'sos_service_desk_chat_transcript_display',
  'service_desk_private_package_id',
  'background_action_session_notification',
  'ssh_computer',
  'update_service',
  'event_logs',
  'wacom_bridge',
  'service_desk_custom_pin_code_widget',
  ...(featureControl.getToggle('PCP_1249__TeamSettings__SetUpNotificationEmails__95PercentComputerQuotaLimitAlert')
    ? ['computer_exceeds_percentage' as const]
    : []),
  ...(featureControl.getToggle('PCP_1336__Manage_Group_Associates') ? ['multiple_groups' as const] : []),
  ...(featureControl.getToggle('PCP_1380__TeamSettings_EndpointVersionControl')
    ? (['allow_src_version_control', 'allow_srs_version_control'] as const)
    : []),
  ...(featureControl.getToggle('PCP_1190__TeamSettings_PasswordRotation') ? (['password_rotation', 'password_rotation_ttl'] as const) : []),
] as const;
export const SettingKeyMap = z.enum(settingKeys).enum;

// Keys to update config to backend
export const updateKeys = [
  'file',
  'file_mode',
  'file_transfer',
  'file_transfer_mode',
  'off_session_file',
  'off_session_file_mode',
  'remote_print',
  'device_redirect',
  'device_redirect_detail',
  'remote_mic',
  'copy_paste',
  'copy_paste_mode',
  'keystroke',
  'wake_on_lan',
  'remote_reboot',
  'chat',
  'chat_transcript',
  'off_session_chat',
  'off_session_chat_transcript',
  'voice_call',
  'sos_voice_call',
  'session_recording',
  'sos_session_recording',
  'session_recording_detail',
  'sos_session_recording_detail',
  'share_screen',
  'sos_share_screen',
  'multi_session',
  'sos_multi_session',
  'watermark',
  'sos_watermark',
  'watermark_detail',
  'sos_watermark_detail',
  'command_prompt',
  'rdp_computer',
  'one_to_many',
  'one_to_many_mode',
  'create_group_admin',
  'os_logon_info',
  'vulnerability_score',
  'member_list',
  'sos_member_list',
  'display_group_to_member',
  'member_share_computer',
  'save_credential',
  'save_security_code',
  'client_stay_login',
  'client_idle_logout',
  'multiple_member_session',
  'member_concurrent_session',
  'member_disconnect_session',
  'member_reboot_computer',
  'member_reboot_computer_detail',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'session_notification',
  'file_session_notification',
  'cmpt_session_notification',
  'sos_session_notification',
  'sos_file_session_notification',
  'sos_cmpt_session_notification',
  'allow_close_attended_banner',
  'allow_close_attended_file_banner',
  'allow_close_attended_cmpt_banner',
  'allow_close_unattended_banner',
  'allow_close_unattended_file_banner',
  'allow_close_unattended_cmpt_banner',
  'mail_settings',
  'web_src',
  'force_member_2step_auth',
  'force_admin_2step_auth',
  'schedule_time_zone',
  'sta_management_tab',
  '2sv_trust',
  '2sv_trust_ttl',
  '2sv_replace_device_auth',
  'team_change_role_to_manager',
  'team_change_role_to_member',
  'sos_accept_team',
  'sos_decline_team',
  'sos_quit_team',
  'sos_seat_enabled',
  'sos_seat_disabled',
  'sos_user_removed',
  'sos_exceeding_max_user_license_limit',
  'stb_accept_team',
  'stb_decline_team',
  'stb_quit_team',
  'stb_seat_enabled',
  'stb_seat_disabled',
  'stb_user_removed',
  'stb_exceeding_max_user_license_limit',
  'stb_computer_added',
  'stb_computer_removed',
  'srs_to_online',
  'srs_to_offline',
  'srs_conn_notification',
  'srs_disconn_notification',
  'srs_conn_notify_all_pcs',
  'srs_disconn_notify_all_pcs',
  'srs_to_online_notify_all_pcs',
  'srs_to_offline_notify_all_pcs',
  'device_auth_notify',
  'device_auth_ttl',
  'device_auth_notify_browser',
  'device_auth_ttl_browser',
  'srs_download',
  'force_infra_gen',
  'extra_src_management',
  'blank_computer_list',
  'pcp_idle_timeout',
  'threats_notification',
  'vnc_computer',
  'computer_notes_permission',
  'sos_web_src',
  'premium_tool',
  'premium_tool_mode',
  'premium_session_notification',
  'allow_close_unattended_cmpt_banner',
  'macaddr_whitelist',
  'macaddr_whitelist_skip_already_authenticated_devices',
  'macaddr_whitelist_skip_mobile_devices',
  'macaddr_whitelist_skip_device_auth',
  'mail_settings',
  'sos_mail_settings',
  'allow_close_unattended_premium_banner',
  'multiple_concurrent_licenses',
  'service_desk_private_customer_survey',
  'service_desk_private_customer_survey_detail',
  'relay_recording',
  'sos_relay_recording',
  'relay_recording_access',
  'relay_recording_access_mode',
  'sos_relay_recording_access',
  'sos_relay_recording_access_mode',
  'sos_service_desk_chat_transcript_display',
  'service_desk_private_package_id',
  'background_action_session_notification',
  'allow_close_background_action_banner',
  'ssh_computer',
  'update_service',
  'event_logs',
  'event_logs_mode',
  'wacom_bridge',
  'service_desk_custom_pin_code_widget',
  ...(featureControl.getToggle('PCP_1249__TeamSettings__SetUpNotificationEmails__95PercentComputerQuotaLimitAlert')
    ? ['computer_exceeds_percentage' as const]
    : []),
  ...(featureControl.getToggle('PCP_1336__Manage_Group_Associates') ? ['multiple_groups' as const] : []),
  ...(featureControl.getToggle('PCP_1380__TeamSettings_EndpointVersionControl')
    ? ([
        'allow_src_version_control',
        'allow_src_version_control_detail',
        'allow_srs_version_control',
        'allow_srs_version_control_detail',
      ] as const)
    : []),
  ...(featureControl.getToggle('PCP_458__TeamSettings_EmailDeviceAuthentication_AllowSuperAdmin')
    ? (['device_auth_notify_detail', 'device_auth_notify_browser_detail'] as const)
    : []),
  ...(featureControl.getToggle('PCP_1190__TeamSettings_PasswordRotation') ? (['password_rotation', 'password_rotation_ttl'] as const) : []),
] as const;
export const UpdateKeyMap = z.enum(updateKeys).enum;

export const teamPermissionKeys = [
  'attended_access',
  'sos_ticket_session',
  'sso',
  'extra_src_management',
  'create_group_admin',
  'sos_sso',
  'access_schedule',
  'sos_service_desk',
] as const;

export const seatPermissionKeys = [
  'watermark',
  'sos_watermark',
  'premium_tool',
  'file_transfer',
  'off_session_file',
  'command_prompt',
  'macaddr_whitelist',
  'restrict_pcp_ip',
  'restrict_src_ip',
  'relay_recording',
  'sos_relay_recording',
  'session_recording',
  'sos_session_recording',
  'one_to_many',
] as const;

export type SettingKey = (typeof settingKeys)[number];

export type UpdateKey = (typeof updateKeys)[number];

type ReadonlySettingType = Extract<SettingKey, 'granular_control' | 'scim'>;
export type EditableSettingType = Exclude<SettingKey, ReadonlySettingType>;

export type TeamPermissionsKey = (typeof teamPermissionKeys)[number];
export type TeamPermissions = Partial<Record<TeamPermissionsKey, boolean>>;
export type SeatPermissionsKey = (typeof seatPermissionKeys)[number];
export type SeatPermissions = Partial<Record<SeatPermissionsKey, boolean>>;

export type GetTeamSettingResponse = Partial<
  Record<
    TeamKind,
    {
      team_id: number;
      team_settings: TeamSettings;
      team_permissions: TeamPermissions;
      seat_permissions: SeatPermissions;
    }
  >
>;

type SessionRecordingSetting = {
  settings: {
    windows?: {
      storage_path: string | null;
      size: number;
    };
    mac?: {
      storage_path: string | null;
      size: number;
    };
    android?: {
      storage_path: string | null;
      size: number;
    };
  };
  windows: boolean;
  mac: boolean;
  android: boolean;
};
type WatermarkSetting = {
  settings: {
    text: string | null;
    client_name: boolean;
    client_user: boolean;
    client_public_ip: boolean;
    server_name: boolean;
    server_public_ip: boolean;
    font_size?: 'small' | 'medium' | 'large';
    font_color?: string;
    font_border_color?: string;
    layout_density?: 'low' | 'medium' | 'high';
  };
};
type IdleLogout = {
  available_statuses: [string, string, string, string, string];
};
type SessionNotification = {
  allow_mode_0: boolean;
};
export type MACAddressWhitelistSettings = {
  skip_already_authenticated_devices: boolean;
  skip_mobile_devices: boolean;
  skip_device_auth: boolean;
};
export type TeamSettings = Partial<Record<SettingKey, SettingItem>> & {
  schedule_time_zone?: SettingItem & {
    editable: boolean;
  };
  device_redirect?: SettingItem & {
    device_redirect_mode: {
      hid: boolean;
      printer: boolean;
      card_reader: boolean;
      user_added?: boolean;
    };
  };

  // session recording
  session_recording?: SettingItem & SessionRecordingSetting;
  sos_session_recording?: SettingItem & SessionRecordingSetting;

  // watermark
  watermark?: SettingItem & WatermarkSetting;
  sos_watermark?: SettingItem & WatermarkSetting;

  // idle logout
  client_idle_logout?: SettingItem & IdleLogout;
  pcp_idle_timeout?: SettingItem & IdleLogout;

  // session notification
  session_notification?: SettingItem & SessionNotification;
  file_session_notification?: SettingItem & SessionNotification;
  cmpt_session_notification?: SettingItem & SessionNotification;
  premium_session_notification?: SettingItem & SessionNotification;
  background_action_session_notification?: SettingItem & SessionNotification;
  sos_session_notification?: SettingItem & SessionNotification;
  sos_file_session_notification?: SettingItem & SessionNotification;
  sos_cmpt_session_notification?: SettingItem & SessionNotification;

  granular_control?: SettingItem & { available_features?: Array<GranularControlKey> };

  create_group_admin?: SettingItem & { has_group_admin: boolean; has_group_admin_privilege: boolean };

  macaddr_whitelist?: SettingItem & { settings: MACAddressWhitelistSettings };

  // relay recording access
  relay_recording_access?: SettingItem & RelayRecording;
  sos_relay_recording_access?: SettingItem & RelayRecording;

  // service desk
  service_desk_private_customer_survey?: SettingItem & {
    settings?: {
      sample_rate: number;
      ss_kind: Array<Extract<SupportSessionKindInNumber, 1 | 3>>;
    };
  };

  member_reboot_computer?: SettingItem & {
    member_reboot_computer_mode: {
      restart_streamer: boolean;
      normal_reboot: boolean;
      safe_mode_reboot: boolean;
    };
  };

  allow_src_version_control?: SettingItem & VersionControlSetting;
  allow_srs_version_control?: SettingItem & VersionControlSetting;

  device_auth_notify?: SettingItem & { device_auth_notify_mode: DeviceAuthenticationRoleSetting };
  device_auth_notify_browser?: SettingItem & { device_auth_notify_browser_mode: DeviceAuthenticationRoleSetting };
};

export type SettingItem = {
  accessibility: 'read_only' | 'read_write' | 'hidden';
  value: string | boolean | number | null;
  mode?: string;
};

export type UpdateTeamNameResponse = {
  success: boolean;
};

export type UpdateSettingBody = {
  setting_type: UpdateKey;
  update_status?: any;
  additional_settings?: any;
};

export type UpdateSettingResponse = {
  setting_type: SettingKey;
  result: boolean;
};

export type VendorKey = 'service_now' | 'zendesk' | 'freshservice' | 'freshdesk' | 'jira' | 'salesforce' | 'ms_team';
export type VendorMetadata = {
  vendor_name: string;
  vendor_id: number;
  disabled_function: boolean;
  guide: string;
  vendor_human_name?: string;
  show_credential?: boolean;
};
export type VendorSetting = {
  vendor_id: number;
  team_id: number;
  credential: string;
  status: boolean;
  created_at: string;
  updated_at: string;
  sos_package_id: number;
};
export type VendorDataSet<Key extends string = VendorKey> = {
  vendor_settings: Record<Key, VendorMetadata>;
  team_vendor_settings: Array<VendorSetting>;
  sos_packages: Array<{ id: number; name: string }>;
};

export type ToggleVendorStatusResponse = {
  vendor_api_key: string;
  sos_package_id: number;
};

export type UpdateVendorApiKeyResponse = {
  vendor_api_key: string;
};

export type SetVendorSosPackageResponse = {
  sos_package_id: number;
};

export type SSOMethod = {
  id: number;
  name: string;
  status: number;
  protocol: number;
  idp_type: number;
  device_auth: boolean;
  default_sso: boolean;
  activated: boolean;
  note: string;
  jit: boolean;
  domain: Array<string> | null;
  settings: {
    client_id?: string;
    client_secret?: string;
    dynamic_signature_key_url?: string;
    idp_cert: string;
    idp_cert_fingerprint: string;
    idp_cert_fingerprint_algorithm: string;
    idp_entity_id: string;
    idp_sso_target_url: string;
    idp_token_url?: string;
    security: {
      digest_method: string;
      signature_method: string;
    };
  };
  login_url: string;
  // NOTE: There are other properties here but not in used.
};

export type SsoMethodList = Array<SSOMethod>;

export type CreateSsoMethodBody = {
  sso_name: string;
  sso_protocol: number;
  sso_idp_type: number;
  sso_domain_whitelist: string | null;
  sso_note: string | null;
  sso_idp_login_url: string;
  sso_jit: boolean | null;
  sso_idp_issuer: string | null;
  sso_certificate: string | null;
  client_id: string | null;
  client_secret: string | null;
  openid_token_url: string | null;
  dynamic_signature_key_url: string | null;
  signature_key: string | null;
};

export type CreateSsoMethodResponse = {
  xauth_method: {
    jit: boolean;
    login_url: string;
  };
};

export type SsoMethodData = {
  id: number;
  name: string;
  status: number;
  protocol: number;
  idp_type: number;
  device_auth: boolean;
  default_sso: boolean;
};

export type UpdateSsoMethodBody = {
  sso_name: string | null;
  sso_note: string | null;
  sso_certificate?: string | null;
  client_secret?: string | null;
};

export type UpdateSsoMethodResponse = {
  success: boolean;
  id: number;
  name: string;
};

export type DeleteSsoMethodResponse = {
  success: boolean;
};

export type UpdateSsoMethodStatusBody = {
  column: 'status' | 'device_auth';
  status: boolean;
};

export type UpdateSsoMethodStatusResponse = {
  success: boolean;
};

export type SetDefaultSsoMethodResponse = {
  success: boolean;
};

export type ResetDefaultSsoMethodResponse = {
  success: boolean;
};

export type ImportSsoMetadataResponse = {
  metadata: {
    idp_issuer: string;
    idp_login_url: string;
    certificate: string;
  };
};

export type ImportSsoMetadataUrl = {
  saml_url: string | null;
  openid_url: string | null;
};

export type ImportSsoMetadataUrlResponse = {
  metadata: {
    // SAML 2.0
    idp_issuer?: string;
    idp_login_url?: string;
    certificate?: string;

    // OpenID
    authorization_endpoint?: string;
    token_endpoint?: string;
    jwks_uri?: string;
  };
};

export type IPWhitelistKind = 'pcp' | 'src';

export type IpWhiteList = {
  max_num: number;
  lists: Array<string>;
};

export type GenerateSCIMTokenResponse = { token: string; token_hint: string };

export type GetTrustDeviceResponse = {
  trust_devices: Array<TrustedDevice> | null;
};

export type TrustedDevice = {
  id: number;
  dev_uuid: string;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
};

export type LimitedDevice = {
  id: number;
  name: string;
  version: string;
  email: string;
};

export type NotifyComputerKind =
  | 'srs_conn_notify_all_pcs'
  | 'srs_to_online_notify_all_pcs'
  | 'srs_to_offline_notify_all_pcs'
  | 'srs_disconn_notify_all_pcs';

export type GetNotifyComputerListResponse = {
  notify_all_computers_val: 'on' | 'off';
  /**
   * computer id
   */
  notify_computers: Array<number>;
  /**
   * group id
   */
  notify_tags: Array<number>;
};

export type UpdateNotifyComputerPayload = {
  notify_kind: NotifyComputerKind;
  notify_all_computers_val: boolean;
  computers: Array<number>;
  groups: Array<number>;
};

export type GetMACAddressListResponse = {
  max_num: number;
  macaddrs: Array<string>;
};

export type UpdateMACAddressListPayload = {
  /** 要新增的 mac addresses */
  add_macaddrs?: Array<string>;
  /** 要移除的 mac addresses */
  remove_macaddrs?: Array<string>;
  /** 此 team 所有的 mac addresses，若不在此陣列中的皆會移除（保留&新增) */
  replace_macaddrs?: Array<string>;
};

export type UpdateMACAddressListResponse = {
  /** 已存在重複的 mac addresses */
  duplicated_macaddrs: Array<string>;
};

export type RelayRecording = {
  /**
   * Unattended: '0', '1', '2'
   * Attended: '0', '1'
   */
  mode: string; // TODO: confirm property name
};

export const eventLogsModeEnumSchema = z.nativeEnum({
  adminsOwner: '1',
  adminsOwnerMembers: '2',
} as const);
export const eventLogsModeEnum = eventLogsModeEnumSchema.enum;
export type EventLogsMode = z.infer<typeof eventLogsModeEnumSchema>;

export type VersionControlPlatform = 'windows' | 'mac' | 'linux';
export type VersionControlPlatformSetting = {
  /**
   * If the value is `""`, it means selected the latest version.
   */
  version: string;
  status: 'pending' | 'active' | 'inactive' | null;
};
export type VersionControlSetting = {
  settings: Record<VersionControlPlatform, VersionControlPlatformSetting>;
};

export type DeviceAuthenticationRoleSetting = {
  device_user: boolean;
  owner: boolean;
  admins: boolean;
  super_admins: boolean;
};
