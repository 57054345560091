import { useTranslation } from 'react-i18next';

import { RippleMultipleSelect, RippleMultipleSelectOption, RippleMultipleSelectProps } from '@/design';
import { Weekday } from '@/utils/cron';

type WeekdayPickerProps = Omit<RippleMultipleSelectProps<Weekday>, 'children' | 'placeholder'>;

export const WeekdayPicker = ({ value, onChange, ...props }: WeekdayPickerProps) => {
  const { t } = useTranslation();

  return (
    <RippleMultipleSelect placeholder="" value={value} onChange={onChange} {...props}>
      <RippleMultipleSelectOption value="1">{t('common:date.monday')}</RippleMultipleSelectOption>
      <RippleMultipleSelectOption value="2">{t('common:date.tuesday')}</RippleMultipleSelectOption>
      <RippleMultipleSelectOption value="3">{t('common:date.wednesday')}</RippleMultipleSelectOption>
      <RippleMultipleSelectOption value="4">{t('common:date.thursday')}</RippleMultipleSelectOption>
      <RippleMultipleSelectOption value="5">{t('common:date.friday')}</RippleMultipleSelectOption>
      <RippleMultipleSelectOption value="6">{t('common:date.saturday')}</RippleMultipleSelectOption>
      <RippleMultipleSelectOption value="0">{t('common:date.sunday')}</RippleMultipleSelectOption>
    </RippleMultipleSelect>
  );
};
