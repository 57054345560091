import { featureControl } from '@/feature/toggle';
import { ATTENDED_TEAM_KIND, TeamInformation } from '@/models/TeamInformation';

const isGroupAdmin = (teamInformation: TeamInformation | null) => {
  return teamInformation?.role === 'manager' && teamInformation?.group_scope === 'part';
};

export function shouldShowServiceDeskChannelBySOS(sosTeamInformation: TeamInformation | null) {
  if (!sosTeamInformation) return false;
  if (sosTeamInformation.teamKind !== ATTENDED_TEAM_KIND) return false;
  if (isGroupAdmin(sosTeamInformation)) return false;

  return (
    ['enabled', 'disabled'].includes(sosTeamInformation.member_status) &&
    sosTeamInformation?.team_permissions.sos_service_desk &&
    sosTeamInformation?.team_member_permissions.attended_access &&
    sosTeamInformation?.team_member_permissions.technician_manager
  );
}

export function shouldShowServiceDeskChannelByEnterprise(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  if (
    !(
      teamInformation.teamKind === 'srs' &&
      [
        'enterprise',
        'ste_lite_sos_basic',
        'ste_lite_sos_plus',
        ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
          ? ['ste_lite_sos_unlimited']
          : []),
      ].includes(teamInformation.plan)
    )
  )
    return false;
  if (isGroupAdmin(teamInformation)) return false;

  return (
    ['enabled', 'disabled'].includes(teamInformation.member_status) &&
    teamInformation?.team_permissions.sos_service_desk &&
    teamInformation?.team_member_permissions.attended_access &&
    teamInformation?.team_member_permissions.technician_manager
  );
}

export function shouldShowServiceDeskSupportSessionBySOS(sosTeamInformation: TeamInformation | null) {
  if (!sosTeamInformation) return false;
  if (sosTeamInformation.teamKind !== ATTENDED_TEAM_KIND) return false;

  return (
    sosTeamInformation.member_status === 'enabled' &&
    sosTeamInformation?.team_permissions.sos_service_desk &&
    sosTeamInformation?.team_member_permissions.attended_access
  );
}

export function shouldShowServiceDeskSupportSessionByEnterprise(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  if (
    !(
      teamInformation.teamKind === 'srs' &&
      [
        'enterprise',
        'ste_lite_sos_basic',
        'ste_lite_sos_plus',
        ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
          ? ['ste_lite_sos_unlimited']
          : []),
      ].includes(teamInformation.plan)
    )
  )
    return false;

  return (
    teamInformation.member_status === 'enabled' &&
    teamInformation?.team_permissions.sos_service_desk &&
    teamInformation?.team_member_permissions.attended_access
  );
}
