import defaults from 'lodash/defaults';

import { shouldShowSOSCustomizationByEnterprise, shouldShowSOSCustomizationBySOS } from '@/components/Navigation/routeConfig/utils';
import { Teams } from '@/services/common/types';
import type { PackageDetailResponse, ThemeFileV2, ThemeFileV3 } from '@/services/customization/types';
import { TeamInformation } from '@/utils/useTeamInformation';

import { defaultThemeV2, defaultThemeV3 } from './constants';
import type { Configuration, ThemeV2, ThemeV3 } from './types';

export function parseTeamForPackage(
  teamSelected: string | Array<string> | undefined,
  teamInformation: TeamInformation | null,
  sosTeamInformation: TeamInformation | null,
) {
  const checkEnterprise = shouldShowSOSCustomizationByEnterprise(teamInformation);
  const checkSOS = shouldShowSOSCustomizationBySOS(sosTeamInformation);

  let teamId = 0;
  let teamType: keyof Teams | null = null;
  if (teamInformation && sosTeamInformation && checkEnterprise && checkSOS && teamInformation.isExpired !== sosTeamInformation.isExpired) {
    if (!teamInformation.isExpired) {
      teamId = teamInformation.teamId;
      teamType = teamInformation.currentTeam;
    } else {
      teamId = sosTeamInformation.teamId;
      teamType = sosTeamInformation.currentTeam;
    }
  } else if (teamInformation && teamSelected === 'enterprise' && checkEnterprise) {
    teamId = teamInformation.teamId;
    teamType = teamInformation.currentTeam;
  } else if (sosTeamInformation && checkSOS) {
    teamId = sosTeamInformation.teamId;
    teamType = sosTeamInformation.currentTeam;
    // eslint-disable-next-line sonarjs/no-duplicated-branches
  } else if (teamInformation && checkEnterprise) {
    teamId = teamInformation.teamId;
    teamType = teamInformation.currentTeam;
  }

  return {
    teamId,
    teamType,
  };
}

export function mapPackageTheme(themeFile: ThemeFileV2 | ThemeFileV3, version: number): ThemeV2 | ThemeV3 {
  switch (version) {
    case 3: {
      const mappedTheme: Omit<
        ThemeV3,
        | 'sessionCodeImage'
        | 'securityLockImage'
        | 'securityKeyImage'
        | 'statusConnectingImage'
        | 'statusConnectedImage'
        | 'warningCircleImage'
        | 'warningTriangleImage'
      > = {
        windowTitle: themeFile.WindowTitle,
        bannerImage: themeFile.BannerImage,
        backgroundColor: themeFile.BackgroundColor,
        instructionText: themeFile.InstructionText,
        instructionColor: themeFile.InstructionColor,
        sessionCodeColor: themeFile.SessionCodeColor,
        securityTextColor: themeFile.SecurityTextColor,
        securityAccentColor: themeFile.SecurityAccentColor,
        statusTextColor: 'StatusTextColor' in themeFile ? themeFile.StatusTextColor : undefined,
        warningTextColor: 'WarningTextColor' in themeFile ? themeFile.WarningTextColor : undefined,
      };
      return defaults(mappedTheme, defaultThemeV3);
    }
    case 2:
    default: {
      const mappedTheme: Omit<ThemeV2, 'sessionCodeImage' | 'securityLockImage' | 'securityKeyImage'> = {
        windowTitle: themeFile.WindowTitle,
        bannerImage: themeFile.BannerImage,
        backgroundColor: themeFile.BackgroundColor,
        instructionText: themeFile.InstructionText,
        instructionColor: themeFile.InstructionColor,
        sessionCodeColor: themeFile.SessionCodeColor,
        securityTextColor: themeFile.SecurityTextColor,
        securityAccentColor: themeFile.SecurityAccentColor,
      };
      return defaults(mappedTheme, defaultThemeV2);
    }
  }
}

export function transformPackageDetailToConfiguration(packageDetail: PackageDetailResponse): Configuration {
  return {
    name: packageDetail.name,
    connectionPermission: packageDetail.config.conn_perm,
    supportFIPS: packageDetail.config.fips,
    audioOutput: packageDetail.config.audio_output,
    proxySetting: packageDetail.config.proxy_setting,
    proxyAddress: packageDetail.config.proxy_addr,
    proxyPort: packageDetail.config.proxy_port,
    proxyAccount: packageDetail.config.proxy_user,
    proxyPassword: packageDetail.config.proxy_password,
    disclaimer: packageDetail.config.disclaimer,
    autoDelete: packageDetail.config.auto_delete ?? false,
    directConnection: packageDetail.config.direct_connection ?? false,
  };
}
