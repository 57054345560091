import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';

import { HStack, Stack } from '@chakra-ui/react';
import { useAtom, useSetAtom } from 'jotai';
import groupBy from 'lodash/groupBy';
import merge from 'lodash/merge';
import { useTranslation } from 'react-i18next';

import { POLICY_SUPPORT_ARTICLE } from '@/constants';
import {
  RippleArrowDown16,
  RippleArrowUp16,
  RippleBreadcrumb,
  RippleButton,
  RippleComputerSearchBar,
  RippleIconButton,
  RippleMenu,
  RippleMenuButton,
  RippleMenuItem,
  RippleMenuList,
  RippleOK,
  RippleRedirectIcon,
  RippleRefresh,
  RippleStrong,
  RippleTipsIcon,
  RippleTypography,
} from '@/design';
import { useTeamExpiration } from '@/models/TeamInformation';
import { categoryKeySchema } from '@/modules/PolicyCategories/hooks/usePolicyCategory';
import { PLATFORMS, Policy } from '@/services/teams/emm_policies';

import { ModalType, Modals, modalAtom, modalTypeEnum } from './Modals';
import { messageAtom, viewedMessagesAtom } from './atoms';
import { PolicyList } from './components';
import { MAX_POLICY_COUNT } from './constants';
import { useCreateDefaultPolicy, useLatestMessageQuery, usePolicyRelationModel, useTutorialMessageQuery } from './hooks';
import { PolicyWithInheritedDepth } from './types';
import { createDefaultPolicyMap, getPlatformString, isPreparedDefaultPolicy } from './utils';

type PolicyNode = PolicyWithInheritedDepth;

export const Policies = () => {
  const setMessage = useSetAtom(messageAtom);
  const { t } = useTranslation();
  const { isTeamExpired } = useTeamExpiration();
  const policyRelationModel = usePolicyRelationModel();
  const [viewedMessages, setViewedMessages] = useAtom(viewedMessagesAtom);
  const tutorialMessageQuery = useTutorialMessageQuery({ enabled: false });
  const latestMessageQuery = useLatestMessageQuery({ enabled: false, viewedMessages });

  const createSuperRootPolicy = useCreateDefaultPolicy();

  const [modal, setModal] = useAtom(modalAtom);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState<'' | Policy['platform']>('');
  const currentPolicyRef = useRef<PolicyNode | null>(null);
  const router = useRouter();

  const generalPolicies = useMemo(
    () =>
      policyRelationModel.policyAssignmentList.filter((policyAssignment) => policyAssignment.policyNode.policy.policy_kind === 'policy'),
    [policyRelationModel.policyAssignmentList],
  );

  const policiesByPlatformMap = useMemo(
    () =>
      merge(
        createDefaultPolicyMap(),
        groupBy(generalPolicies, (policy) => policy.policyNode.policy.platform),
      ),
    [generalPolicies],
  );

  const filteredByPlatform = useMemo(
    () =>
      Object.keys(PLATFORMS)
        .filter((platform) => selectedPlatform === '' || platform === selectedPlatform)
        .map((platform) => policiesByPlatformMap[platform])
        .flat(),
    [policiesByPlatformMap, selectedPlatform],
  );

  const openModal = (modal: ModalType, policyNode: PolicyNode | null) => {
    currentPolicyRef.current = policyNode;
    if (generalPolicies.length >= MAX_POLICY_COUNT && (modalTypeEnum.Enum.CREATE === modal || modalTypeEnum.enum.CLONE === modal)) {
      setModal('EXCEED_MAX');
      return;
    }
    setModal(modal);
  };

  const handleEdit = (node: PolicyNode) => {
    if (isPreparedDefaultPolicy(node.policy.id)) {
      createSuperRootPolicy(node.policy.platform).then(({ id: policyId }) => {
        router.push(`/w/policy/${policyId}/${categoryKeySchema.Enum.streamer_preference}`);
      });
      return;
    }
    router.push(`/w/policy/${node.policy.id}/${categoryKeySchema.Enum.streamer_preference}`);
  };

  const handleStatusChange = (policyNode: PolicyNode) => {
    openModal(policyNode.policy.active ? 'DISABLE' : 'ENABLE', policyNode);
  };

  const handleModalClose = () => {
    currentPolicyRef.current = null;
    setModal('');
  };

  const handleRewatch = () => {
    if (!tutorialMessageQuery.data) {
      tutorialMessageQuery.refetch();
      return;
    }
    setMessage(tutorialMessageQuery.data);
    setModal('TIPS');
  };

  useEffect(
    function getNews() {
      // Wait 1s for the page render and atom hydrated.
      // atom with local storage hytraion issue: https://github.com/pmndrs/jotai/discussions/1737
      const timer = setTimeout(() => {
        if (!latestMessageQuery.isFetched || !latestMessageQuery.data) {
          latestMessageQuery.refetch();
        }
      }, 1_000);
      return () => {
        clearTimeout(timer);
      };
    },
    [latestMessageQuery, setMessage, setModal, setViewedMessages],
  );

  useEffect(
    function showNews() {
      if (!latestMessageQuery.isFetched || !latestMessageQuery.data) {
        return;
      }
      if (latestMessageQuery.data.message.pages) {
        const { pages } = latestMessageQuery.data.message;
        if (pages.length > 0) {
          setMessage(latestMessageQuery.data.message);
          setModal('TIPS');
        }
      }
      if (latestMessageQuery.data.viewed_messages) {
        setViewedMessages(latestMessageQuery.data?.viewed_messages);
      }
    },
    [latestMessageQuery.data, latestMessageQuery.isFetched, setMessage, setModal, setViewedMessages],
  );

  useEffect(
    function showTutorialMessage() {
      if (!tutorialMessageQuery.isFetching && tutorialMessageQuery.data) {
        setMessage(tutorialMessageQuery.data);
        setModal('TIPS');
      }
    },
    [setMessage, setModal, tutorialMessageQuery.data, tutorialMessageQuery.isFetching],
  );

  return (
    <>
      <Stack py="24px" gap="16px">
        <HStack justifyContent="space-between">
          <RippleBreadcrumb items={[t('common:header.management'), t('emm-policy:endpoint_policies')]} />
          <HStack gap="10px">
            <RippleIconButton aria-label="tips" variant="tertiary" icon={<RippleTipsIcon color="blue.100" />} onClick={handleRewatch} />
            <RippleButton
              as={Link}
              rightIcon={<RippleRedirectIcon />}
              size="xs"
              variant="tertiary"
              href={POLICY_SUPPORT_ARTICLE}
              target="_blank"
            >
              {t('common:learnMore')}
            </RippleButton>
          </HStack>
        </HStack>
        <Stack gap="8px">
          <RippleTypography variant="heading04">{t('emm-policy:endpoint_policies')}</RippleTypography>
          <RippleTypography variant="body02">
            {t('emm-policy:count_policy', { count: policiesByPlatformMap.Windows.length + policiesByPlatformMap.macOS.length })}
          </RippleTypography>
        </Stack>
      </Stack>
      <Stack gap="8px" pb="32px">
        <HStack justifyContent="space-between">
          <HStack spacing="8px">
            <RippleButton variant="primary" size="xs" onClick={() => openModal('CREATE', null)} isDisabled={isTeamExpired}>
              {t('emm-policy:create_policy')}
            </RippleButton>
            <RippleIconButton
              isDisabled={isTeamExpired}
              icon={<RippleRefresh isLoading={policyRelationModel.isFetching} />}
              aria-label="refresh"
              onClick={() => {
                policyRelationModel.refetch();
              }}
            />
          </HStack>
          <HStack>
            <RippleMenu>
              {({ isOpen }) => (
                <>
                  <RippleMenuButton
                    as={RippleButton}
                    size="xs"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    variant="neutralGhost"
                    rightIcon={isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
                    isActive={isOpen}
                  >
                    {selectedPlatform || t('emm-policy:all_platforms')}
                  </RippleMenuButton>
                  <RippleMenuList zIndex="dropdown" w="208px">
                    <RippleMenuItem onClick={() => setSelectedPlatform('')}>
                      {selectedPlatform === '' ? (
                        <HStack justifyContent="space-between">
                          <RippleStrong variant="strong02">{t('emm-policy:all_platforms')}</RippleStrong>
                          <RippleOK color="blue.100" />
                        </HStack>
                      ) : (
                        <RippleTypography variant="body02">{t('emm-policy:all_platforms')}</RippleTypography>
                      )}
                    </RippleMenuItem>
                    {Object.keys(PLATFORMS).map((platform) => (
                      <RippleMenuItem key={platform} onClick={() => setSelectedPlatform(platform as keyof typeof PLATFORMS)}>
                        {platform === selectedPlatform ? (
                          <HStack justifyContent="space-between">
                            <RippleStrong variant="strong02">{getPlatformString(platform)}</RippleStrong>
                            <RippleOK color="blue.100" />
                          </HStack>
                        ) : (
                          <RippleTypography variant="body02">{getPlatformString(platform)}</RippleTypography>
                        )}
                      </RippleMenuItem>
                    ))}
                  </RippleMenuList>
                </>
              )}
            </RippleMenu>
            <RippleComputerSearchBar onSearch={setSearchKeyword} />
          </HStack>
        </HStack>
        <PolicyList
          data={filteredByPlatform}
          isLoading={policyRelationModel.isFetching}
          isTeamExpired={isTeamExpired}
          modal={modal}
          searchKeyword={searchKeyword}
          onEdit={handleEdit}
          onModalOpen={openModal}
          onStatusChange={handleStatusChange}
        />
      </Stack>
      <Modals
        policyCount={generalPolicies.length}
        policiesByPlatformMap={policiesByPlatformMap}
        policyRelationModel={policyRelationModel}
        currentPolicy={currentPolicyRef.current}
        onClose={handleModalClose}
      />
    </>
  );
};
