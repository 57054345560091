import { Fragment, useEffect, useRef } from 'react';

import { VStack } from '@chakra-ui/react';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ADD_FIELD_BUTTON_HEIGHT, AddFieldButton } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor';
import {
  ActionButtonsEditMode,
  BriefAnswerFieldEditMode,
  CheckboxFieldEditMode,
  CustomerIssueFieldEditMode,
  CustomerNameFieldEditMode,
  DetailedAnswerFieldEditMode,
  DragListWrapper,
  DragWrapper,
  RadioButtonFieldEditMode,
  SingleSelectFieldEditMode,
} from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode';
import {
  ActionButtons,
  BriefAnswerField,
  CheckboxField,
  CustomerIssueField,
  CustomerNameField,
  DetailedAnswerField,
  RadioButtonField,
  SingleSelectField,
} from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/ViewMode';
import { ADD_FIELD_MAP, MAX_QUESTIONNAIRE_COUNT } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import {
  CurrentOpenFieldAutoFocusType,
  useCurrentOpenField,
  useResetCurrentOpenField,
} from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { AddFieldKindValues, SupportFormScenarioMap, SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import { QuestionnaireFieldKind, QuestionnaireFieldOption } from '@/services/serviceDesk/supportForm/types';

const PADDING_TOP = 24;
const SCENARIO = SupportFormScenarioMap.pcp;

type ClickItemHandler = { fieldUuid: string; autoFocusType: CurrentOpenFieldAutoFocusType; questionIndex: number };

type FormEditorProps = {
  validateAndAutoFillEmptyField: () => void;
};

export const FormEditor = ({ validateAndAutoFillEmptyField }: FormEditorProps) => {
  const [currentOpenField, setCurrentOpenField] = useCurrentOpenField();

  const resetCurrentOpenField = useResetCurrentOpenField();

  const { setValue, watch, control, getValues, register } = useFormContext<SupportFormValue>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questionnaire',
  });

  const prevQuestionnaireLength = useRef<number>(fields.length);

  const questionnaire = watch('questionnaire');

  const maxCount = MAX_QUESTIONNAIRE_COUNT;
  const isDisabledAddFieldButton = questionnaire.length >= maxCount;

  const lastFieldUuid = fields[fields.length - 1].uuid;

  useEffect(() => {
    // To auto focus on last field added by user
    if (questionnaire.length > prevQuestionnaireLength.current) {
      setCurrentOpenField({ uuid: lastFieldUuid, autoFocusType: 'label', questionIndex: questionnaire.length - 1 });
    }

    prevQuestionnaireLength.current = questionnaire.length;
  }, [questionnaire.length, lastFieldUuid, setCurrentOpenField]);

  const addField = ({ kind }: { kind: AddFieldKindValues }) => {
    validateAndAutoFillEmptyField();
    const addedField = ADD_FIELD_MAP[kind];
    append({ ...addedField, order: fields.length, uuid: window.crypto.randomUUID() });
  };

  const makeClickFieldItemHandler =
    ({ fieldUuid, autoFocusType, questionIndex }: ClickItemHandler) =>
    (event: { preventDefault: () => void }) => {
      event.preventDefault();
      validateAndAutoFillEmptyField();
      setCurrentOpenField({ uuid: fieldUuid, autoFocusType, questionIndex });
    };

  const makeRemoveFieldHandler = (index: number) => () => {
    remove(index);
    resetCurrentOpenField();
  };

  const makeUpdateOptionsHandler = (index: number) => (options: Array<QuestionnaireFieldOption> | null) => {
    setValue(`questionnaire.${index}.options`, options);
  };

  const handleSave = () => {
    validateAndAutoFillEmptyField();
    resetCurrentOpenField();
  };

  // Drag & Drop Methods
  const handleDragEnd = (event: DragEndEvent) => {
    if (!(event.active && event.over)) return;

    const { active, over } = event;

    if (active.id !== over.id) {
      const originalIndex: number = active.data.current?.sortable.index;
      const droppedIndex: number = over.data.current?.sortable.index;

      setValue('questionnaire', arrayMove(questionnaire, originalIndex, droppedIndex));
    }
  };

  return (
    <>
      <AddFieldButton addField={addField} isDisabledAddFieldButton={isDisabledAddFieldButton} />
      <DragListWrapper items={fields} onDragEnd={handleDragEnd}>
        <VStack
          w="100%"
          p={`${PADDING_TOP}px 0 16px 0`}
          border="1px solid"
          borderColor="neutral.300"
          borderRadius="12px"
          gap="4px"
          mt={`-${PADDING_TOP + ADD_FIELD_BUTTON_HEIGHT}px`}
        >
          {fields.map((field, index) => {
            const labelValue = getValues(`questionnaire.${index}.question`);
            const placeholderValue = getValues(`questionnaire.${index}.placeholder`);
            const fieldIsRequired = getValues(`questionnaire.${index}.required`);
            const options = getValues(`questionnaire.${index}.options`);
            const defaultOption = getValues(`questionnaire.${index}.default_option`);

            switch (field.kind) {
              case QuestionnaireFieldKind.customerName:
                return (
                  <Fragment key={field.id}>
                    {currentOpenField?.uuid === field.uuid ? (
                      <CustomerNameFieldEditMode
                        labelProps={{ ...register(`questionnaire.${index}.question`) }}
                        placeholderProps={{ ...register(`questionnaire.${index}.placeholder`) }}
                        isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                        onSave={handleSave}
                      />
                    ) : (
                      <CustomerNameField
                        {...field}
                        scenario={SCENARIO}
                        labelValue={labelValue}
                        placeholder={placeholderValue}
                        isRequired={fieldIsRequired}
                        onClickLabel={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'label', questionIndex: index })}
                        onMouseDown={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'input', questionIndex: index })}
                      />
                    )}
                  </Fragment>
                );
              case QuestionnaireFieldKind.customerIssue:
                return (
                  <DragWrapper key={field.id} id={field.id}>
                    {currentOpenField?.uuid === field.uuid ? (
                      <CustomerIssueFieldEditMode
                        labelProps={{ ...register(`questionnaire.${index}.question`) }}
                        placeholderProps={{ ...register(`questionnaire.${index}.placeholder`) }}
                        isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                        onSave={handleSave}
                      />
                    ) : (
                      <CustomerIssueField
                        {...field}
                        scenario={SCENARIO}
                        labelValue={labelValue}
                        placeholder={placeholderValue}
                        isRequired={fieldIsRequired}
                        onClickLabel={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'label', questionIndex: index })}
                        onMouseDown={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'input', questionIndex: index })}
                      />
                    )}
                  </DragWrapper>
                );
              case QuestionnaireFieldKind.shortText:
                return (
                  <DragWrapper key={field.id} id={field.id}>
                    {currentOpenField?.uuid === field.uuid ? (
                      <BriefAnswerFieldEditMode
                        labelProps={{ ...register(`questionnaire.${index}.question`) }}
                        placeholderProps={{ ...register(`questionnaire.${index}.placeholder`) }}
                        isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                        onSave={handleSave}
                        onDelete={makeRemoveFieldHandler(index)}
                      />
                    ) : (
                      <BriefAnswerField
                        {...field}
                        scenario={SCENARIO}
                        labelValue={labelValue}
                        placeholder={placeholderValue}
                        isRequired={fieldIsRequired}
                        onClickLabel={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'label', questionIndex: index })}
                        onMouseDown={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'input', questionIndex: index })}
                      />
                    )}
                  </DragWrapper>
                );
              case QuestionnaireFieldKind.longText:
                return (
                  <DragWrapper key={field.id} id={field.id}>
                    {currentOpenField?.uuid === field.uuid ? (
                      <DetailedAnswerFieldEditMode
                        labelProps={{ ...register(`questionnaire.${index}.question`) }}
                        placeholderProps={{ ...register(`questionnaire.${index}.placeholder`) }}
                        isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                        onSave={handleSave}
                        onDelete={makeRemoveFieldHandler(index)}
                      />
                    ) : (
                      <DetailedAnswerField
                        {...field}
                        scenario={SCENARIO}
                        labelValue={labelValue}
                        placeholder={placeholderValue}
                        isRequired={fieldIsRequired}
                        onClickLabel={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'label', questionIndex: index })}
                        onMouseDown={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'input', questionIndex: index })}
                      />
                    )}
                  </DragWrapper>
                );
              case QuestionnaireFieldKind.comboBox:
                return (
                  <DragWrapper key={field.id} id={field.id}>
                    {currentOpenField?.uuid === field.uuid ? (
                      <SingleSelectFieldEditMode
                        fieldIndex={index}
                        labelProps={{ ...register(`questionnaire.${index}.question`) }}
                        isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                        options={options}
                        updateOptions={makeUpdateOptionsHandler(index)}
                        onSave={handleSave}
                        onDelete={makeRemoveFieldHandler(index)}
                      />
                    ) : (
                      <SingleSelectField
                        {...field}
                        registerFieldName=""
                        defaultValue={defaultOption}
                        options={options}
                        scenario={SCENARIO}
                        labelValue={labelValue}
                        isRequired={fieldIsRequired}
                        onClickLabel={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'label', questionIndex: index })}
                        onMouseDown={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'input', questionIndex: index })}
                      />
                    )}
                  </DragWrapper>
                );
              case QuestionnaireFieldKind.radioButton:
                return (
                  <DragWrapper key={field.id} id={field.id}>
                    {currentOpenField?.uuid === field.uuid ? (
                      <RadioButtonFieldEditMode
                        fieldIndex={index}
                        labelProps={{ ...register(`questionnaire.${index}.question`) }}
                        isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                        options={options}
                        updateOptions={makeUpdateOptionsHandler(index)}
                        onSave={handleSave}
                        onDelete={makeRemoveFieldHandler(index)}
                      />
                    ) : (
                      <RadioButtonField
                        {...field}
                        registerFieldName=""
                        defaultValue={defaultOption}
                        options={options}
                        scenario={SCENARIO}
                        labelValue={labelValue}
                        isRequired={fieldIsRequired}
                        onClickLabel={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'label', questionIndex: index })}
                        onClickRadioGroup={makeClickFieldItemHandler({
                          fieldUuid: field.uuid,
                          autoFocusType: 'input',
                          questionIndex: index,
                        })}
                      />
                    )}
                  </DragWrapper>
                );
              case QuestionnaireFieldKind.checkbox:
                if (!Array.isArray(defaultOption)) {
                  console.error('FormEditor: checkbox value is not an array');
                  return <></>;
                }
                return (
                  <DragWrapper key={field.id} id={field.id}>
                    {currentOpenField?.uuid === field.uuid ? (
                      <CheckboxFieldEditMode
                        fieldIndex={index}
                        labelProps={{ ...register(`questionnaire.${index}.question`) }}
                        isRequireProps={{ ...register(`questionnaire.${index}.required`) }}
                        options={options}
                        updateOptions={makeUpdateOptionsHandler(index)}
                        onSave={handleSave}
                        onDelete={makeRemoveFieldHandler(index)}
                      />
                    ) : (
                      <CheckboxField
                        {...field}
                        registerFieldName=""
                        defaultValue={defaultOption}
                        options={options}
                        scenario={SCENARIO}
                        labelValue={labelValue}
                        isRequired={fieldIsRequired}
                        onClickLabel={makeClickFieldItemHandler({ fieldUuid: field.uuid, autoFocusType: 'label', questionIndex: index })}
                        onMouseDown={makeClickFieldItemHandler({
                          fieldUuid: field.uuid,
                          autoFocusType: 'input',
                          questionIndex: index,
                        })}
                      />
                    )}
                  </DragWrapper>
                );
              default:
                return null;
            }
          })}
          {currentOpenField?.uuid === 'submit-button' ? (
            <ActionButtonsEditMode onSave={handleSave} />
          ) : (
            <ActionButtons validateAndAutoFillEmptyField={validateAndAutoFillEmptyField} />
          )}
        </VStack>
      </DragListWrapper>
    </>
  );
};
