import type { SessionCodeColors } from '../types';
import defaultBackground from './9-digit-bg_250x42px_default.png';
import greenBackground from './9-digit-bg_250x42px_green.png';
import greyBackground from './9-digit-bg_250x42px_grey.png';
import orangeBackground from './9-digit-bg_250x42px_orange.png';
import purpleBackground from './9-digit-bg_250x42px_purple.png';
import redBackground from './9-digit-bg_250x42px_red.png';
import yellowBackground from './9-digit-bg_250x42px_yellow.png';

const backgrounds: Record<SessionCodeColors, string> = {
  default: defaultBackground.src,
  green: greenBackground.src,
  red: redBackground.src,
  yellow: yellowBackground.src,
  purple: purpleBackground.src,
  orange: orangeBackground.src,
  grey: greyBackground.src,
};

export default backgrounds;
