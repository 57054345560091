import { Box, SimpleGrid } from '@chakra-ui/react';

import { RippleBodyText01, RippleButton } from '@/design';

export type SectionBaseProps = {
  instruction: string;
  buttonList: Array<{ label: string; onClick: () => void }>;
};

export const SectionBase = ({ instruction, buttonList }: SectionBaseProps) => {
  return (
    <Box w="88%" p="36px" mx="48px" my="20px" bg="blue.0" border="1px dashed" borderColor="blue.80" borderRadius="4px">
      <RippleBodyText01 w="100%" textAlign="center" mb="24px">
        {instruction}
      </RippleBodyText01>
      <SimpleGrid columns={2} spacing="12px">
        {buttonList.map(({ label, onClick }) => (
          <RippleButton key={label} variant="secondary" size="sm" onClick={onClick}>
            {label}
          </RippleButton>
        ))}
      </SimpleGrid>
    </Box>
  );
};
