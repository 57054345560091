import { useAttendedTeamInformation, useUnattendedTeamInformation } from '@/models/TeamInformation';
import { useUserProfile } from '@/models/UserProfile';
import { shouldShowServiceDeskSupportSessionByEnterprise, shouldShowServiceDeskSupportSessionBySOS } from '@/modules/ServiceDesk';
import { compareToCurrentDate } from '@/utils/useTeamInformation';

import type { TeamInfo } from './types';

export function useTeamInfoForSupportSession(): TeamInfo {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const profile = useUserProfile();

  if (attendedTeamInformation && shouldShowServiceDeskSupportSessionBySOS(attendedTeamInformation)) {
    const {
      team_id: teamId,
      member_id: memberId,
      expires_at,
      role,
      group_scope: groupScope,
      team_member_permissions: { technician_manager },
    } = attendedTeamInformation;
    return {
      teamType: 'sos',
      teamId,
      memberId,
      timeZoneMinutes: profile.time_zone_minutes,
      technicianManager: technician_manager,
      isExpired: compareToCurrentDate(expires_at),
      isGroupAdmin: role !== 'member' && groupScope === 'part',
      email: profile.email,
    };
  }

  if (unattendedTeamInformation && shouldShowServiceDeskSupportSessionByEnterprise(unattendedTeamInformation)) {
    const {
      team_id: teamId,
      member_id: memberId,
      expires_at,
      role,
      group_scope: groupScope,
      team_member_permissions: { technician_manager },
    } = unattendedTeamInformation;
    return {
      teamType: 'srs',
      teamId,
      memberId,
      timeZoneMinutes: profile.time_zone_minutes,
      technicianManager: technician_manager,
      isExpired: compareToCurrentDate(expires_at),
      isGroupAdmin: role !== 'member' && groupScope === 'part',
      email: profile.email,
    };
  }

  return {
    teamType: '',
    teamId: -1,
    memberId: -1,
    timeZoneMinutes: 0,
    technicianManager: false,
    isExpired: true,
    isGroupAdmin: false,
    email: '',
  };
}
