import { featureControl } from '@/feature/toggle';

import { ConfirmModal, ConfirmModal_deprecated } from './ConfirmModal';
import { SelectKindModal, useSelectKindModal } from './SelectKindModal';

const FT_NEW_WEB_API = featureControl.getToggle('BT_14454__ComputerList_UseNewVersionRebootComputerAPI');

export function useRebootComputer() {
  const renameComputerModal = useSelectKindModal();

  return {
    execute: renameComputerModal.open,
  };
}

export function RebootComputer(): React.JSX.Element {
  return (
    <>
      <SelectKindModal />
      {FT_NEW_WEB_API ? <ConfirmModal /> : <ConfirmModal_deprecated />}
    </>
  );
}
