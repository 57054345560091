import { useContext, useEffect } from 'react';

import { Flex } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import type { Group as RawGroup } from '@/services/group/types';
import { arrayToHashTable } from '@/utils/hashTable';

import { AddNotes } from './AddNotes';
import { AssignGroup } from './AssignGroup';
import { BackgroundActions } from './BackgroundActions';
import { ComputerShortcuts } from './ComputerShortcuts';
import { DeleteComputers } from './DeleteComputers';
import { DisconnectSession } from './DisconnectSession';
import { RebootComputer } from './RebootComputer';
import { RemoteCommand } from './RemoteCommand';
import { RenameComputer } from './RenameComputer';
import { WakeComputerModal } from './WakeComputer';
import { AccessPermission, RebootComputers, UpdateStreamers } from './actions';
import { additionGroupMapAtom, computerDataMapAtom, computerListAtom, computerScopeAtom, groupListAtom, isLoadingAtom } from './atoms';
import { StickyLeftWrapper } from './components';
import { ConnectToStreamer } from './components/ConnectToStreamer';
import { ALL_GROUP_ID, DEFAULT_GROUP_ID, FROM_OTHER_GROUP_ID } from './constants';
import { ColumnPermissionsContext } from './contexts';
import type { ComputerItemBase, ComputerScope } from './types';
import { sortGroup } from './utils';

type ComputerListProviderProps<T extends ComputerItemBase = ComputerItemBase> = {
  scope: ComputerScope;
  isLoading: boolean;
  data: Array<T>;
  groupList: Array<Pick<RawGroup, 'id' | 'name'>>;
  children: React.ReactNode;
};
export function ComputerListProvider<T extends ComputerItemBase = ComputerItemBase>({
  scope,
  isLoading: isLoadingFromProps,
  data: rawData,
  groupList,
  children,
}: ComputerListProviderProps<T>): React.JSX.Element {
  const { t } = useTranslation();

  const columnPermissions = useContext(ColumnPermissionsContext);

  const setComputerScope = useSetAtom(computerScopeAtom);
  const setComputerDataMap = useSetAtom(computerDataMapAtom);
  const setGroupList = useSetAtom(groupListAtom);
  const setAdditionGroupMap = useSetAtom(additionGroupMapAtom);

  const setIsLoading = useSetAtom(isLoadingAtom);

  const data = useAtomValue(computerListAtom) as Array<T>;

  useEffect(
    function initializeComputerScope() {
      setComputerScope(scope);
    },
    [scope, setComputerScope],
  );

  useEffect(
    function initializeAdditionGroupMap() {
      setAdditionGroupMap({
        [ALL_GROUP_ID]: t('computer:allGroups'),
        [DEFAULT_GROUP_ID]: t('computer:defaultGroup'),
        [FROM_OTHER_GROUP_ID]: t('computer:selector.fromOtherGroup'),
      });
    },
    [setAdditionGroupMap, t],
  );

  useEffect(
    function updateData() {
      setComputerDataMap(arrayToHashTable(rawData, { mainKey: 'id' }));
      setGroupList(groupList.map(({ id, name }) => ({ id: String(id), name })).sort(sortGroup));
      setIsLoading(isLoadingFromProps);
    },
    [groupList, isLoadingFromProps, rawData, setComputerDataMap, setGroupList, setIsLoading],
  );

  return (
    <>
      <Flex flex="1" w="100%" flexDirection="column">
        {children}

        {/* Sticky footer */}
        <StickyLeftWrapper position="sticky" bottom="0">
          <Flex w="100%" h="32px" bgColor="neutral.10" />
        </StickyLeftWrapper>
      </Flex>

      <ComputerShortcuts />

      {/* Modals */}
      <ConnectToStreamer />
      <DeleteComputers />
      <AssignGroup />
      <BackgroundActions />
      <RemoteCommand />
      <AddNotes />
      <RenameComputer />
      <RebootComputer />
      <DisconnectSession />
      <WakeComputerModal showGroupTable={columnPermissions.group} data={data} />
      <UpdateStreamers />
      <RebootComputers />
      <AccessPermission />
    </>
  );
}
