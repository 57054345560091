import { parseToRgb } from 'polished';

import { SOS_CUSTOMIZATION_THEME_VERSION } from '@/services/customization/constants';
import type { FullRGBColor } from '@/services/customization/types';

import type { SessionCodeProps } from './Editor/SessionCode';
import sessionCodeBackgrounds from './Editor/sessionCodeBackgrounds';
import type { SessionCodeColors } from './Editor/types';
import type { Configuration, ThemeV2, ThemeV3 } from './types';

type SessionColorOptionItem = {
  name: SessionCodeColors;
  value: FullRGBColor;
  colors: Pick<SessionCodeProps, 'borderColor' | 'backgroundColor' | 'textColor'>;
  url: string;
};

export const sessionColorOptionItemList: Array<SessionColorOptionItem> = [
  {
    name: 'default',
    value: parseToRgb('#054BBF'),
    colors: {
      borderColor: '#2E7DDA',
      backgroundColor: '#F8FAFF',
      textColor: '#054BBF',
    },
    url: sessionCodeBackgrounds.default,
  },
  {
    name: 'green',
    value: parseToRgb('#067314'),
    colors: {
      borderColor: '#34DF66',
      backgroundColor: '#F8FFF9',
      textColor: '#067314',
    },
    url: sessionCodeBackgrounds.green,
  },
  {
    name: 'red',
    value: parseToRgb('#B60022'),
    colors: {
      borderColor: '#B60022',
      backgroundColor: '#FFFBFB',
      textColor: '#B60022',
    },
    url: sessionCodeBackgrounds.red,
  },
  {
    name: 'yellow',
    value: parseToRgb('#986C2E'),
    colors: {
      borderColor: '#F8C81C',
      backgroundColor: '#FFFEF7',
      textColor: '#986C2E',
    },
    url: sessionCodeBackgrounds.yellow,
  },
  {
    name: 'purple',
    value: parseToRgb('#772DBC'),
    colors: {
      borderColor: '#9B51E0',
      backgroundColor: '#FEFAFF',
      textColor: '#772DBC',
    },
    url: sessionCodeBackgrounds.purple,
  },
  {
    name: 'orange',
    value: parseToRgb('#A5251F'),
    colors: {
      borderColor: '#E06018',
      backgroundColor: '#FFFAF6',
      textColor: '#A5251F',
    },
    url: sessionCodeBackgrounds.orange,
  },
  {
    name: 'grey',
    value: parseToRgb('#333333'),
    colors: {
      borderColor: '#333333',
      backgroundColor: '#F7F7F7',
      textColor: '#333333',
    },
    url: sessionCodeBackgrounds.grey,
  },
];

export const defaultConfig: Configuration = {
  name: '',
  connectionPermission: 'off',
  supportFIPS: false,
  audioOutput: 'local',
  proxySetting: 'none',
  proxyAddress: '',
  proxyPort: '',
  proxyAccount: '',
  proxyPassword: '',
  disclaimer: '',
  autoDelete: false,
  directConnection: false,
};

export const defaultThemeV2: Required<ThemeV2> = {
  windowTitle: 'Splashtop SOS',
  bannerImage: null,
  backgroundColor: {
    red: 255,
    green: 255,
    blue: 255,
  },
  instructionText: 'Share this code with your technician',
  instructionColor: {
    red: 88,
    green: 88,
    blue: 88,
  },
  sessionCodeColor: sessionColorOptionItemList[0].value,
  sessionCodeImage: null,
  securityTextColor: {
    red: 79,
    green: 79,
    blue: 79,
  },
  securityAccentColor: {
    red: 3,
    green: 109,
    blue: 234,
  },
  securityKeyImage: null,
  securityLockImage: null,
};

export const defaultThemeV3: Required<ThemeV3> = {
  windowTitle: 'Splashtop SOS',
  bannerImage: null,
  backgroundColor: {
    red: 255,
    green: 255,
    blue: 255,
  },
  instructionText: 'Share this code with your technician',
  instructionColor: {
    red: 88,
    green: 88,
    blue: 88,
  },
  sessionCodeColor: sessionColorOptionItemList[0].value,
  sessionCodeImage: null,
  securityTextColor: {
    red: 79,
    green: 79,
    blue: 79,
  },
  securityAccentColor: {
    red: 3,
    green: 109,
    blue: 234,
  },
  securityKeyImage: null,
  securityLockImage: null,
  statusTextColor: {
    red: 114,
    green: 172,
    blue: 95,
  },
  statusConnectingImage: null,
  statusConnectedImage: null,
  warningTextColor: {
    red: 198,
    green: 0,
    blue: 46,
  },
  warningCircleImage: null,
  warningTriangleImage: null,
};

// TODO: deprecated ?
export const defaultTheme = getDefaultTheme();
function getDefaultTheme(version = SOS_CUSTOMIZATION_THEME_VERSION): Required<ThemeV2> | Required<ThemeV3> {
  switch (version) {
    case 3: {
      return defaultThemeV3;
    }
    case 2:
    default: {
      return defaultThemeV2;
    }
  }
}
