import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { shouldShowPreferencePolicy } from '@/components/Navigation/routeConfig/utils';
import { featureControl } from '@/feature/toggle';
import { useEmmPolicyAccess } from '@/hooks';
import { getComputerListColumnPermissions } from '@/services/computers';
import type { ComputerListColumnPermissions } from '@/services/computers';
import useTeamInformation from '@/utils/useTeamInformation';
import type { TeamInformation } from '@/utils/useTeamInformation';

import type { ColumnPermissions } from '../ComputerList';
import { canSeeGroup, canSeeNotes } from '../ComputerList';

export function useColumnPermissions(): ColumnPermissions | null {
  const teamInformation = useTeamInformation();

  const permissionQuery = useQuery({
    queryKey: ['allComputerList', 'getColumnPermissions'],
    queryFn: () => getComputerListColumnPermissions(),
    staleTime: Infinity,
  });

  const permissionMap = permissionQuery.data;

  const hasSeeGroupPermission = canSeeGroup(teamInformation, permissionMap);
  const hasSeePreferencePolicyPermission = featureControl.getToggle('PCP_1134__Policy_management')
    ? shouldShowPreferencePolicy(teamInformation) && Boolean(permissionMap?.team_permissions.preference_policy)
    : shouldShowPreferencePolicy(teamInformation);
  const hasSeeAlertCountPermission = canShowAlertCount(teamInformation);
  const hasSeeWindowsUpdatesPermission = canShowWindowsUpdates(teamInformation);
  const hasSeeAntivirusStatusPermission = canShowAntivirusStatus(teamInformation, permissionMap);
  const hasSeeSecurityLayerPermission = canShowSecurityLayer(teamInformation, permissionMap);
  const hasSeeNotesPermission = canSeeNotes(teamInformation, permissionMap);
  const hasWebAppPermission = Boolean(permissionMap?.team_permissions.web_src);
  const hasRemoteRebootPermission = Boolean(permissionMap?.team_permissions.remote_reboot);
  const hasMemberRebootComputerPermission = Boolean(permissionMap?.team_permissions.member_reboot_computer);
  const hasSeeEmmPolicyPermission = useEmmPolicyAccess();

  const columnPermission = useMemo<ColumnPermissions>(
    () => ({
      alertCount: hasSeeAlertCountPermission,
      antivirusStatus: hasSeeAntivirusStatusPermission,
      emmPolicy: hasSeeEmmPolicyPermission,
      group: hasSeeGroupPermission,
      memberRebootComputer: hasMemberRebootComputerPermission,
      notes: hasSeeNotesPermission,
      preferencePolicy: hasSeePreferencePolicyPermission,
      remoteReboot: hasRemoteRebootPermission,
      securityLayer: hasSeeSecurityLayerPermission,
      webApp: hasWebAppPermission,
      windowsUpdates: hasSeeWindowsUpdatesPermission,
    }),
    [
      hasMemberRebootComputerPermission,
      hasRemoteRebootPermission,
      hasSeeAlertCountPermission,
      hasSeeAntivirusStatusPermission,
      hasSeeEmmPolicyPermission,
      hasSeeGroupPermission,
      hasSeeNotesPermission,
      hasSeePreferencePolicyPermission,
      hasSeeSecurityLayerPermission,
      hasSeeWindowsUpdatesPermission,
      hasWebAppPermission,
    ],
  );

  if (permissionQuery.isFetching || typeof permissionMap === 'undefined') return null;

  return columnPermission;
}

/**
 * Only owner and admin (NOT Group Admin) can see alert count
 */
function canShowAlertCount(teamInformation: TeamInformation | null): boolean {
  return Boolean(
    teamInformation?.teamPermissions.premierPack &&
      (teamInformation?.role === 'owner' || (teamInformation?.role === 'manager' && teamInformation.groupScope === 'all')),
  );
}

function canShowWindowsUpdates(teamInformation: TeamInformation | null): boolean {
  return Boolean(
    teamInformation?.teamPermissions.premierPack && (teamInformation?.role === 'owner' || teamInformation?.role === 'manager'),
  );
}

function canShowAntivirusStatus(
  teamInformation: TeamInformation | null,
  permissionMap: ComputerListColumnPermissions | undefined,
): boolean {
  return Boolean(permissionMap?.team_permissions.antivirus && (teamInformation?.role === 'owner' || teamInformation?.role === 'manager'));
}

function canShowSecurityLayer(teamInformation: TeamInformation | null, permissionMap: ComputerListColumnPermissions | undefined): boolean {
  return Boolean(
    permissionMap?.team_permissions.manage_infra_gen && (teamInformation?.role === 'owner' || teamInformation?.role === 'manager'),
  );
}
