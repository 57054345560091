import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useRippleFlashMessage } from '@/design';
import { useErrorModal } from '@/modules/ServiceDesk/SurveyForm/Editor';
import { useCustomImageFileValue, useUploadImageToS3 } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { useUpdateSearchParam } from '@/modules/ServiceDesk/SurveyForm/List/hooks';
import { modalType, searchParamKeys } from '@/modules/ServiceDesk/SurveyForm/constants';
import type { ErrorResponse } from '@/services/common/types';
import { createSurvey, createSurveyErrorSchema } from '@/services/serviceDesk/surveys';
import { SurveyFormAPIValue } from '@/services/serviceDesk/surveys/type';

const SURVEY_LIST_PATH = '/service_desk/surveys';

export const useCreateSurvey = ({ teamId }: { teamId: number }) => {
  const { t } = useTranslation();

  const { redirectWithSearchParam } = useUpdateSearchParam();
  const { flashMessage } = useRippleFlashMessage();
  const handleError = useErrorHandler();

  const customImageFile = useCustomImageFileValue();
  const customImageBase64 = customImageFile.url;
  const { uploadImageToS3, isIdle, isUploadingImageToS3, isUploadImageToS3Error } = useUploadImageToS3();

  const toAssignChannel = (surveyId: number) => () => {
    redirectWithSearchParam(SURVEY_LIST_PATH, {
      [searchParamKeys.SURVEY_ID]: surveyId.toString(),
      [searchParamKeys.MODAL]: modalType.ASSIGN_CHANNEL,
    });
  };

  const {
    mutateAsync: createSurveyForm,
    data: createSurveyResult,
    isLoading: isCreatingForm,
  } = useMutation(
    async (formValue: SurveyFormAPIValue) => {
      return await createSurvey(teamId, formValue);
    },
    { onError: handleError },
  );

  const imageS3Url = createSurveyResult?.brand_image_upload_url;
  const isSubmitting = isCreatingForm || isUploadingImageToS3;
  const onSubmitSuccess = createSurveyResult ? toAssignChannel(createSurveyResult.id) : undefined;

  const handleCreateSurvey = (formValue: SurveyFormAPIValue) => {
    createSurveyForm({ ...formValue });
  };

  useEffect(() => {
    if (imageS3Url && isIdle) {
      uploadImageToS3({ s3Url: imageS3Url, customImageBase64 });
    }
  }, [imageS3Url, uploadImageToS3, isIdle, customImageBase64]);

  useEffect(() => {
    if (isUploadImageToS3Error) {
      flashMessage({
        variant: 'error',
        title: t('survey:create_survey_but_image_upload_failed'),
      });
    }
  }, [flashMessage, isUploadImageToS3Error, t]);

  return { handleCreateSurvey, isSubmitting, onSubmitSuccess };
};

const useErrorHandler = () => {
  const { t } = useTranslation();
  const routers = useRouter();
  const { openErrorModal, closeErrorModal } = useErrorModal();
  const { flashMessage } = useRippleFlashMessage();

  return (createSurveyError: ErrorResponse) => {
    const errorValidation = createSurveyErrorSchema.safeParse(createSurveyError);

    if (!errorValidation.success) {
      console.error('Error status code is not valid', errorValidation.error);
    } else {
      const errorStatusCode = errorValidation.data.result;
      const message = errorValidation.data.data?.error;

      switch (errorStatusCode) {
        case 40422: // Wrong params
        case 40403: // The survey or channel does not exist
          openErrorModal({
            title: t('survey:failed_to_create_survey'),
            message: t('survey:please_contact_splashtop_support_team'),
            onClickPrimaryButton: () => {
              closeErrorModal();
              routers.push(SURVEY_LIST_PATH);
            },
          });
          break;
        case 41403:
          openErrorModal({
            title: t('survey:an_error_occurred'),
            message: t('survey:please_contact_your_it_admin'),
            onClickPrimaryButton: () => {
              closeErrorModal();
              routers.push(SURVEY_LIST_PATH);
            },
          });
          break;
        case 40409:
          if (message === 'duplicate_resource') {
            flashMessage({
              title: t('survey:you_cannot_create_this_survey_because_the_survey_name_is_duplicated'),
              variant: 'error',
            });
            break;
          }
          if (message === 'exceed_number_limit') {
            flashMessage({
              title: t('survey:you_cannot_create_this_survey_because_256_surveys_are_reached'),
              variant: 'error',
            });
            break;
          }
          console.error('Status code 40409 but has no specific error message');
          break;
        default:
          flashMessage({
            title: t('survey:failed_to_connect_to_splashtop_servers_please_check_your_network_connection'),
            variant: 'error',
          });
      }
    }
  };
};
