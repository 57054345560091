import { useAtom } from 'jotai';

import { RippleComputerSearchBar } from '@/design';
import { featureControl } from '@/feature/toggle';

import { searchKeywordAtom } from '../../atoms';

export function SearchBar(): React.JSX.Element {
  const [searchKeyword, setSearchKeyword] = useAtom(searchKeywordAtom);

  return (
    <RippleComputerSearchBar
      {...(featureControl.getToggle('PCP_1502__AllComputers__SearchFromQuery')
        ? {
            key: searchKeyword, // Force re-mount when searchKeyword changes
            initKeyword: searchKeyword,
          }
        : {})}
      onSearch={setSearchKeyword}
      showShortcut
    />
  );
}
