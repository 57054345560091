import { useRouter } from 'next/router';

import { HStack } from '@chakra-ui/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
} from '@/design';
import { featureControl } from '@/feature/toggle';

const FT_RENAME_TO_SYSTEM_TOOLS = featureControl.getToggle('rename_to_system_tools');

export const require2SVModalAtom = atomWithReset({ isOpen: false });

export function useRequire2SVModal() {
  const set = useSetAtom(require2SVModalAtom);

  return {
    open: () => {
      set({ isOpen: true });
    },
  };
}

export function Require2SVModal(): React.JSX.Element {
  const { t } = useTranslation();
  const route = useRouter();

  const { isOpen } = useAtomValue(require2SVModalAtom);
  const resetModal = useResetAtom(require2SVModalAtom);

  return (
    <RippleModal size="2xl" isOpen={isOpen} onClose={resetModal}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:backgroundAction.require2SVModal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>
          {FT_RENAME_TO_SYSTEM_TOOLS
            ? t('computer:backgroundAction.require2SVModal.content_rename')
            : t('computer:backgroundAction.require2SVModal.content')}
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={resetModal}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton
              data-testid="go-button"
              variant="primary"
              onClick={() => {
                route.push('/account_info?turnon2sv=1');
              }}
            >
              {t('computer:backgroundAction.require2SVModal.goToAccountSummary')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}
