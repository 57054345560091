import { useMemo, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Trans, useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleListItem,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleRadio,
  RippleTypography,
  RippleUnorderedList,
  useRippleFlashMessage,
} from '@/design';
import { featureControl } from '@/feature/toggle';
import { wakeComputerService, wakeComputer_deprecated } from '@/services/computers';
import { getOSType } from '@/utils/computers';

import { useWakeComputerModalActions, useWakeComputerModalData } from './WakeComputerModalAtom';
import { WakeComputer } from './WakeComputerModalColumns';
import { WakeComputerModalTable } from './WakeComputerModalTable';

const FT_NEW_WEB_API = featureControl.getToggle('BT_14455__ComputerList_RemoteWake_UseNewWebAPI');

type WakeComputerModalProps = {
  showGroupTable: boolean;
  data: Array<WakeComputer>;
};

export function WakeComputerModal({ showGroupTable, data }: WakeComputerModalProps) {
  const { state, wakeComputer } = useWakeComputerModalData();

  const enabledComputers = useMemo(
    () => data.filter(isComputerOnline).filter(filterComputerByOSType).filter(filterOutTargetComputer(wakeComputer)),
    [data, wakeComputer],
  );

  if (state === 'Selector') {
    return FT_NEW_WEB_API ? (
      <WakeComputerModalSelector showGroupTable={showGroupTable} data={enabledComputers} />
    ) : (
      <WakeComputerModalSelector_deprecated showGroupTable={showGroupTable} data={enabledComputers} />
    );
  } else if (state === 'RequestSent') {
    return <WakeComputerModalRequestSent />;
  } else if (FT_NEW_WEB_API && state === 'Unavailable') {
    return <WakeComputerModalUnavailable />;
  }

  return null;
}

function isComputerOnline(computer: WakeComputer): boolean {
  return Boolean(computer.online_status);
}

function filterComputerByOSType(computer: WakeComputer): boolean {
  const osType = getOSType(computer.version);

  const isMac = osType.type === 'mac';
  const isWindows = osType.type === 'win';
  const isNormalStreamer = !osType.isRDP && !osType.isVNC && !osType.isSSH && !osType.isRDS;

  return (isMac || isWindows) && isNormalStreamer;
}

function filterOutTargetComputer(targetComputer: { id: number } | undefined): (computer: WakeComputer) => boolean {
  return (computer) => computer.id !== targetComputer?.id;
}

function WakeComputerModalSelector({ showGroupTable, data }: WakeComputerModalProps) {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const { handleClose, handleChangeType, handleOpenRequestSent, handleOpenUnavailable } = useWakeComputerModalActions();
  const wakeData = useWakeComputerModalData();
  const { wakeType, canSubmit } = wakeData;

  const submitMutation = useMutation({
    mutationFn: async () => {
      const { state } = wakeData;

      if (state === 'Selector') {
        const { wakeComputer: computer, selectedComputerIds } = wakeData;

        return wakeComputerService.execute({
          computerId: computer.id,
          sendComputerIds: selectedComputerIds.map((id) => Number(id)),
        });
      }

      return null as never;
    },
    onSuccess() {
      handleOpenRequestSent();
    },
    onError(error) {
      if (wakeComputerService.errorHandlings.deviceNotFound(error)) {
        handleOpenUnavailable();
      } else {
        flashMessage({ variant: 'error', title: t('common:unexpectedError') });
      }
    },
  });

  return (
    <RippleModal isOpen onClose={handleClose} size="2xl" closeOnEsc={false} closeOnOverlayClick={false}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:remoteWake.selectModal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody display="flex" flexDirection="column">
          <Box pt="4px" onClick={() => handleChangeType('auto')}>
            <RippleRadio data-testid="auto" isChecked={wakeType === 'auto'}>
              <RippleTypography variant="body02" pb="6px">
                {t('computer:remoteWake.selectModal.option.auto.label')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.200">
                {t('computer:remoteWake.selectModal.option.auto.description')}
              </RippleTypography>
            </RippleRadio>
          </Box>
          <Box mt="8px" onClick={() => handleChangeType('manual')}>
            <RippleRadio data-testid="manual" isChecked={wakeType === 'manual'}>
              <RippleTypography variant="body02" pb="6px">
                {t('computer:remoteWake.selectModal.option.manual.label')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.200">
                {t('computer:remoteWake.selectModal.option.manual.description')}
              </RippleTypography>
            </RippleRadio>
          </Box>
          {wakeType === 'manual' && <WakeComputerModalTable showGroupTable={showGroupTable} data={data} />}
        </RippleModalBody>
        <RippleModalFooter>
          <RippleButton data-testid="cancel-button" variant="ghost" size="sm" onClick={handleClose}>
            {t('common:cancel')}
          </RippleButton>
          <RippleButton
            data-testid="ok-button"
            ml="12px"
            size="sm"
            isDisabled={submitMutation.isLoading || !canSubmit}
            onClick={() => submitMutation.mutate()}
          >
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

/**
 * @deprecated Remove it after the toggle `BT_14455__ComputerList_RemoteWake_UseNewWebAPI` is released
 */
function WakeComputerModalSelector_deprecated({ showGroupTable, data }: WakeComputerModalProps) {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleClose, handleChangeType, handleOpenRequestSent } = useWakeComputerModalActions();
  const wakeData = useWakeComputerModalData();
  const { wakeType, canSubmit } = wakeData;

  const handleSubmit = async () => {
    const { state } = wakeData;

    if (state === 'Selector') {
      setIsLoading(true);

      const { wakeComputer: computer, selectedComputerIds } = wakeData;

      const result = await wakeComputer_deprecated({
        computerId: computer.id,
        computerName: computer.name,
        sendComputerIds: selectedComputerIds,
      });

      if (result.success) {
        handleOpenRequestSent();
      } else {
        setIsLoading(false);
        flashMessage({ variant: 'error', title: t('common:unexpectedError') });
      }
    }
  };

  return (
    <RippleModal isOpen onClose={handleClose} size="2xl" closeOnEsc={false} closeOnOverlayClick={false}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:remoteWake.selectModal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody display="flex" flexDirection="column">
          <Box pt="4px" onClick={() => handleChangeType('auto')}>
            <RippleRadio isChecked={wakeType === 'auto'}>
              <RippleTypography variant="body02" pb="6px">
                {t('computer:remoteWake.selectModal.option.auto.label')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.200">
                {t('computer:remoteWake.selectModal.option.auto.description')}
              </RippleTypography>
            </RippleRadio>
          </Box>
          <Box mt="8px" onClick={() => handleChangeType('manual')}>
            <RippleRadio isChecked={wakeType === 'manual'}>
              <RippleTypography variant="body02" pb="6px">
                {t('computer:remoteWake.selectModal.option.manual.label')}
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.200">
                {t('computer:remoteWake.selectModal.option.manual.description')}
              </RippleTypography>
            </RippleRadio>
          </Box>
          {wakeType === 'manual' && <WakeComputerModalTable showGroupTable={showGroupTable} data={data} />}
        </RippleModalBody>
        <RippleModalFooter>
          <RippleButton variant="grayScaleGhost" size="sm" onClick={handleClose}>
            {t('common:cancel')}
          </RippleButton>
          <RippleButton ml="12px" size="sm" isDisabled={isLoading || !canSubmit} onClick={handleSubmit}>
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

function WakeComputerModalRequestSent() {
  const { t } = useTranslation();
  const wakeComputer = useWakeComputerModalData();
  const { handleClose } = useWakeComputerModalActions();

  const { state } = wakeComputer;
  if (state !== 'RequestSent') {
    return null;
  }

  const { sentComputers } = wakeComputer;

  return (
    <RippleModal isOpen onClose={handleClose} size="2xl" closeOnEsc={false} closeOnOverlayClick={false}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:remoteWake.resultModal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody display="flex" flexDirection="column">
          <RippleTypography variant="body02" color="dark.100">
            <Trans
              t={t}
              i18nKey="computer:remoteWake.resultModal.description1"
              components={{ Strong: <Box as="span" fontWeight={600} /> }}
              values={{ computerName: sentComputers.name }}
            />
          </RippleTypography>
          <RippleTypography variant="body02" color="dark.100" mt="24px">
            {t('computer:remoteWake.resultModal.description2')}
          </RippleTypography>
        </RippleModalBody>
        <RippleModalFooter>
          <RippleButton ml="12px" size="sm" onClick={handleClose}>
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

function WakeComputerModalUnavailable() {
  const { t } = useTranslation();

  const { handleClose } = useWakeComputerModalActions();

  return (
    <RippleModal size="xl" isOpen onClose={handleClose}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:failed_to_remote_wake')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>
          <RippleTypography variant="body02">{t('computer:unable_to_wake_up_this_target_computer_it_is_possible_that')}</RippleTypography>
          <RippleUnorderedList>
            <RippleListItem>
              <RippleTypography variant="body02">
                {t('computer:there_are_no_other_online_computers_on_the_same_network_or')}
              </RippleTypography>
            </RippleListItem>
            <RippleListItem>
              <RippleTypography variant="body02">
                {t('computer:these_computers_have_an_older_version_of_splashtop_streamer')}
              </RippleTypography>
            </RippleListItem>
          </RippleUnorderedList>
        </RippleModalBody>
        <RippleModalFooter>
          <RippleButton variant="primary" size="sm" onClick={handleClose}>
            {t('common:ok')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}
