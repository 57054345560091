import { useTranslation } from 'next-i18next';

import { singleSpace } from '@/constants';
import { RippleTypography } from '@/design';

type SelectionCountProps = {
  selectedCount: number;
};

const SelectionCount = ({ selectedCount }: SelectionCountProps) => {
  const { t } = useTranslation();
  return (
    <RippleTypography variant="body02">
      <span data-testid="selection-count">{selectedCount}</span>
      {singleSpace}
      {t('computer:selector.selected')}
    </RippleTypography>
  );
};

export default SelectionCount;
