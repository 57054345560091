import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, BoxProps, Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { Virtuoso } from 'react-virtuoso';

import AccordionButton from '@/components/ComputerList/AccordionButton';
import ComputerSelectorEmptyMessage from '@/components/ComputerList/ComputerSelectorEmptyMessage';
import ComputerSelectorFilterAlert from '@/components/ComputerList/ComputerSelectorFilterAlert';
import ComputerSelectorSortMenu from '@/components/ComputerList/ComputerSelectorSortMenu';
import DataRow from '@/components/ComputerList/DataRow';
import FilterActions from '@/components/ComputerList/FilterActions';
import SelectionCount from '@/components/ComputerList/SelectionCount';
import type { SelectorComputerData } from '@/components/ComputerList/types';
import {
  ALL_GROUP_ID,
  DEFAULT_GROUP_ID,
  FROM_OTHER_GROUP_ID,
  SelectorComputerGroup,
  computeAggregateKey,
  filterDefaultGroup,
  filterFromOtherGroup,
  formatComputer,
  formatGroup,
  getDefaultGroup,
  getFromOtherGroup,
  sortComputerGroup,
  sortComputerGroupWithSortBy,
} from '@/components/ComputerList/utils';
import Highlight from '@/components/Highlight';
import MultiLinesLoading from '@/components/MultiLinesLoading';
import useObservableAccordion from '@/components/ObservableTable/useObservableAccordion';
import useObservableFilter from '@/components/ObservableTable/useObservableFilter';
import useObservableGroupSort from '@/components/ObservableTable/useObservableGroupSort';
import useObservableSearch from '@/components/ObservableTable/useObservableSearch';
import useObservableSelection from '@/components/ObservableTable/useObservableSelection';
import useObservableSort from '@/components/ObservableTable/useObservableSort';
import useObservableTable, { OTableRowTypes, OTableViewStates } from '@/components/ObservableTable/useObservableTable';
import { OTableSelectStates } from '@/components/ObservableTable/utils/selection';
import { checkIsFilterMatched } from '@/components/ObservableTable/utils/table';
import type { OTableRowHashMap } from '@/components/ObservableTable/utils/table';
import {
  RippleAlertSpecificColor,
  RippleArrowDown,
  RippleArrowDown16,
  RippleArrowRight,
  RippleArrowUp16,
  RippleCheckbox,
  RippleComputerDeviceIcon,
  RippleComputerGroupDropdown,
  RippleComputerGroupDropdownData,
  RippleComputerSearchBar,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import type { FilterComputersOptions, GetComputerIdListData, GetComputerListData } from '@/services/computers';
import { getComputerGroupList } from '@/services/group';
import { concatCombineAndUniqueBy } from '@/utils/array';
import { getOSIconType } from '@/utils/computers';
import { arrayToHashTable } from '@/utils/hashTable';
import { useGroupAdmin } from '@/utils/useTeamInformation';

import ButtonBox from './ButtonBox';
import type { ComputerSelectorOnSelectChanged, FilterStates, RdsComputerHashTable, SelectedIds } from './types';
import { aggregateRdsComputerRow, computeRdsComputerHashTable, getAllComputerList, segregateSelectedRdsComputer } from './utils';

const topActionHeight = 45;
const tableHeadHeight = 47;
const bottomPadding = 20;

export type ComputerSelectorWithRDSProps = {
  teamId: number;
  maxHeight: number;
  canSaveGroup?: boolean;
  getSelectedComputerList?: (
    teamId: number,
    isSimpleMode?: boolean,
  ) => Promise<
    | {
        simpleMode: true;
        data: GetComputerIdListData;
      }
    | {
        simpleMode: false;
        data: GetComputerListData;
      }
  >;
  onSelectChanged: ComputerSelectorOnSelectChanged;
  onSubmitReady?: (isReady: boolean) => void;
  computerOptions?: FilterComputersOptions;
};

export const ComputerSelectorWithRDS = ({
  teamId,
  maxHeight,
  getSelectedComputerList,
  onSelectChanged,
  onSubmitReady,
  canSaveGroup = true,
  computerOptions,
}: ComputerSelectorWithRDSProps) => {
  const { t } = useTranslation();
  const { isGroupAdmin } = useGroupAdmin();

  const [forceUpdate, setForceUpdate] = useState<number>(0);
  const selectedAllKeys = useRef<SelectedIds>({
    computers: {},
    groups: {},
  });
  const [filterGroups, setFilterGroups] = useState<FilterStates>({});
  const [computerHashMap, setComputerHashMap] = useState<OTableRowHashMap<SelectorComputerData>>({});
  const [rdsComputerHashTable, setRdsComputerHashTable] = useState<RdsComputerHashTable>({});

  const { filterPlugin, onToggleOneFilter, onResetFilters } = useObservableFilter();
  const { searchPlugin, onSearchChange, getSearchKeyword, onClearSearch } = useObservableSearch({
    columns: [{ accessor: 'name' }],
    groupColumns: [{ accessor: 'name' }],
  });
  const { selectionPlugin, updateSelected, getSelectedIds, onToggleSelectedOnly, onCancelSelectedOnly, getIsSelectedOnly } =
    useObservableSelection();

  const { groupSortPlugin, onToggleGroupSort, getGroupSort } = useObservableGroupSort({
    initSortBy: [
      {
        accessor: 'name',
        desc: false,
      },
    ],
    sortFunc: sortComputerGroupWithSortBy,
  });
  const { sortPlugin, onToggleSort, getSort } = useObservableSort({
    initSortBy: [
      {
        accessor: 'name',
        desc: false,
      },
    ],
  });
  const { accordionPlugin, onToggleAccordion, getAccordionState } = useObservableAccordion();
  const { result, appendData, getAllRowIdsInGroup } = useObservableTable<SelectorComputerData, SelectorComputerGroup>({
    plugins: [groupSortPlugin, filterPlugin, searchPlugin, selectionPlugin, accordionPlugin, sortPlugin],
    initStates: {
      viewState: OTableViewStates.GROUP,
    },
    groupSort: sortComputerGroup,
  });

  const { rows, groups, selection, accordion, groupHashMap } = result;

  const updateShowFilterGroups = useCallback(
    (computers: Array<SelectorComputerData>) => {
      const nextFilterGroups: FilterStates = {};

      const hasDefaultGroupInComputers = computers.some((computer) => computer.gid === DEFAULT_GROUP_ID);
      if (hasDefaultGroupInComputers) {
        nextFilterGroups.defaultGroup = getDefaultGroup(t);
      }

      const hasFromOtherGroup = computers.some((computer) => computer.gid === FROM_OTHER_GROUP_ID);
      if (hasFromOtherGroup) {
        nextFilterGroups.fromOtherGroup = getFromOtherGroup(t);
      }

      setFilterGroups(nextFilterGroups);

      return nextFilterGroups;
    },
    [t, setFilterGroups],
  );

  const { isSuccess, isFetching } = useQuery(['ComputerSelector', teamId, getSelectedComputerList], async () => {
    const [allComputersRes, allGroupsRes, selectedDataRes] = await Promise.all([
      getAllComputerList(teamId, computerOptions),
      getComputerGroupList(teamId),
      getSelectedComputerList ? getSelectedComputerList(teamId, !isGroupAdmin) : undefined,
    ]);

    const isGroupAdminMode = isGroupAdmin && selectedDataRes && !selectedDataRes.simpleMode;
    let allGroups = allGroupsRes.map(formatGroup);
    if (isGroupAdminMode) {
      const selectedGroups = selectedDataRes.data.groups.map(formatGroup);
      allGroups = concatCombineAndUniqueBy(allGroups, selectedGroups, 'id');
    }
    const allComputers = allComputersRes.map(formatComputer);

    let additionalGroups: Array<SelectorComputerGroup> = [];
    let computerHashMap: Record<string, SelectorComputerData> = {};
    if (allComputers.length > 0) {
      computerHashMap = arrayToHashTable(allComputers, { mainKey: 'id' });
      setComputerHashMap(computerHashMap);
      const { defaultGroup, fromOtherGroup } = updateShowFilterGroups(allComputers);
      additionalGroups = [defaultGroup, fromOtherGroup].filter((group): group is SelectorComputerGroup => Boolean(group));
    }

    const nextRdsComputerHashTable = computeRdsComputerHashTable(allComputers);
    setRdsComputerHashTable(nextRdsComputerHashTable);

    const tableData = aggregateRdsComputerRow(allComputers);
    appendData(tableData, [...allGroups, ...additionalGroups]);

    let selectedComputerIds: Array<number> = [];
    if (isGroupAdminMode) {
      selectedComputerIds = selectedDataRes.data.computers.map((computer) => computer.id);
    } else if (selectedDataRes && selectedDataRes.simpleMode) {
      selectedComputerIds = selectedDataRes.data.computers;
    }
    const selectedComputerHashMap = selectedComputerIds.reduce(
      (acc, computerId) => {
        const computer = computerHashMap[computerId];
        if (computer?.rds_uuid === null || computer?.rds_uuid === undefined) {
          acc[computerId] = true;
        } else {
          acc[computeAggregateKey({ rds_uuid: computer?.rds_uuid ?? '', group_id: computer.group_id })] = true;
        }
        return acc;
      },
      {} as SelectedIds['computers'],
    );

    let selectedGroupIds: Array<number> = [];
    if (isGroupAdminMode) {
      selectedGroupIds = selectedDataRes.data.groups
        .filter((group) => group.selected)
        .map((group) => group.id)
        .filter((groupId): groupId is number => Boolean(groupId));
    } else if (selectedDataRes && selectedDataRes.simpleMode) {
      selectedGroupIds = selectedDataRes.data.groups;
    }
    const selectedGroupHashMap = (selectedGroupIds ?? []).reduce(
      (acc, groupId) => {
        if (groupId !== null) {
          acc[groupId] = true;
        }
        return acc;
      },
      {} as SelectedIds['groups'],
    );

    selectedAllKeys.current = {
      computers: selectedComputerHashMap,
      groups: selectedGroupHashMap,
    };

    updateSelected(selectedComputerHashMap, selectedGroupHashMap);
    return { allComputers, allGroups };
  });

  const { selectedRowIds, selectedGroupIds } = getSelectedIds();

  useEffect(() => {
    const selectedComputers = segregateSelectedRdsComputer(selectedAllKeys.current.computers, rdsComputerHashTable);
    const nextSelectedComputers = segregateSelectedRdsComputer(selectedRowIds, rdsComputerHashTable);

    onSelectChanged({
      computerHashMap,
      groupHashMap: groupHashMap ?? {},
      selectedComputers,
      selectedGroups: selectedAllKeys.current.groups,
      nextSelectedComputers,
      nextSelectedGroups: selectedGroupIds,
    });
  }, [onSelectChanged, selectedRowIds, selectedGroupIds, rdsComputerHashTable]); // eslint-disable-line

  useEffect(() => {
    if (onSubmitReady) {
      onSubmitReady(isSuccess);
    }
  }, [onSubmitReady, isSuccess]);

  const dataHeight = useMemo(() => {
    return maxHeight - topActionHeight - tableHeadHeight - bottomPadding;
  }, [maxHeight]);

  const headerNameAccessor = 'name';
  const selectedComputerCount = Object.keys(selectedRowIds)
    .filter((id) => selectedRowIds[id])
    // If it is not an RDS computer, add 1 more computer
    .reduce((acc, id) => acc + (rdsComputerHashTable[id]?.length ?? 1), 0);

  const isAllLoading = !isSuccess || isFetching;
  const formattedHeight = !isAllLoading && rows.length !== 0 ? dataHeight : 0;

  const isSelectedOnly = getIsSelectedOnly();
  const showHeadCheckBox = !isAllLoading;

  const groupSelectList =
    groups
      ?.filter((group) => filterDefaultGroup(group.id))
      ?.filter((group) => filterFromOtherGroup(group.id))
      ?.map((group) => ({
        id: group.id,
        name: group.name,
      })) ?? [];

  const handleFilterGroup = (selectedGroup: RippleComputerGroupDropdownData) => {
    if (selectedGroup.id === ALL_GROUP_ID) {
      onResetFilters();
    } else {
      onToggleOneFilter(OTableRowTypes.GROUP, 'id', selectedGroup.id);
    }
  };

  const haveFilterApplied = isSelectedOnly;
  const haveSearchApplied = Boolean(getSearchKeyword());
  const isEmpty = rows.length === 0;
  const isNotFound = isEmpty && (haveFilterApplied || haveSearchApplied);
  const isNoComputers = isEmpty && !haveFilterApplied && !haveSearchApplied;

  const handleShowAll = () => {
    onCancelSelectedOnly();
    onClearSearch();
    onResetFilters();
    setForceUpdate((key) => key + 1);
  };

  return (
    <div>
      <div>
        <ComputerSelectorFilterAlert haveFilterApplied={haveFilterApplied} isNotFound={isNotFound} onShowAll={handleShowAll} />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          zIndex={10}
          paddingBottom="12px"
          backgroundColor="white"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="neutral.60"
        >
          <Flex alignItems="center">{!isAllLoading && <SelectionCount selectedCount={selectedComputerCount} />}</Flex>
          <Flex alignItems="center">
            <ButtonBox>
              <RippleComputerGroupDropdown
                key={forceUpdate}
                showAllGroup
                showDefaultGroup={Boolean(filterGroups.defaultGroup)}
                showFromOtherGroup={Boolean(filterGroups.fromOtherGroup)}
                groups={groupSelectList}
                maxHeight={maxHeight}
                onSelect={handleFilterGroup}
              />
            </ButtonBox>
            <ButtonBox>
              <AccordionButton isEnabled={Boolean(accordion?.isAllRowsExpanded)} onClick={() => accordion?.onToggleAccordionAll()} />
            </ButtonBox>
            <ButtonBox>
              <FilterActions showSelectedOnly={isSelectedOnly} onToggleShowSelectedOnly={onToggleSelectedOnly} />
            </ButtonBox>
            <ButtonBox>
              <RippleComputerSearchBar key={forceUpdate} onSearch={onSearchChange} placeholder={t('common:table.search')} />
            </ButtonBox>
          </Flex>
        </Flex>
      </div>
      <ComputerSelectorEmptyMessage
        isLoading={isAllLoading}
        haveFilterApplied={haveFilterApplied}
        haveSearchApplied={haveSearchApplied}
        isEmpty={isEmpty}
        selectedComputerCount={selectedComputerCount}
        onShowAll={handleShowAll}
      />
      {(isAllLoading || (!isNotFound && !isNoComputers)) && (
        <Box role="table" backgroundColor="white">
          <Box role="rowgroup">
            <Box role="row" display="flex" alignItems="center" padding="12px 0" background="white">
              <Box role="columnheader" cursor="pointer" display="flex" alignItems="center">
                <Box width="36px" padding="0 3px">
                  {showHeadCheckBox && (
                    <RippleCheckbox
                      data-testid="selection-header"
                      isChecked={selection?.headFilteredSelectState === OTableSelectStates.ALL}
                      isIndeterminate={selection?.headFilteredSelectState === OTableSelectStates.SOME}
                      onChange={selection?.onToggleFilteredSelectedAll}
                    />
                  )}
                </Box>
              </Box>

              <Box role="columnheader" display="flex" alignItems="center">
                <Box position="relative" paddingLeft="50px">
                  <ComputerSelectorSortMenu
                    groupSortBy={getGroupSort(headerNameAccessor)}
                    onGroupSortByAsc={() => onToggleGroupSort(headerNameAccessor, { sortByDesc: false, mustSort: true })}
                    onGroupSortByDesc={() => onToggleGroupSort(headerNameAccessor, { sortByDesc: true, mustSort: true })}
                    computerSortBy={getSort(headerNameAccessor)}
                    onComputerSortByAsc={() => onToggleSort(headerNameAccessor, { sortByDesc: false, mustSort: true })}
                    onComputerSortByDesc={() => onToggleSort(headerNameAccessor, { sortByDesc: true, mustSort: true })}
                  >
                    {({ isOpen }) => {
                      return (
                        <>
                          <RippleTypography variant="heading09" color={isOpen ? 'blue.200' : 'neutral.300'}>
                            {t('common:name')}
                          </RippleTypography>
                          {isOpen ? <RippleArrowUp16 color="blue.200" /> : <RippleArrowDown16 />}
                        </>
                      );
                    }}
                  </ComputerSelectorSortMenu>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box role="rowgroup" overflowX="auto">
            <Virtuoso
              style={{ height: `${formattedHeight}px` }}
              totalCount={rows.length}
              itemContent={(index) => {
                const row = rows[index];

                const nextRow = rows[index + 1];
                const isNextGroupRow = nextRow?.type === OTableRowTypes.GROUP;
                const isLastRow = nextRow === undefined;
                const isLastRowInGroup = isNextGroupRow || isLastRow;
                const gid = row.type === OTableRowTypes.GROUP ? row.original.id : row.original.gid;

                if (row.type === OTableRowTypes.GROUP) {
                  const { name } = row.original;
                  const { selection: groupSelection } = row;

                  const rowIds = getAllRowIdsInGroup(gid);
                  const count = rowIds.reduce((acc, id) => acc + (rdsComputerHashTable[id]?.length ?? 1), 0);
                  const isExpanded = getAccordionState(gid);
                  const borderRadius = isExpanded ? 0 : 4;
                  const isGroupedSelected = groupSelection?.selectState === OTableSelectStates.ALL;
                  const shouldShowAlert = !canSaveGroup && isGroupedSelected;
                  const warningMsg = t('computer:selector.onlySaveComputers');

                  const handleClickExpand = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
                    event.preventDefault();
                    event.stopPropagation();

                    onToggleAccordion(gid);
                  };

                  const handleToggleGroupSelected = () => {
                    selection?.onToggleGroupSelected?.(gid);
                  };

                  return (
                    <DataRow rowId={gid} isLastRowInGroup={isLastRowInGroup}>
                      <Box width="36px" padding="0 3px">
                        <RippleCheckbox
                          data-testid={`selection-group-${gid ?? ''}`}
                          isChecked={isGroupedSelected}
                          isIndeterminate={groupSelection?.selectState === OTableSelectStates.SOME}
                          isDisabled={!checkIsFilterMatched(row)}
                          onChange={handleToggleGroupSelected}
                        />
                      </Box>
                      <Flex
                        onClick={handleToggleGroupSelected}
                        alignItems="center"
                        width="100%"
                        padding="12px 0"
                        cursor="pointer"
                        backgroundColor={isGroupedSelected ? 'green.20' : 'transparent'}
                        _hover={{
                          backgroundColor: isGroupedSelected ? 'green.40' : 'transparent',
                        }}
                        borderColor="neutral.60"
                        borderStyle="solid"
                        borderWidth="1px"
                        borderRadius={`4px 4px ${borderRadius}px ${borderRadius}px`}
                      >
                        <Flex alignItems="center" onClick={handleClickExpand}>
                          <Flex alignItems="center" justifyContent="center" width="50px" flexShrink={0}>
                            {isExpanded ? <RippleArrowDown color="neutral.300" /> : <RippleArrowRight />}
                          </Flex>
                          {shouldShowAlert && (
                            <RippleTooltip aria-label={warningMsg} label={warningMsg} placement="bottom">
                              <Flex alignItems="center" justifyContent="flex-start" width="25px" flexShrink={0} mr="4px">
                                <RippleAlertSpecificColor />
                              </Flex>
                            </RippleTooltip>
                          )}
                          <RippleTypography variant="heading07" data-testid={`group-${gid}`} wordBreak="break-all" paddingRight="8px">
                            <Highlight keyword={getSearchKeyword()}>{name}</Highlight>
                          </RippleTypography>
                        </Flex>
                        <Flex
                          alignItems="center"
                          backgroundColor="neutral.40"
                          color="dark.80"
                          margin="0 8px"
                          padding="0 6px"
                          minWidth="20px"
                          minHeight="20px"
                          flexShrink={0}
                        >
                          <RippleTypography variant="heading09" lineHeight="18px">
                            {count}
                          </RippleTypography>
                        </Flex>
                      </Flex>
                    </DataRow>
                  );
                }

                const { id, name, online_status } = row.original;
                const { selection: rowSelection } = row;
                const borderRadius = isLastRow ? 4 : 0;
                const deviceType = getOSIconType(row.original.version);

                const isSelected = Boolean(rowSelection?.isSelected);

                const groupData = groupHashMap?.[gid];
                const isDisabledSelect = groupData?.group?.in_charged === false;

                const handleToggleRow = () => {
                  selection?.onToggleRowSelected(id);
                };

                const rdsComputerCount = rdsComputerHashTable[id]?.length ?? 0;

                return (
                  <DataRow rowId={id} isLastRowInGroup={isLastRowInGroup}>
                    <Box width="36px" padding="0 3px">
                      <RippleCheckbox
                        data-testid={`selection-cell-${id}`}
                        isChecked={isSelected}
                        isDisabled={isDisabledSelect}
                        onChange={handleToggleRow}
                      />
                    </Box>

                    <Box
                      data-testid={`row-${gid ?? ''}-${id}`}
                      onClick={!isDisabledSelect ? handleToggleRow : undefined}
                      width="100%"
                      padding="12px 0"
                      cursor="pointer"
                      backgroundColor={isSelected ? 'green.10' : 'transparent'}
                      _hover={{
                        backgroundColor: isSelected ? 'green.40' : 'transparent',
                      }}
                      borderColor="neutral.60"
                      borderStyle="solid"
                      borderWidth="0 1px 1px 1px"
                      borderRadius={`0 0 ${borderRadius}px ${borderRadius}px`}
                    >
                      <Flex alignItems="center" ml="40px">
                        <Flex alignItems="center" mr="4px">
                          <RippleComputerDeviceIcon type={deviceType} state={online_status ? 'online' : 'offline'} />
                        </Flex>
                        <RippleTypography variant="body02" wordBreak="break-all" paddingRight="8px">
                          <Highlight keyword={getSearchKeyword()}>{name}</Highlight>
                        </RippleTypography>
                        {deviceType === 'rds' && (
                          <RippleTooltip
                            aria-label="rds-computer-total-count"
                            label={t('computer:rdsComputer.totalSize')}
                            placement="bottom"
                          >
                            <Box>
                              <RDSComputerCountBadge mr="8px" count={rdsComputerCount} />
                            </Box>
                          </RippleTooltip>
                        )}
                      </Flex>
                    </Box>
                  </DataRow>
                );
              }}
            />
            <MultiLinesLoading
              count={3}
              loading={isAllLoading}
              sx={{
                marginBottom: '8px',
              }}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

/**
 * Copy style from RippleBadge inline grey
 */
function RDSComputerCountBadge({ count = 0, ...otherProps }: BoxProps & { count: number }): React.JSX.Element {
  return (
    <Box border="1px solid" borderColor="#DDDDDD" borderRadius="12px" p="1px 8px" {...otherProps}>
      <RippleTypography variant="body03">{count}</RippleTypography>
    </Box>
  );
}
