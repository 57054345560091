import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useInitializeColumnVisibility } from '@/modules/Computer/ComputerList/components/TableSettingsDrawer/utils';
import { createPreparedDefaultPolicy, getPlatformByOs } from '@/modules/PolicyList/utils';
import { ComputerData } from '@/services/computers';
import { type OSType, OSTypeSchema } from '@/utils/computers';

import {
  ColumnPermissions,
  DEFAULT_GROUP_ID,
  filterGroupAtom,
  filterPlatformAtom,
  searchKeywordAtom,
  sortingStateAtom,
} from '../ComputerList';
import { FilterGroup as FilterGroupValue } from '../ComputerList';
import { columnIdMap } from './constants';
import { useGroupData } from './useGroupData';
import { usePolicyMapQuery } from './usePolicyMap';

export function useInitializeSortingState(columnPermissions: ColumnPermissions): void {
  const searchParams = useSearchParams();
  const setSortingState = useSetAtom(sortingStateAtom);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(
    function updateSortingStateFromUrlQuery() {
      if (!isInitialized) {
        const sortColumnId = searchParams.get('sort');
        const isSortDesc = searchParams.get('order') === 'desc';

        switch (sortColumnId) {
          case columnIdMap.alerts_count: {
            if (columnPermissions.alertCount) {
              setSortingState([{ id: columnIdMap.alerts_count, desc: isSortDesc }]);
            }
            break;
          }

          case columnIdMap.name: // TODO: implement for all columns?
          default:
        }

        setIsInitialized(true);
      }
    },
    [searchParams, columnPermissions, isInitialized, setSortingState],
  );
}

/**
 * Initializes the filtering state from the URL query.
 *
 * Currently only the group filter is supported.
 */
export function useInitializeFilterGroup(): void {
  const searchParams = useSearchParams();
  const setFilterGroup = useSetAtom(filterGroupAtom);

  const [isInitialized, setIsInitialized] = useState(false);

  const { groupListQuery, groupList } = useGroupData();
  const isFetchingGroupList = groupListQuery.isFetching;

  useEffect(
    function updateFilteringStateFromUrlQuery() {
      if (!isInitialized && !isFetchingGroupList) {
        const filterGroupId = searchParams.get('group');
        const isFilterGroupValid = groupList.some(({ id }) => String(id) === filterGroupId);

        if (filterGroupId && isFilterGroupValid) {
          setFilterGroup(filterGroupId as FilterGroupValue);
        }

        setIsInitialized(true);
      }
    },
    [searchParams, isInitialized, setFilterGroup, isFetchingGroupList, groupList],
  );
}

export function useInitializeSearch({ enable }: { enable: boolean }): void {
  const searchParams = useSearchParams();
  const searchKeyword = searchParams.get('search');

  const setSearchKeyword = useSetAtom(searchKeywordAtom);

  useEffect(
    function initializeSearch() {
      if (enable && searchKeyword) {
        setSearchKeyword(searchKeyword);
      }
    },
    [enable, searchKeyword, setSearchKeyword],
  );
}

/**
 * Expected query param: `?platform=mac`, `?platform=win,mac`
 */
export function useInitializeFilterPlatform({ enable }: { enable: boolean }): void {
  const searchParams = useSearchParams();
  const platformRawString = searchParams.get('platform');
  const filterValues = computerFilterValues(platformRawString);

  const setFilterPlatform = useSetAtom(filterPlatformAtom);

  useEffect(
    function initializeFilterPlatform() {
      if (enable && filterValues.length > 0) {
        setFilterPlatform(filterValues);
      }
    },
    [enable, filterValues, setFilterPlatform],
  );

  function computerFilterValues(searchParamValue: string | null): Array<OSType> {
    if (searchParamValue === null) return [];

    return searchParamValue.split(',').filter((value): value is OSType => {
      return OSTypeSchema.safeParse(value).success;
    });
  }
}

// Get the policy that should be applied to a computer
export const useGetPolicyName = () => {
  const policyMapQuery = usePolicyMapQuery();
  const { t } = useTranslation();
  return (computer: ComputerData): string | undefined => {
    if (!computer.support_emm_policy) {
      return '';
    }
    if (!policyMapQuery.isFetched) {
      return '';
    }
    const policy = policyMapQuery.serverPolicies?.[Number(computer.id)];
    const isCustomized = policy?.policy_kind === 'server';
    if (isCustomized) {
      if (policy.parent_id && policyMapQuery.policyMap?.[policy.parent_id]) {
        return policyMapQuery.policyMap[policy.parent_id]?.name;
      }

      Sentry.captureMessage(`Error: customized policy without parent, Policy ID: ${policy.id}`);
      return '';
    }

    const isFollowGroup = !policy;

    if (isFollowGroup) {
      const platform = getPlatformByOs(computer.version);
      if (!platform) {
        // Streamer is not supported to EMM policy
        return '';
      }

      const groupId = computer.group_id ?? DEFAULT_GROUP_ID;
      const groupPolicy = policyMapQuery.groupPolicies?.[groupId];
      if (!groupPolicy || !groupPolicy[platform]) {
        // If no policy for the group, the group is following the team default policy
        const teamDefaultPolicy = policyMapQuery.getTeamDefaultPolicy(platform) ?? createPreparedDefaultPolicy(platform);
        return teamDefaultPolicy.name;
      }

      return groupPolicy[platform]?.name;
    }

    return policy?.name ?? t('computer:follow_group');
  };
};

/**
 * Initializes the filtering state from the URL query.
 * If the target column is found in the URL query, the column visibility will be forced to show.
 *
 * Currently only the OS and OS build filter is supported.
 */
export function useInitializeFilterColumnState({
  enable,
  storedColumnVisibilityAtom,
}: {
  enable: boolean;
  storedColumnVisibilityAtom: ReturnType<typeof useInitializeColumnVisibility>;
}): void {
  const searchParams = useSearchParams();
  const setSearchKeyword = useSetAtom(searchKeywordAtom);
  const setColumnVisibility = useSetAtom(storedColumnVisibilityAtom);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(
    function updateFilteringStateFromUrlQuery() {
      if (enable && !isInitialized) {
        const filterOs = searchParams.get('filter_os');
        const filterOsBuild = searchParams.get('filter_os_build');

        if (filterOs) {
          setSearchKeyword(filterOs);
          setColumnVisibility((columnVisibility) => {
            return {
              ...columnVisibility,
              [columnIdMap.os]: true,
            };
          });
        } else if (filterOsBuild) {
          setSearchKeyword(filterOsBuild);
          setColumnVisibility((columnVisibility) => {
            return {
              ...columnVisibility,
              [columnIdMap.osBuild]: true,
            };
          });
        }

        setIsInitialized(true);
      }
    },
    [enable, searchParams, isInitialized, setSearchKeyword, setColumnVisibility],
  );
}
