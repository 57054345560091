import { useMemo } from 'react';

import keyBy from 'lodash/keyBy';
import { useTranslation } from 'react-i18next';

import { sortGroup } from '@/modules/Computer';
import { Group } from '@/services/group';
import { PLATFORMS } from '@/services/teams/emm_policies';
import { useTime } from '@/utils/formatDateTime';

import { createPolicyInheritanceTrees, createPreparedDefaultPolicy, getPreOrderDfs } from '../../utils';
import { useComputerListQuery } from '../useComputerListQuery';
import { useGroupListQuery } from '../useGroupListQuery';
import { useGroupPolicyListQuery } from '../useGroupPolicyListQuery';
import { usePoliciesQuery } from '../usePoliciesQuery';
import { useServerPolicyListQuery } from '../useServerPolicyListQuery';
import { PolicyRelationModel } from './types';
import { getGroupPolicyRelation, getPolicyAssignmentList, getServerPolicyRelation, getTeamPolicy } from './utils';

export * from './types';

/**
 * Constructure the relation model between policies, groups and servers
 */
export const usePolicyRelationModel = (): PolicyRelationModel => {
  const { i18n } = useTranslation();
  const { formatDateTime } = useTime(i18n.language);
  const groupsQuery = useGroupListQuery({
    select: (data) =>
      (
        [{ id: 0, name: 'Default Group' }, ...data.map((data) => ({ id: data.id, name: data.name }))] as Array<Pick<Group, 'id' | 'name'>>
      ).toSorted(sortGroup),
  });
  const serversQuery = useComputerListQuery({ select: (data) => data.filter((computer) => computer.support_emm_policy) });
  const policiesQuery = usePoliciesQuery({
    select: (data) => {
      const hasWinTeamDefault = data.some((policy) => policy.platform === PLATFORMS.Windows && policy.super_root);
      const hasMacTeamDefault = data.some((policy) => policy.platform === PLATFORMS.macOS && policy.super_root);
      return [
        ...(hasWinTeamDefault ? [] : [createPreparedDefaultPolicy(PLATFORMS.Windows)]),
        ...(hasMacTeamDefault ? [] : [createPreparedDefaultPolicy(PLATFORMS.macOS)]),
        ...data,
      ];
    },
  });
  const policyMapQuery = usePoliciesQuery({ select: (data) => keyBy(data, 'id') });
  const dfsPolicies = useMemo(() => getPreOrderDfs(createPolicyInheritanceTrees(policiesQuery.data)), [policiesQuery.data]);
  const groupPolicyMapQuery = useGroupPolicyListQuery({
    select: (data) =>
      data.reduce(
        (acc, { group_id, emm_policy_id }) => {
          const policyPlatform = policyMapQuery.data?.[emm_policy_id]?.platform;
          if (!policyPlatform) {
            return acc;
          }
          acc[group_id] = { ...acc[group_id], [policyPlatform]: emm_policy_id };
          return acc;
        },
        {} as Record<number, { [PLATFORMS.macOS]: number; [PLATFORMS.Windows]: number }>,
      ),
  });
  const serverPolicyMapQuery = useServerPolicyListQuery({ select: (data) => keyBy(data, 'server_id') });
  const teamPolicy = useMemo(() => getTeamPolicy(policiesQuery.data), [policiesQuery.data]);

  const groupPolicyRelation = useMemo(() => {
    if (groupsQuery.isFetched && groupPolicyMapQuery.isFetched && policyMapQuery.isFetched) {
      return getGroupPolicyRelation({
        teamPolicy,
        groups: groupsQuery.data,
        groupPolicyMap: groupPolicyMapQuery.data,
        policyMap: policyMapQuery.data,
      });
    }
  }, [
    groupPolicyMapQuery.data,
    groupPolicyMapQuery.isFetched,
    groupsQuery.data,
    groupsQuery.isFetched,
    policyMapQuery.data,
    policyMapQuery.isFetched,
    teamPolicy,
  ]);

  const serverPolicyRelation = useMemo(() => {
    if (groupPolicyRelation && serversQuery.isFetched && serverPolicyMapQuery.isFetched && policyMapQuery.isFetched) {
      return getServerPolicyRelation({
        teamPolicy,
        groupPolicyRelation,
        servers: serversQuery.data,
        serverPolicyMap: serverPolicyMapQuery.data,
        policyMap: policyMapQuery.data,
      });
    }
  }, [
    groupPolicyRelation,
    policyMapQuery.data,
    policyMapQuery.isFetched,
    serverPolicyMapQuery.data,
    serverPolicyMapQuery.isFetched,
    serversQuery.data,
    serversQuery.isFetched,
    teamPolicy,
  ]);

  const policyAssignmentList = useMemo(
    () =>
      getPolicyAssignmentList({
        groups: groupsQuery.data,
        servers: serversQuery.data,
        policies: dfsPolicies,
        groupPolicyRelation,
        serverPolicyRelation,
        teamPolicy,
        formatDateTime,
      }),
    [groupsQuery.data, serversQuery.data, dfsPolicies, groupPolicyRelation, serverPolicyRelation, teamPolicy, formatDateTime],
  );

  const isLoading =
    groupsQuery.isLoading ||
    serversQuery.isLoading ||
    policiesQuery.isLoading ||
    groupPolicyMapQuery.isLoading ||
    serverPolicyMapQuery.isLoading;

  const isFetching =
    groupsQuery.isFetching ||
    serversQuery.isFetching ||
    policiesQuery.isFetching ||
    groupPolicyMapQuery.isFetching ||
    serverPolicyMapQuery.isFetching;

  const refetch = () => {
    groupsQuery.refetch();
    serversQuery.refetch();
    policiesQuery.refetch();
    groupPolicyMapQuery.refetch();
    serverPolicyMapQuery.refetch();
  };

  return {
    refetch,
    isLoading,
    isFetching,
    teamPolicy,
    sortedGroupIds: groupsQuery.data?.map((group) => group.id) || [],
    groups: groupsQuery.data,
    servers: serversQuery.data,
    policies: policiesQuery.data,
    policyAssignmentList,
    groupPolicyRelation,
    serverPolicyRelation,
  };
};
