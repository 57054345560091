import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';

import { featureControl } from '@/feature/toggle';
import { type ColumnPermissions, checkPermission, useRegisterComputerDataFields } from '@/modules/Computer/ComputerList';
import { computeShowOOBE } from '@/modules/Computer/utils';
import {
  type ComputerListCustomizedField,
  getUserComputerIdList,
  getUserComputerListByIds,
  getUserComputerListByToken,
  getUserComputerTokenList,
} from '@/services/computers';
import { CHUNK_SIZE } from '@/utils/constants';

import { useColumnPermissions } from '../useColumnPermissions';
import { useGroupConfig } from '../useGroupConfig';
import { computerIdListPlaceholder, computerListPlaceholder, tokenListPlaceholder } from './constants';

const FT_TOKEN_METHOD = featureControl.getToggle('PCP_740__ComputerList__SRS_TokenMethodForFetchingData');

const useComputerSRSQueries = FT_TOKEN_METHOD ? useComputerSRSQueries_tokenList : useComputerSRSQueries_idList;
export function useComputerDataSRS() {
  const columnPermissions = useColumnPermissions();

  const { computerIdListQuery, computerListQuery } = useComputerSRSQueries({ columnPermissions });

  const computerListData = computerListQuery.data ?? computerListPlaceholder;

  const showOOBE = computeShowOOBE(computerIdListQuery);
  const isFetching = computerIdListQuery.isFetching || computerListQuery.isFetching;

  function refresh() {
    computerIdListQuery.remove();
    computerIdListQuery.refetch().then(() => {
      computerListQuery.remove();
      computerListQuery.refetch();
    });
  }

  return { showOOBE, isFetching, computerListData, refresh };
}

export function useComputerIdListSRSQuery() {
  return useQuery({
    queryKey: ['myComputers', 'getComputerIdList'],
    queryFn: () => getUserComputerIdList(),
    staleTime: Infinity,
  });
}

function useComputerTokenListQuery() {
  return useQuery({
    queryKey: ['myComputers', 'getComputerTokenList'],
    queryFn: () => getUserComputerTokenList(),
    staleTime: Infinity,
  });
}

export function useComputerListSRSQuery({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const computerIdListQuery = useComputerIdListSRSQuery();
  const computerIdList = computerIdListQuery.data ?? computerIdListPlaceholder;

  const fields = useSRSFields(columnPermissions);

  return useQuery({
    queryKey: ['myComputer', 'computerList', computerIdList],
    queryFn: async () => {
      if (computerIdList.length === 0) return [];

      const idListChunks = chunk(computerIdList, CHUNK_SIZE);
      const requestList = idListChunks.map((idList) => {
        return getUserComputerListByIds({ idList, fields });
      });

      const result = await Promise.all(requestList);

      return flatten(result);
    },
    staleTime: Infinity,
    enabled: !computerIdListQuery.isFetching && columnPermissions !== null,
  });
}

function useComputerListSRSTokenQuery({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  const computerTokenListQuery = useComputerTokenListQuery();
  const tokenList = computerTokenListQuery.data ?? tokenListPlaceholder;

  const fields = useSRSFields(columnPermissions);

  return useQuery({
    queryKey: ['myComputer', 'computerList', tokenList],
    queryFn: async () => {
      if (tokenList.length === 0) return [];

      const requestList = tokenList.map((token) => {
        return getUserComputerListByToken({ token, fields });
      });

      const result = await Promise.all(requestList);

      return flatten(result);
    },
    staleTime: Infinity,
    enabled: !computerTokenListQuery.isFetching && columnPermissions !== null,
  });
}

function useComputerSRSQueries_idList({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  return {
    computerIdListQuery: useComputerIdListSRSQuery(),
    computerListQuery: useComputerListSRSQuery({ columnPermissions }),
  };
}

function useComputerSRSQueries_tokenList({ columnPermissions }: { columnPermissions: ColumnPermissions | null }) {
  return {
    computerIdListQuery: useComputerTokenListQuery(),
    computerListQuery: useComputerListSRSTokenQuery({ columnPermissions }),
  };
}

function useSRSFields(columnPermissions: ColumnPermissions | null): Array<ComputerListCustomizedField> {
  const { shouldGetGroupName } = useGroupConfig();

  const fields = useMemo(() => getSRSFields({ columnPermissions, shouldGetGroupName }), [columnPermissions, shouldGetGroupName]);

  useRegisterComputerDataFields(fields);

  return fields;
}

function getSRSFields({
  columnPermissions,
  shouldGetGroupName,
}: {
  columnPermissions: ColumnPermissions | null;
  shouldGetGroupName: boolean;
}): Array<ComputerListCustomizedField> {
  return [
    'version',
    'host_name',
    'group_id',
    ...checkPermission(shouldGetGroupName, 'group_name' as const),
    'pubip',
    'local_ip',
    ...checkPermission(columnPermissions?.notes, 'note' as const),
    'online_status',
    'is_device_owner',
    'connected',
  ];
}
