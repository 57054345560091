import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';

import { RippleInput, RippleTextarea } from '@/design';
import colors from '@/design/theme/colors';
import { LABEL_MAX_LENGTH, PLACEHOLDER_MAX_LENGTH } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { useCurrentOpenFieldValue, useScrollToLastField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';

import { ActionBar, FieldTitle, Highlighter } from '.';

type LongTextFieldEditModeProps = {
  isRequireProps: UseFormRegisterReturn;
  questionProps: UseFormRegisterReturn;
  onDelete: () => void;
  onSave: () => void;
  answerPlaceholderProps: UseFormRegisterReturn;
};

export function LongTextFieldEditMode({
  isRequireProps,
  questionProps,
  onDelete,
  onSave,
  answerPlaceholderProps,
}: LongTextFieldEditModeProps) {
  const { t } = useTranslation();
  const { labelRef } = useScrollToLastField();
  const currentOpenField = useCurrentOpenFieldValue();

  return (
    <Box w="100%" p="0px 48px 8px" position="relative">
      <Highlighter />
      <FieldTitle title={t('survey:long_text_field')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-detailed-answer"
        aria-describedby="edit-detailed-answer"
        maxLength={LABEL_MAX_LENGTH}
        variant="flushed"
        sx={{
          caretColor: colors.blue[100],
        }}
        fontSize="16px"
        size="sm"
        type="text"
        placeholder={t('survey:add_name_for_this_field')}
        m="4px 0 12px"
        {...questionProps}
        ref={(e) => {
          questionProps.ref(e);
          labelRef.current = e;
        }}
      />
      <RippleTextarea
        autoFocus={currentOpenField?.autoFocusType === 'input'}
        id="edit-textarea-placeholder"
        aria-describedby="edit-textarea-placeholder"
        maxLength={PLACEHOLDER_MAX_LENGTH}
        height="120px"
        resize="none"
        color="dark.40"
        border="1px solid"
        borderColor="neutral.300"
        background="white"
        sx={{
          caretColor: colors.blue[100],
        }}
        _placeholder={{
          color: 'dark.40',
          fontStyle: 'italic',
        }}
        _hover={{
          background: 'blue.0',
          borderColor: 'neutral.300',
        }}
        _focus={{
          background: 'white',
          borderColor: 'blue.100',
        }}
        _focusVisible={{
          border: '1px solid',
          borderColor: 'blue.100',
          boxShadow: 'none',
        }}
        placeholder={t('survey:add_placeholder_text')}
        {...answerPlaceholderProps}
      />
      <ActionBar isShowRequired={false} switchProps={isRequireProps} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
}
