import { Center, Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import { RippleButton, RippleSheetModal } from '@/design';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

import { ThankYouMessage } from './FormEditor/ViewMode';

type PreviewThankYouMessageModalProps = {
  onClose: () => void;
};

export const PreviewThankYouMessageModal = ({ onClose }: PreviewThankYouMessageModalProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<SurveyFormValue>();
  const thankYouMessage = getValues('thank_you_message');

  return (
    <RippleSheetModal
      title={t('common:preview')}
      isOpen
      onClose={onClose}
      footer={
        <Stack alignItems="flex-end">
          <RippleButton variant="secondary" size="sm" onClick={onClose}>
            {t('common:close')}
          </RippleButton>
        </Stack>
      }
    >
      <Center>
        <ThankYouMessage thankYouMessage={thankYouMessage} />
      </Center>
    </RippleSheetModal>
  );
};
