import { z } from 'zod';

import { granularControlKeys, granularControlSettingKeys } from './constants';

export type TeamDetailResponse = {
  /** example: "2022-12-07T03:28:21Z" */
  created_at: string;
  multiple_magnification: number;
  seats_users_count: number;
  occupied_seat_count: number;
  team_members_count: number;
  on_shelf: boolean;
  can_change_plan: boolean;
  can_trial: boolean;
  seat_is_recurring: boolean;
  seat_auto_renew: boolean;
  /** 能否購買 Product */
  can_buy_product: boolean;
  /** 購買連結 (僅限 owner 可見) */
  buy_url: string;
  /** 試用申請連結 (僅限 owner & 仍可申請試用時顯示) */
  trial_url: string;
  /** 升級方案連結 (僅限 owner 可見) */
  upgrade_url: string | null;
  team_member_permissions: {
    sso: boolean;
    create_group_admin: boolean;
  };
  team_permissions: {
    sso: boolean;
  };
  product_options: {
    can_buy_pcs: boolean;
  };
  team_settings: {
    schedule_time_zone_minutes: number;
    schedule_time_zone: string | null;
  };
  team_code: string;
  /**
   * technicians 數量 (僅限 owner, super_admin 可見 & rs-enterprise 限定)
   */
  technicians: number;
  /**
   * 使用者數量 (僅限 owner, super_admin 可見 & rs-enterprise 限定)
   */
  end_users: number;
  /**
   * 已部署的電腦數量 (僅限 owner, super_admin 可見)
   */
  used_computers: number;
  /**
   * 可部署的電腦數量 (僅限 owner, super_admin 可見)
   *
   * NOTE: SBA legacy (Unlimited) 為例外，接收到的是 null，表示沒有限制電腦數量
   */
  total_computers: number | null;
  /**
   * 此 team 是否有買電腦
   */
  with_computer_license: boolean;
  /**
   * New SOS team 的 technicians 數量
   */
  seats_count?: number;
};

export type TeamMemberListRequestMode = 'simple' | 'group' | 'customize' | 'default';
export type TeamMemberListOptions = {
  mode?: TeamMemberListRequestMode;
  status?: Array<TeamMemberStatus>;
  /**
   * group_admin 必須是有啟動 create_group_admin 的 team 才能呼叫，否則會回傳 40403 錯誤
   */
  roles?: Array<TeamMemberRole>;
  ids?: Array<number>;
} & ({ mode?: 'default' } | { mode: 'simple' | 'group' } | { mode: 'customize'; customized_fields: Array<TeamMemberCustomField> });

export const teamMemberStatusScheme = z.enum(['enabled', 'disabled', 'invited', 'declined', 'quit']);
export const TeamMemberStatusEnum = teamMemberStatusScheme.enum;
export type TeamMemberStatus = z.infer<typeof teamMemberStatusScheme>;

const teamMemberRoleScheme = z.enum(['owner', 'manager', 'member', 'group_admin']);
export const TeamMemberRoleEnum = teamMemberRoleScheme.enum;
export type TeamMemberRole = z.infer<typeof teamMemberRoleScheme>;

const teamMemberGroupScopeScheme = z.enum(['all', 'part', 'none']);
export type TeamMemberGroupScope = z.infer<typeof teamMemberGroupScopeScheme>;

const teamMemberAccessPermissionScheme = z.enum(['all', 'none', 'group_all', 'follow_group', 'specific']);
export const TeamMemberAccessPermissionEnum = teamMemberAccessPermissionScheme.enum;
export type TeamMemberAccessPermission = z.infer<typeof teamMemberAccessPermissionScheme>;

export const teamMemberSchema = z.object({
  id: z.number(),
  email: z.string(),
  member_name: z.string(),
  xauth_method_id: z.number(),
  /**
   * - SRS team: owner, admin 可以拿到這個 property, member 需要 `member_list` 權限才可以拿到
   * - SBA team: 不限 role 或權限皆可拿到這個 property
   */
  role: teamMemberRoleScheme,
  status: teamMemberStatusScheme,
  group_id: z.number().nullable(),
  /**
   * 需要組合成 ex: https://be-qa.splashtop.com/splashtop/team/invite/<code>
   */
  invite_code: z.string(),
  granular_control_type: z.enum(['follow_team', 'follow_group', 'customized']),
  grant_granular_control_type: z.enum(['follow_team', 'follow_group', 'customized']),
  technician_manager: z.boolean(),
  /**
   * technician_manager 爲 false 時不會有這個 property
   */
  technician_manager_capability: z.record(z.boolean()).optional(),
  super_admin: z.boolean(),
  /**
   * super_admin 爲 false 時不會有這個 property
   */
  super_admin_capability: z
    .object({
      user_management: z.boolean().optional(),
      team_settings_management: z.boolean().optional(),
    })
    .optional(),
  group_scope: teamMemberGroupScopeScheme,
  /**
   * 被管理的 group id
   *
   * user 爲 group admin 時，會有這個 property
   */
  in_charge_group_ids: z.array(z.number()),
  enable_2fa: z.boolean(),
  last_session_at: z.string().nullable(),
  last_login_at: z.string().nullable(),
  access_permissions_kind: teamMemberAccessPermissionScheme,
  license_type: z.enum(['end_user', 'technician']).optional(),
});
export type TeamMember = z.infer<typeof teamMemberSchema>;

export type TeamMemberCustomField = keyof Pick<
  TeamMember,
  | 'role'
  | 'xauth_method_id'
  | 'member_name'
  | 'status'
  | 'group_id'
  | 'invite_code'
  | 'granular_control_type'
  | 'grant_granular_control_type'
  | 'technician_manager'
  | 'super_admin'
  | 'group_scope'
  | 'enable_2fa'
  | 'last_session_at'
  | 'last_login_at'
  | 'access_permissions_kind'
  | 'license_type'
>;

export const teamMemberListDefaultSchema = z.array(
  teamMemberSchema.pick({
    id: true,
    email: true,
    member_name: true,
    role: true,
    xauth_method_id: true,
    status: true,
    invite_code: true,
  }),
);
export type TeamMemberListDefault = z.infer<typeof teamMemberListDefaultSchema>;
export type TeamMemberListDefaultFields = keyof z.infer<typeof teamMemberListDefaultSchema.element>;

export const teamMemberListSimpleSchema = z.array(
  teamMemberSchema.pick({
    id: true,
    email: true,
    member_name: true,
  }),
);
export type TeamMemberListSimple = z.infer<typeof teamMemberListSimpleSchema>;
export type TeamMemberListSimpleFields = keyof z.infer<typeof teamMemberListSimpleSchema.element>;

export const teamMemberListGroupSchema = z.array(
  teamMemberSchema.pick({
    id: true,
    email: true,
    member_name: true,
    group_id: true,
  }),
);
export type TeamMemberListGroup = z.infer<typeof teamMemberListGroupSchema>;
export type TeamMemberListGroupFields = keyof z.infer<typeof teamMemberListGroupSchema.element>;

export type TeamComputerListWithCustomizedFields = {
  id: number;
  name: string;
  email: string | null;
  group_id: number | null;
  last_online: string | null;
  pubip: string | null;
  local_ip: string | null;
  version: string | null;
  last_session: string | null;
  online_status: boolean | null;
  is_device_owner: boolean;
  deployed: boolean;
  note: string | null;
};

export type TeamDeploymentPackageResponse = {
  id: number;
  name: string;
  d_code: string;
  default: boolean;
};

export type TeamDeploymentCloudBuildFileResponse = {
  can_build: boolean;
  cache_url?: string;
  cache_ok_url?: string;
  backup_url?: string;
  download_url: string;
  redirect_url: string;
};

export type GranularControlKey = (typeof granularControlKeys)[number];
export type GranularControlDetailSettingKey = (typeof granularControlSettingKeys)[number];

export type GranularRole = 'owner' | 'manager' | 'member';
export type GranularControlSetting = Record<GranularRole, 'on' | 'off'>;

export type ConnectPermissionDetailSetting = {
  setting: string | 0 | 1 | 2;
  option: string | null | 1 | 2;
};

/**
 * Required if the role is on
 */
export type ConnectPermissionDetailSettingMap = Partial<Record<GranularRole, ConnectPermissionDetailSetting>>;
export type GetGranularControlResponse = Partial<
  Record<GranularControlKey, GranularControlSetting> & Record<GranularControlDetailSettingKey, ConnectPermissionDetailSettingMap>
>;

export type UpdateGranularControlPayload = GetGranularControlResponse;

export type GrantGranularRole = Extract<GranularRole, 'manager'>;
export type GrantGranularControlSetting = Record<GrantGranularRole, 'on' | 'off'>;

export type GetGrantGranularControlResponse = Partial<Record<GranularControlKey, GrantGranularControlSetting>>;

export type UpdateGrantGranularControlPayload = Partial<Record<GranularControlKey, GrantGranularControlSetting>>;

export type TeamMemberListItem = Pick<TeamMember, 'id' | 'email' | 'member_name'>;

export type TimezoneList = Array<{ value: string; label: string }>;

export type TeamComputersCustomizedField =
  | 'preference_policy'
  | 'antivirus_action_capacity'
  | 'note'
  | 'host_name'
  | 'email'
  | 'os'
  | 'group_id'
  | 'support_vulnerability'
  | 'support_windows_updates'
  | 'support_antivirus'
  | 'last_online'
  | 'online_status'
  | 'deployed'
  | 'pubip'
  | 'local_ip'
  | 'version'
  | 'last_session'
  | 'is_device_owner'
  | 'id'
  | 'windows_updates'
  | 'antivirus'
  | 'vulnerability_status'
  | 'rds_uuid';

export type TeamComputer = {
  id: number;
  name: string;
  group_id: number;
  email?: string | null;
  rds_uuid?: string | null;
  host_name?: string | null;
  note?: string | null;
  pubip?: string | null;
  local_ip?: string | null;

  // TODO: 目前只有放 Grouping assign computer 用到的欄位，有用到其他 customized_field 要再補
};
