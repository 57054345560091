import { Fade } from '@chakra-ui/react';

import { RippleCheckbox, RippleInheritFrom } from '@/design';

type ServerCheckboxProps = {
  isChecked?: boolean;
  isInherited?: boolean;
  isHidden?: boolean;
  onChange: () => void;
};
export const ServerCheckbox = ({ isChecked = false, isInherited = false, isHidden = false, onChange }: ServerCheckboxProps) => {
  if (isInherited) {
    return (
      <Fade in>
        <RippleInheritFrom />
      </Fade>
    );
  }

  if (isHidden) {
    return <></>;
  }

  return (
    <Fade in>
      <RippleCheckbox isChecked={isChecked} onChange={onChange} />
    </Fade>
  );
};
