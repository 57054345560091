import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';

import {
  RippleAdd,
  RippleMenu,
  RippleMenuDivider,
  RippleMenuGroup,
  RippleMenuIconButton,
  RippleMenuItem,
  RippleMenuItemWithSwitch,
  RippleMenuList,
  RippleTooltip,
} from '@/design';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { QuestionnaireKind } from '@/services/serviceDesk/surveys/type';

import { MAX_QUESTIONNAIRE_COUNT } from '../constant';
import { useBrandImageHandler, useFieldList, useWelcomeMessageHandler } from '../hooks';

/**
 * Add Field Button needs to be sticky and stay in flex box model at the same time,
 * set negative margin value to adjust button position
 */
const FORM_AND_BUTTON_GAP = 24;
export const ADD_FIELD_BUTTON_HEIGHT = 32;

type AddFieldButtonProps = {
  addField: ({ kind }: { kind: QuestionnaireKind }) => void;
};

export const AddFieldButton = ({ addField }: AddFieldButtonProps) => {
  const { t } = useTranslation();

  const { isShowBrandImage, handleBrandImageField } = useBrandImageHandler();
  const { noWelcomeMessageField, handleWelcomeMessageField } = useWelcomeMessageHandler();
  const { watch } = useFormContext<SurveyFormValue>();

  const questionnaire = watch('questionnaire');

  const isDisabledAddField = questionnaire.length >= MAX_QUESTIONNAIRE_COUNT;

  const fieldList = useFieldList();

  return (
    <Box position="sticky" top="24px" alignSelf="flex-end" mr={`-${FORM_AND_BUTTON_GAP + ADD_FIELD_BUTTON_HEIGHT}px`} zIndex={1}>
      <RippleMenu>
        <RippleTooltip label={t('survey:add_new_field')}>
          <RippleMenuIconButton
            aria-label="add field button"
            icon={<RippleAdd color="blue.100" />}
            border="1px solid"
            borderColor="blue.100"
            bg="white"
          />
        </RippleTooltip>
        <RippleMenuList minW="208px" maxH="min-content">
          <RippleMenuGroup title={t('survey:survey_header')}>
            <RippleMenuItemWithSwitch isChecked={isShowBrandImage} onChange={handleBrandImageField}>
              {t('survey:brand_icon')}
            </RippleMenuItemWithSwitch>
            <RippleMenuItemWithSwitch isChecked={!noWelcomeMessageField} onChange={handleWelcomeMessageField}>
              {t('survey:welcome_message')}
            </RippleMenuItemWithSwitch>
          </RippleMenuGroup>
          <RippleMenuDivider />
          <RippleMenuGroup title={t('survey:custom_fields')}>
            {fieldList.map(({ label, kind }) => (
              <RippleTooltip key={kind} label={t('survey:maximum_field_alert')} isDisabled={!isDisabledAddField}>
                <RippleMenuItem onClick={() => addField({ kind })} isDisabled={isDisabledAddField}>
                  {label}
                </RippleMenuItem>
              </RippleTooltip>
            ))}
          </RippleMenuGroup>
        </RippleMenuList>
      </RippleMenu>
    </Box>
  );
};
