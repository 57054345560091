import { featureControl } from '@/feature/toggle';

const FT_CSV_INJECTION = featureControl.getToggle('PCP_1465__CSV_INJECTION');

type DownloadAsCSVOptions<T> = {
  data: Array<T>;
  fileName: string;
  headers?: Array<string>;
  transform: (item: T) => Array<string>;
  wrapQuote?: boolean;
};

const universalBOM = '\uFEFF';

export function escapeFormulaOperator(item: string) {
  if (['=', '+', '-', '@', '%', '|', '\t', '\\t', '\r', '\\r'].some((character) => item.startsWith(character))) {
    return `'${item}`;
  }
  return item;
}

export function escapeQuoteInCSV(item: string) {
  return item.replace(/"/g, '""');
}

export function downloadAsCSV<T = unknown>({ data, fileName, headers, transform, wrapQuote = true }: DownloadAsCSVOptions<T>): void {
  const headerString = `${universalBOM}${headers && headers.length > 0 ? `${headers.join(',')}\r\n` : ''}`;
  const csvString = data.reduce(
    (acc, item) => {
      acc.push(
        `${transform(item)
          .map((item) => (FT_CSV_INJECTION ? escapeFormulaOperator(item) : item))
          .map((item) => (wrapQuote ? `"${FT_CSV_INJECTION ? escapeQuoteInCSV(item) : item}"` : item))
          .join(',')}\r\n`,
      );
      return acc;
    },
    [headerString],
  );
  const blob = new Blob(csvString, { type: 'text/csv;charset=utf-8;' });
  const downloadUrl = URL.createObjectURL(blob);

  const downloadDOM = document.createElement('a');
  downloadDOM.setAttribute('href', downloadUrl);
  downloadDOM.setAttribute('download', fileName);
  downloadDOM.click();

  URL.revokeObjectURL(downloadUrl);
}
