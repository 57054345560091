import { useQuery } from '@tanstack/react-query';

import { useTeamInfoForSupportSession } from '@/modules/ServiceDesk/SupportSession/useTeamInfoForSupportSession';
import { Release } from '@/modules/ServiceDesk/shared/userPermissionBitmap';
import { getSupportSessionChannelDetail } from '@/services/serviceDesk/supportSession';

/**
 * Share same logic in TransferSupportSessionModal
 * ref: src/modules/ServiceDesk/SupportSession/modals/TransferSupportSessionModal.tsx
 * */
export const useSupportSessionChannelTechnicians = ({
  channelId,
  assignedTechnicianId,
}: {
  channelId: number;
  assignedTechnicianId?: number;
}) => {
  const { teamId, memberId } = useTeamInfoForSupportSession();

  const { data: channelDetail } = useQuery(['channelDetail', teamId, channelId], () => getSupportSessionChannelDetail(teamId, channelId), {
    enabled: channelId !== null && teamId !== -1,
  });

  const currentTechnician = channelDetail?.technicians.find(({ id }) => id === memberId);
  const currentTechnicianOption = currentTechnician === undefined ? null : { value: memberId.toString(), label: currentTechnician.email };

  const technicianOptions = channelDetail
    ? channelDetail.technicians
        .filter(({ id, status }) => id !== memberId && (status !== 0 || id === assignedTechnicianId))
        .map(({ id, email }) => ({ value: id.toString(), label: email }))
    : [];

  return {
    isShowUnassignedOption: currentTechnician?.user_permission && Release,
    currentTechnicianOption,
    technicianOptions,
  };
};
