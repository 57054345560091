import { Center, Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { FormProvider, useFormContext } from 'react-hook-form';

import { RippleButton, RippleSheetModal } from '@/design';
import { useCustomImageFileValue } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormScenarioMap, SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';
import { Form } from '@/modules/ServiceDesk/SurveyForm/EndUser';
import { useFillForm } from '@/modules/ServiceDesk/SurveyForm/EndUser/hooks';

type PreviewFormModalProps = {
  onClose: () => void;
};

export const PreviewFormModal = ({ onClose }: PreviewFormModalProps) => {
  const { t } = useTranslation();
  const customImageFile = useCustomImageFileValue();

  const { getValues } = useFormContext<SurveyFormValue>();
  const formFields = getValues();

  const { form: submitForm } = useFillForm({
    defaultValues: {
      questionnaire: [],
    },
  });

  return (
    <RippleSheetModal
      title={t('common:preview')}
      isOpen
      onClose={onClose}
      footer={
        <Stack alignItems="flex-end">
          <RippleButton variant="secondary" size="sm" onClick={onClose}>
            {t('common:close')}
          </RippleButton>
        </Stack>
      }
    >
      <Center>
        <Flex w="800px" direction="column" align="center" pt="24px" pb="32px">
          <FormProvider {...submitForm}>
            <Form
              scenario={SurveyFormScenarioMap.editorPreview}
              formFields={formFields}
              brandImageUrl={customImageFile.url}
              onSubmit={() => null}
            />
          </FormProvider>
        </Flex>
      </Center>
    </RippleSheetModal>
  );
};
