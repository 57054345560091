import { useTranslation } from 'react-i18next';
import zod from 'zod';

import { featureControl } from '@/feature/toggle';

export const categoryKeySchema = zod.enum(['streamer_preference', 'software_patch', 'os_patch']);
export type CategoryKey = zod.infer<typeof categoryKeySchema>;

export const usePolicyCategory = (): Record<CategoryKey, { key: CategoryKey; label: string; isShow: boolean }> => {
  const { t } = useTranslation();
  return {
    [categoryKeySchema.Enum.streamer_preference]: {
      key: categoryKeySchema.Enum.streamer_preference,
      label: t('emm-policy:preference'),
      isShow: featureControl.getToggle('PCP_1134__Policy_management'),
    },
    [categoryKeySchema.Enum.software_patch]: {
      key: categoryKeySchema.Enum.software_patch,
      label: 'Software Patch',
      isShow: featureControl.getToggle('PCP_1391__Policy_software_patch'),
    },
    [categoryKeySchema.Enum.os_patch]: {
      key: categoryKeySchema.Enum.os_patch,
      label: 'OS Patch',
      isShow: featureControl.getToggle('PCP_1682__Policy_os_patch'),
    },
  };
};
