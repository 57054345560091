import { useTranslation } from 'next-i18next';

import { useFieldList } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { QuestionnaireKind } from '@/services/serviceDesk/surveys/type';

import { SectionBase } from '.';

type AddFieldSectionProps = {
  addField: ({ kind }: { kind: QuestionnaireKind }) => void;
};

export const AddFieldSection = ({ addField }: AddFieldSectionProps) => {
  const { t } = useTranslation();
  const fieldList = useFieldList();
  const buttonList = fieldList.map((field) => {
    return {
      label: field.label,
      onClick: () => addField({ kind: field.kind }),
    };
  });

  return <SectionBase instruction={t('survey:choose_a_field_type_to_start_building_your_survey')} buttonList={buttonList} />;
};
