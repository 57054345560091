import { MouseEventHandler } from 'react';

import { Box, FormControl, type RadioProps, Stack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { RippleRadio, RippleRadioGroup } from '@/design';
import { Label } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/ViewMode';
import { NO_DEFAULT_OPTION } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { SupportFormScenario, SupportFormScenarioMap } from '@/modules/ServiceDesk/SupportForm/Editor/types';

import { DragButton } from '../EditMode';

type RadioButtonFieldProps = {
  registerFieldName: string;
  defaultValue: RadioProps['defaultValue'];
  isRequired: RadioProps['isRequired'];
  isInvalid?: RadioProps['isInvalid'];
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  onClickRadioGroup?: RadioProps['onClick'];
  options: Array<{ option: string }> | null;
  scenario: SupportFormScenario;
};

const radioSharedProps = {
  id: 'radio-button',
  'aria-describedby': 'radio-button',
  borderRadius: '4px',
  mt: '4px',
  px: '4px',
};

export const RadioButtonField = ({
  registerFieldName,
  defaultValue,
  isRequired,
  isInvalid,
  labelValue,
  onClickLabel,
  onClickRadioGroup,
  options,
  scenario,
}: RadioButtonFieldProps) => {
  const { control } = useFormContext();

  return (
    <Box w="100%" p="8px 36px 12px" position="relative" role="group">
      {scenario === SupportFormScenarioMap.pcp && <DragButton />}
      <FormControl isRequired={isRequired} isInvalid={isInvalid}>
        <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
        {scenario === SupportFormScenarioMap.pcp ? (
          <RippleRadioGroup
            _hover={{ bg: 'blue.0', cursor: 'pointer' }}
            borderColor="neutral.300"
            value={defaultValue ? String(defaultValue) : undefined}
            onClick={onClickRadioGroup}
            {...radioSharedProps}
          >
            <Options options={options} />
          </RippleRadioGroup>
        ) : (
          <Controller
            name={registerFieldName}
            control={control}
            defaultValue={defaultValue === NO_DEFAULT_OPTION ? undefined : defaultValue?.toString()}
            rules={{ required: isRequired }}
            render={({ field }) => {
              return (
                <RippleRadioGroup
                  borderColor={isInvalid ? 'red.100' : 'neutral.300'}
                  onMouseDown={onClickRadioGroup}
                  {...radioSharedProps}
                  {...field}
                >
                  <Options options={options} />
                </RippleRadioGroup>
              );
            }}
          />
        )}
      </FormControl>
    </Box>
  );
};

const Options = ({ options }: { options: RadioButtonFieldProps['options'] }) => {
  return (
    <Stack gap="4px">
      {options && options?.length > 0 ? (
        options.map(({ option }) => (
          <RippleRadio key={option} value={option} w="fit-content" h="32px" alignItems="center">
            {option}
          </RippleRadio>
        ))
      ) : (
        <Box h="64px" />
      )}
    </Stack>
  );
};
