import { MouseEventHandler, forwardRef } from 'react';

import { Box, FormControl } from '@chakra-ui/react';

import { RippleInput, RippleInputProps } from '@/design';
import { DragButton } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode';
import { Label } from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/ViewMode';
import { INPUT_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { SupportFormScenario, SupportFormScenarioMap } from '@/modules/ServiceDesk/SupportForm/Editor/types';

type BriefAnswerFieldProps = RippleInputProps & {
  labelValue: string;
  onClickLabel?: MouseEventHandler<HTMLLabelElement>;
  scenario: SupportFormScenario;
};

export const BriefAnswerField = forwardRef(
  ({ isRequired, labelValue, onClickLabel, onMouseDown, placeholder, scenario, ...inputProps }: BriefAnswerFieldProps, ref) => {
    return (
      <Box w="100%" p="8px 36px 12px" position="relative" role="group">
        {scenario === SupportFormScenarioMap.pcp && <DragButton />}
        <FormControl isRequired={isRequired}>
          <Label title={labelValue} scenario={scenario} onMouseDown={onClickLabel} />
          <RippleInput
            id="brief-answer"
            aria-describedby="brief-answer"
            maxLength={INPUT_MAX_LENGTH}
            size="sm"
            type="text"
            placeholder={placeholder}
            _hover={scenario === SupportFormScenarioMap.pcp ? { bg: 'blue.0', cursor: 'pointer' } : {}}
            onMouseDown={onMouseDown}
            ref={ref}
            {...inputProps}
          />
        </FormControl>
      </Box>
    );
  },
);
