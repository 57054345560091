import * as Sentry from '@sentry/nextjs';
import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';

import { featureControl } from '@/feature/toggle';
import {
  checkUserIsShareable,
  shareableTeamMemberSchema,
  targetUserCanNotBeSharedSchema,
  targetUserIsMspTeamSchema,
} from '@/services/computers';
import validateEmail from '@/utils/validateEmail';

import { addShareTeamMemberAtom, addShareUserAtom, teamMemberListAtom } from '../atoms';
import { AddUserError, addUserErrorEnum } from './types';

export const addUserStateAtom = atomWithReset<'idle' | 'checking' | 'error'>('idle');
export const addUserErrorAtom = atomWithReset<AddUserError | null>(null);
export const addUserAtom = atom(null, async (get, set, payload: { teamId: number; computerId: number; email: string }) => {
  set(addUserStateAtom, 'checking');

  if (validateEmail(payload.email) === null) {
    set(addUserErrorAtom, addUserErrorEnum.invalid_format);
    set(addUserStateAtom, 'error');
    return Promise.reject();
  }

  if (featureControl.getToggle('PCP_1551__ComputerList_AccessPermission_InviteTeamMemberFix')) {
    const teamMemberList = get(teamMemberListAtom);
    const findTeamMember = teamMemberList.find((member) => member.email === payload.email);
    if (findTeamMember) {
      set(addShareTeamMemberAtom, { memberId: findTeamMember.id });
      set(addUserStateAtom, 'idle');
      return;
    }
  }

  const { teamId, computerId, email } = payload;
  try {
    const result = await checkUserIsShareable(teamId, computerId, email);

    if (featureControl.getToggle('PCP_1551__ComputerList_AccessPermission_InviteTeamMemberFix')) {
      set(addShareUserAtom, { id: result.target_user_id, email: result.target_user_email });
    } else {
      const checkIsTeamMember = shareableTeamMemberSchema.safeParse(result);

      if (checkIsTeamMember.success) {
        // team member
        const memberId = checkIsTeamMember.data.target_member_id;
        set(addShareTeamMemberAtom, { memberId });
      } else {
        // external user
        set(addShareUserAtom, { id: result.target_user_id, email: result.target_user_email });
      }
    }

    set(addUserStateAtom, 'idle');
  } catch (error) {
    if (targetUserCanNotBeSharedSchema.safeParse(error).success) {
      set(addUserErrorAtom, addUserErrorEnum.not_shareable);
    } else if (targetUserIsMspTeamSchema.safeParse(error).success) {
      set(addUserErrorAtom, addUserErrorEnum.is_msp_team);
    } else {
      Sentry.captureException(error);
      set(addUserErrorAtom, addUserErrorEnum.unexpected_error);
    }
    set(addUserStateAtom, 'error');
    return Promise.reject();
  }
});

export const resetAddUserInputAtom = atom(null, (_get, set) => {
  set(addUserStateAtom, RESET);
  set(addUserErrorAtom, RESET);
});
