import { Fragment } from 'react';

import { HStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Row, Table, createColumnHelper, flexRender } from '@tanstack/react-table';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalSubTitle,
  RippleModalTitle,
  RippleTD,
  RippleTH,
  RippleTR,
  RippleTooltip,
  RippleVirtuosoReactTable,
  useRippleFlashMessage,
} from '@/design';
import { type BatchRebootComputersOption, batchRebootComputers } from '@/services/computers';

import { removeSelectAtom } from '../../atoms';
import { TargetComputer, TargetComputerList } from './types';

export const confirmModalAtom = atomWithReset<{
  isOpen: boolean;
  kind: BatchRebootComputersOption | null;
  targetComputerList: TargetComputerList;
}>({
  isOpen: false,
  kind: null,
  targetComputerList: [],
});

export function useConfirmModal() {
  const set = useSetAtom(confirmModalAtom);

  return {
    open({ targetComputerList, kind }: { targetComputerList: TargetComputerList; kind: BatchRebootComputersOption }) {
      set({ isOpen: true, targetComputerList, kind });
    },
  };
}

const columnHelper = createColumnHelper<TargetComputer>();
const columns = [
  columnHelper.accessor('name', {
    header: function RenderHeader() {
      const { t } = useTranslation();

      return <RippleTH w="50%">{t('common:name')}</RippleTH>;
    },
    cell: ({ getValue }) => {
      const value = getValue();

      return (
        <RippleTD w="50%">
          <RippleTooltip label={value}>
            <RippleBodyText02 wordBreak="break-all" noOfLines={1}>
              {value}
            </RippleBodyText02>
          </RippleTooltip>
        </RippleTD>
      );
    },
  }),
  columnHelper.display({
    id: 'status',
    header: function RenderHeader() {
      const { t } = useTranslation();

      return <RippleTH w="50%">{t('common:status')}</RippleTH>;
    },
    cell: function RenderCell() {
      const { t } = useTranslation();

      return (
        <RippleTD w="50%">
          <RippleBodyText02>
            {'-> '}
            {t('computer:rebootComputers.confirmModal.status_rebooting')}
          </RippleBodyText02>
        </RippleTD>
      );
    },
  }),
];

export function ConfirmModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { isOpen, targetComputerList, kind } = useAtomValue(confirmModalAtom);
  const resetModal = useResetAtom(confirmModalAtom);

  const removeSelect = useSetAtom(removeSelectAtom);

  const { flashMessage } = useRippleFlashMessage();

  const rebootMutation = useMutation({
    mutationFn: ({ targetComputerIdList, kind }: { targetComputerIdList: Array<number>; kind: BatchRebootComputersOption }) =>
      batchRebootComputers.service(targetComputerIdList, kind),
    onSuccess: (_response, { targetComputerIdList, kind }) => {
      const messageMap: Record<BatchRebootComputersOption, string> = {
        restart_streamer: t('computer:rebootComputers.confirmModal.restart_streamer.successMessage'),
        normal_reboot: t('computer:rebootComputers.confirmModal.reboot.successMessage'),
      };

      flashMessage({
        variant: 'info',
        title: messageMap[kind],
      });
      removeSelect(targetComputerIdList);
    },
    onError: () => {
      flashMessage({ variant: 'error', title: t('common:unexpectedError') });
    },
    onSettled: () => {
      resetModal();
    },
  });

  function handleConfirm() {
    if (kind !== null) {
      rebootMutation.mutate({ targetComputerIdList: targetComputerList.map(({ id }) => Number(id)), kind });
    }
  }

  const titleMap: Record<BatchRebootComputersOption, string> = {
    restart_streamer: t('computer:rebootComputers.confirmModal.restart_streamer.title'),
    normal_reboot: t('computer:rebootComputers.confirmModal.reboot.title'),
  };

  const descriptionMap: Record<BatchRebootComputersOption, string> = {
    restart_streamer: t('computer:rebootComputers.confirmModal.restart_streamer.description'),
    normal_reboot: t('computer:rebootComputers.confirmModal.reboot.description'),
  };

  const isDisabled = rebootMutation.isLoading;

  return (
    <RippleModal isOpen={isOpen} onClose={resetModal} closeOnEsc={!isDisabled} closeOnOverlayClick={!isDisabled}>
      <RippleModalOverlay />
      <RippleModalContent w="100%" maxWidth="736px">
        <RippleModalHeader>
          <RippleModalTitle>{kind && titleMap[kind]}</RippleModalTitle>
          <RippleModalSubTitle>
            {t('computer:rebootComputers.confirmModal.subTitle', {
              targetComputerCounts: targetComputerList.length,
            })}
          </RippleModalSubTitle>
        </RippleModalHeader>

        <RippleModalBody display="flex" flexDirection="column">
          <RippleBodyText02 mb="24px">{kind && descriptionMap[kind]}</RippleBodyText02>

          <RippleVirtuosoReactTable
            variant="compact"
            colorScheme="white"
            style={{ width: '100%' }}
            data={targetComputerList}
            columns={columns}
            tableOptions={{ enableSorting: false }}
            virtuosoOptions={getVirtuosoOptions}
          />
        </RippleModalBody>

        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={resetModal} isDisabled={isDisabled}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton
              data-testid="submit-button"
              variant="primary"
              onClick={handleConfirm}
              isLoading={rebootMutation.isLoading}
              isDisabled={isDisabled}
            >
              {t('computer:rebootComputers.confirmModal.submit_reboot')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

function getVirtuosoOptions<T>(table: Table<T>) {
  return { itemContent: renderItemContent, fixedHeaderContent: renderFixedHeaderContent(table) };
}

function renderFixedHeaderContent<T>(table: Readonly<Table<T>>) {
  return () =>
    table.getHeaderGroups().map((headerGroup) => (
      <RippleTR key={headerGroup.id} w="100%">
        {headerGroup.headers.map((header) => (
          <Fragment key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Fragment>
        ))}
      </RippleTR>
    ));
}

function renderItemContent<T>(_index: number, row: Row<T>) {
  return (
    <RippleTR isSelected={row.getIsSelected()} w="100%">
      {row.getVisibleCells().map((cell) => (
        <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
      ))}
    </RippleTR>
  );
}
