import { Box, Center } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { RippleButton, RippleButtonProps, RippleEdit } from '@/design';
import { SUBMIT_BUTTON_UUID } from '@/modules/ServiceDesk/SurveyForm/Editor/constant';
import { useSetCurrentOpenField, useValidateAndAutoFillEmptyField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

export function SubmitButton({ label, ...restProps }: { label: string } & RippleButtonProps) {
  return (
    <RippleButton minW="160px" size="sm" {...restProps}>
      {label}
    </RippleButton>
  );
}

export function ActionButtons() {
  const { validateAndAutoFillEmptyField } = useValidateAndAutoFillEmptyField();
  const { watch } = useFormContext<SurveyFormValue>();
  const label = watch('action_button_label');

  const setCurrentOpenField = useSetCurrentOpenField();

  return (
    <Center
      w="100%"
      p="16px 48px"
      _hover={{
        bg: 'blue.0',
      }}
      cursor="pointer"
      role="group"
      onClick={() => {
        validateAndAutoFillEmptyField();
        setCurrentOpenField({ uuid: SUBMIT_BUTTON_UUID, autoFocusType: 'label' });
      }}
    >
      <Box position="relative">
        <SubmitButton isLoading={false} label={label} />
        <Box as="span" position="absolute" right="-32px" top="50%" transform="translateY(-50%)" opacity={0} _groupHover={{ opacity: 1 }}>
          <RippleEdit />
        </Box>
      </Box>
    </Center>
  );
}
