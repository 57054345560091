import { useState } from 'react';

import { HStack, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalSubTitle,
  RippleModalTitle,
  RippleRadio,
  RippleRadioGroup,
  RippleSkeleton,
  useRippleFlashMessage,
} from '@/design';
import { getRebootComputerOptionMap } from '@/services/computers';
import type { RebootComputerKind, RebootComputerOptionMap } from '@/services/computers';

import { computerScopeAtom } from '../atoms';
import { useRemoveMoreActionsCache } from '../components/MoreActions/utils';
import { useConfirmModal } from './ConfirmModal';
import { kindEnum } from './constants';

export const selectKindModalAtom = atomWithReset<{ isOpen: boolean; computerId: number | null; computerName: string | null }>({
  isOpen: false,
  computerId: null,
  computerName: null,
});

export function useSelectKindModal() {
  const set = useSetAtom(selectKindModalAtom);

  return {
    open: ({ computerId, computerName }: { computerId: number; computerName: string }) => {
      set({ isOpen: true, computerId, computerName });
    },
  };
}

const optionMapFallback: RebootComputerOptionMap = {
  restart_streamer: false,
  normal_reboot: false,
  safe_mode_reboot: false,
  shut_down: false,
  log_off: false,
};

export function SelectKindModal(): React.JSX.Element {
  const { t } = useTranslation();
  const { flashMessage } = useRippleFlashMessage();

  const removeMoreActionsCache = useRemoveMoreActionsCache();

  const computerScope = useAtomValue(computerScopeAtom);

  const { isOpen, computerId, computerName } = useAtomValue(selectKindModalAtom);
  const resetModal = useResetAtom(selectKindModalAtom);
  const confirmModal = useConfirmModal();

  const [kind, setKind] = useState<RebootComputerKind | undefined>(undefined);

  const optionMapQuery = useQuery({
    queryKey: ['computerList', 'rebootComputerOptions', computerId],
    queryFn: () => {
      if (computerId) return getRebootComputerOptionMap.service(computerId);
      return null as never;
    },
    cacheTime: 0,
    enabled: isOpen && computerId !== null,
    retry(failureCount, error) {
      if (
        getRebootComputerOptionMap.errorHandling.resourceNotExist(error) ||
        getRebootComputerOptionMap.errorHandling.noPermission(error)
      ) {
        return false;
      }

      return failureCount < 3;
    },
    onError: (error) => {
      if (getRebootComputerOptionMap.errorHandling.noPermission(error)) {
        flashMessage({ variant: 'error', title: t('common:noPermission') });
        if (computerId !== null) {
          removeMoreActionsCache({ computerScope, computerId });
        }
      } else {
        flashMessage({ variant: 'error', title: t('common:unexpectedError') });
      }
      handleCloseModal();
    },
  });
  const optionMap: RebootComputerOptionMap = optionMapQuery.data ?? optionMapFallback;

  const isDisabled = optionMapQuery.isFetching;

  return (
    <RippleModal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:remoteReboot.selectKindModal.title')}</RippleModalTitle>
          <RippleModalSubTitle>{computerName}</RippleModalSubTitle>
        </RippleModalHeader>
        <RippleModalBody>
          {optionMapQuery.isFetching ? (
            <VStack spacing="8px" alignItems="start">
              <RippleSkeleton h="20px" w="200px" />
              <RippleSkeleton h="20px" w="200px" />
              <RippleSkeleton h="20px" w="200px" />
            </VStack>
          ) : (
            <RippleRadioGroup
              value={kind}
              onChange={(newValue) => {
                setKind(newValue as RebootComputerKind);
              }}
            >
              <VStack spacing="8px" alignItems="start">
                {optionMap.restart_streamer && (
                  <RippleRadio data-testid="restart_streamer" value={kindEnum.restart_streamer}>
                    {t('computer:remoteReboot.selectKindModal.option.restart_streamer.label')}
                  </RippleRadio>
                )}
                {optionMap.normal_reboot && (
                  <RippleRadio data-testid="normal_reboot" value={kindEnum.normal_reboot}>
                    {t('computer:remoteReboot.selectKindModal.option.reboot.label')}
                  </RippleRadio>
                )}
                {optionMap.safe_mode_reboot && (
                  <RippleRadio data-testid="safe_mode_reboot" value={kindEnum.safe_mode_reboot}>
                    {t('computer:remoteReboot.selectKindModal.option.safe_mode_reboot.label')}
                  </RippleRadio>
                )}
                {optionMap.log_off && (
                  <RippleRadio data-testid="log_off" value={kindEnum.log_off}>
                    {t('computer:remoteReboot.selectKindModal.option.log_off.label')}
                  </RippleRadio>
                )}
                {optionMap.shut_down && (
                  <RippleRadio data-testid="shut_down" value={kindEnum.shut_down}>
                    {t('computer:remoteReboot.selectKindModal.option.shut_down.label')}
                  </RippleRadio>
                )}
              </VStack>
            </RippleRadioGroup>
          )}
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="12px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={resetModal}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton
              data-testid="ok-button"
              variant="primary"
              isLoading={optionMapQuery.isLoading}
              isDisabled={isDisabled}
              onClick={() => {
                if (computerId && computerName && kind) {
                  confirmModal.open({ computerId, computerName, kind });
                  handleCloseModal();
                }
              }}
            >
              {t('common:ok')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );

  function handleCloseModal() {
    resetModal();
    setKind(undefined);
  }
}
