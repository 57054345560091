import { useIsFetching, useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useTeamId } from '@/models/TeamInformation';
import { computerScopeAtom, refreshComputerDataAtom } from '@/modules/Computer/ComputerList/atoms';
import { useRemoveMoreActionsCache } from '@/modules/Computer/ComputerList/components/MoreActions/utils';
import { computerSharedModeMap, getComputerSharedMode, updateComputerSharedMode } from '@/services/computers';
import { getTeamMemberList } from '@/services/team';

import {
  accessPermissionModalAtom,
  functionPermissionMapAtom,
  newSharedWithDiffAtom,
  resetModalAtom,
  selectedUserCountAtom,
  setSharedModeAtom,
  sharedModeAtom,
  teamMemberListAtom,
} from './atoms';

const useSharedModeQueryKey = 'getComputerSharedMode';
export function useSharedModeQuery() {
  const teamId = useTeamId();
  const { isOpen, computerData } = useAtomValue(accessPermissionModalAtom);
  const setSharedMode = useSetAtom(setSharedModeAtom);

  return useQuery({
    queryKey: [useSharedModeQueryKey, computerData],
    queryFn: async () => {
      if (computerData) {
        const response = await getComputerSharedMode(teamId, computerData.id);

        setSharedMode(response);
        return computerData;
      }
      return null as never;
    },
    enabled: isOpen && Boolean(computerData),
  });
}
export function useIsFetchingSharedMode() {
  return useIsFetching([useSharedModeQueryKey]) > 0;
}

const useTeamMemberListQueryKey = 'getTeamMemberList';
export function useTeamMemberListQuery() {
  const teamId = useTeamId();
  const { canGetMemberList } = useAtomValue(functionPermissionMapAtom);

  const isFetchingSharedMode = useIsFetchingSharedMode();

  const { isOpen, computerData } = useAtomValue(accessPermissionModalAtom);

  const [teamMemberList, setTeamMemberList] = useAtom(teamMemberListAtom);

  return useQuery({
    queryKey: [useTeamMemberListQueryKey, teamId, computerData],
    queryFn: async () => {
      const teamMemberList = await getTeamMemberList(teamId, {
        mode: 'customize',
        status: ['invited', 'enabled', 'disabled'],
        customized_fields: ['role', 'group_scope', 'super_admin'],
      });

      setTeamMemberList(teamMemberList);

      return null;
    },
    enabled: isOpen && canGetMemberList && Boolean(computerData) && !isFetchingSharedMode && teamMemberList.length === 0,
  });
}
export function useIsFetchingTeamMemberList() {
  return useIsFetching([useTeamMemberListQueryKey]) > 0;
}

const submitMutationKey = 'submitAccessPermission';
export function useSubmitMutation() {
  const teamId = useTeamId();

  const removeMoreActionsCache = useRemoveMoreActionsCache();
  const computerScope = useAtomValue(computerScopeAtom);

  const { computerData } = useAtomValue(accessPermissionModalAtom);
  const resetModal = useSetAtom(resetModalAtom);

  const shareMode = useAtomValue(sharedModeAtom);
  const newSharedWithDiff = useAtomValue(newSharedWithDiffAtom);

  const refreshComputerData = useSetAtom(refreshComputerDataAtom);

  return useMutation({
    mutationKey: [submitMutationKey],
    mutationFn: async () => {
      const payload = computePayload();

      if (computerData !== null) {
        return updateComputerSharedMode(teamId, computerData.id, payload);
      }

      return null as never;
    },
    onSuccess: () => {
      resetModal();
    },
    onSettled: () => {
      if (computerData) {
        removeMoreActionsCache({ computerScope, computerId: computerData.id });
        refreshComputerData([computerData.id]);
      }
    },
  });

  function computePayload() {
    if (shareMode === computerSharedModeMap.share_to_someone) {
      return {
        mode: computerSharedModeMap.share_to_someone,
        ...newSharedWithDiff,
      };
    } else {
      return {
        mode: shareMode,
      };
    }
  }
}
export function useIsSubmitting() {
  return useIsMutating([submitMutationKey]) > 0;
}
export function useSubmitState() {
  const { hasLimitedManagePermission } = useAtomValue(accessPermissionModalAtom);
  const sharedMode = useAtomValue(sharedModeAtom);
  const selectedUserCount = useAtomValue(selectedUserCountAtom);

  const isSubmitting = useIsSubmitting();

  const isSubmitDisabled = computeIsSubmitDisabled();
  function computeIsSubmitDisabled() {
    if (hasLimitedManagePermission) {
      return sharedMode !== 'stop_share_server';
    }

    if (sharedMode === computerSharedModeMap.share_to_someone) {
      return selectedUserCount === 0;
    }

    return false;
  }

  return {
    isSubmitDisabled,
    isSubmitting,
  };
}

export function useIsLoading() {
  const isFetchingSharedMode = useIsFetchingSharedMode();
  const isFetchingUserList = useIsFetchingTeamMemberList();
  const isSubmitting = useIsSubmitting();

  return isFetchingSharedMode || isFetchingUserList || isSubmitting;
}
