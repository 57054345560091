import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { RippleDivider, RippleSkeleton, RippleTooltip, RippleTypography } from '@/design';
import { featureControl } from '@/feature/toggle';
import { tableColumn } from '@/models/Computer';
import { ComputerPlatformNameKeys, getComputerPlatformName } from '@/modules/Computer';
import { type ComputerVersion, getTeamComputerDetail, getUserComputerDetail, srsVersionSchema } from '@/services/computers';
import { useTime } from '@/utils/formatDateTime';

const ComputerDetailSkeleton = () => <RippleSkeleton height="8px" alignSelf="center" />;

type ComputerDetailInfoProps = {
  /** default: team */
  scope?: 'user' | 'team';
  computerName: string;
  teamId: string;
  computerId: string;
};

const ComputerDetailInfo = (props: ComputerDetailInfoProps) => {
  const { scope = 'team', computerName, teamId, computerId } = props;
  const { t } = useTranslation();
  const { formatDateTime } = useTime();

  const queryClient = useQueryClient();

  const [enabled, setEnabled] = useState<boolean>(false);
  const queryKeys = useMemo(() => ['ComputerDetailInfo', scope, teamId, computerId], [scope, teamId, computerId]);
  const { isInitialLoading: isLoading, data } = useQuery(
    queryKeys,
    async () => {
      const response = await (scope === 'team'
        ? getTeamComputerDetail.service(teamId, computerId)
        : getUserComputerDetail.service(computerId));

      const { formattedVersion, platform } = getFormattedVersionPlatform(response.version);

      let status = '';
      if (response.online_status) {
        const dateSince = response.online_since ? formatDateTime(response.online_since) : '';
        status = dateSince ? `Online since ${dateSince}` : t('computer:online');
      } else {
        const dateSince = response.last_online ? formatDateTime(response.last_online) : '';
        status = dateSince ? `Offline since ${dateSince}` : t('computer:offline');
      }

      const os = tableColumn.os.getAccessor()(response);
      const osBuild = tableColumn.osBuild.getAccessor()(response);

      return {
        deviceName: response.host_name,
        accountName: response.email ? response.email : t('computer:deployedComputer'),
        version: formattedVersion,
        platform,
        os,
        osBuild,
        architecture: response.architecture,
        status,
        ipAddress: response.pubip,
        publicIP: response.pubip,
        localIP: response.local_ip,
      };
    },
    {
      enabled,
      staleTime: 30000, // 30 seconds
    },
  );

  useEffect(() => {
    const queryCache = queryClient.getQueryCache();
    const query = queryCache.find(queryKeys);
    const isCached = query?.state?.status === 'success';

    if (isCached) {
      setEnabled(true);
      return;
    }

    const timer = setTimeout(() => {
      setEnabled(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [queryClient, queryKeys]);

  const { deviceName, accountName, version, os, osBuild, architecture, status, publicIP, localIP } = data ?? {};
  const isWaitingOrLoading = !enabled || isLoading;

  const properties: Array<{ key: string; element: React.JSX.Element }> = [
    { key: t('computer:deviceName'), element: <RippleTypography variant="body03">{deviceName}</RippleTypography> },
    { key: t('computer:account'), element: <RippleTypography variant="body03">{accountName}</RippleTypography> },
    { key: t('computer:version'), element: <RippleTypography variant="body03">{version}</RippleTypography> },
    { key: tableColumn.os.getLabel(t), element: <RippleTypography variant="body03">{os}</RippleTypography> },
    { key: tableColumn.osBuild.getLabel(t), element: <RippleTypography variant="body03">{osBuild}</RippleTypography> },
    { key: tableColumn.architecture.getLabel(t), element: <RippleTypography variant="body03">{architecture}</RippleTypography> },

    { key: t('computer:status'), element: <RippleTypography variant="body03">{status}</RippleTypography> },
    {
      key: t('computer:ipAddress'),
      element: (
        <Stack direction="column" spacing="4px">
          {publicIP && (
            <RippleTypography variant="body03" wordBreak="break-all">
              WAN {publicIP}
            </RippleTypography>
          )}
          {localIP && (
            <RippleTypography variant="body03" wordBreak="break-all">
              LAN {localIP}
            </RippleTypography>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Box>
      <RippleTypography variant="heading07" pb="16px">
        {computerName}
      </RippleTypography>
      <RippleDivider mb="8px" />
      <Box display="grid" gridTemplateColumns="auto 1fr" gap="8px 16px">
        {properties.map(({ key, element }) => (
          <Fragment key={key}>
            <RippleTypography variant="heading09">{key}</RippleTypography>
            {isWaitingOrLoading ? <ComputerDetailSkeleton /> : element}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

type ComputerDetailTooltipProps = {
  children: NonNullable<ReactNode>;
  /** default: team */
  scope?: 'user' | 'team';
  isDisabled?: boolean;
  computerName: ComputerDetailInfoProps['computerName'];
  teamId: ComputerDetailInfoProps['teamId'];
  computerId: ComputerDetailInfoProps['computerId'];
};

export function ComputerDetailTooltip(props: ComputerDetailTooltipProps) {
  const { scope = 'team', children, isDisabled, computerName, teamId, computerId } = props;

  if (isDisabled) {
    return <>{children}</>;
  }

  const label = <ComputerDetailInfo scope={scope} computerName={computerName} teamId={teamId} computerId={computerId} />;

  return (
    <RippleTooltip placement="right" aria-label={computerName} maxWidth="initial" width="381px" p="16px" label={label}>
      {children}
    </RippleTooltip>
  );
}

function getFormattedVersionPlatform(version: ComputerVersion | null | undefined): { formattedVersion: string; platform: string } {
  if (featureControl.getToggle('PCP_1440__Accept_version_in_3_digit')) {
    let formattedVersion = '';
    let platform = '';

    const [srsVersion, _splashtop2, rawPlatform] = (version ?? '').split(' ');
    const [srs, digitVersion] = srsVersion.split('/');
    if (srs !== 'SRS') {
      return { formattedVersion: '', platform: '' };
    }
    const parsedFormattedVersion = srsVersionSchema.safeParse(digitVersion);
    if (parsedFormattedVersion.success) {
      formattedVersion = parsedFormattedVersion.data;
    }

    platform = getComputerPlatformName(rawPlatform as ComputerPlatformNameKeys) ?? '';

    return { formattedVersion, platform };
  }

  const matches = (version ?? '').match(/\w+\/(\d+\.\d+\.\d+\.\d+)\s+\w+\s+(\w+)/);
  const formattedVersion = matches?.[1] ?? '';
  let platform = '';
  if (matches?.[2]) {
    platform = getComputerPlatformName(matches[2] as ComputerPlatformNameKeys) ?? '';
  }

  return { formattedVersion, platform };
}
