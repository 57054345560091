import { useCallback, useEffect, useMemo } from 'react';

import { Box, Center, Fade, HStack, Stack, forwardRef } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import groupBy from 'lodash/groupBy';
import matches from 'lodash/matches';
import stubTrue from 'lodash/stubTrue';
import { Trans, useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import {
  RippleArrowDown,
  RippleArrowRight,
  RippleCheckbox,
  RippleCount,
  RippleHighlight,
  RippleHyperLink,
  RippleIconButton,
  RippleInfo16,
  RipplePopover,
  RipplePopoverArrow,
  RipplePopoverBody,
  RipplePopoverContent,
  RipplePopoverTrigger,
  RippleSkeleton,
  RippleTBody,
  RippleTHead,
  RippleTR,
  RippleTable,
  RippleTableGroupHead,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import { RippleMacOffline, RippleMacOnline, RippleWindowsOffline, RippleWindowsOnline } from '@/design/RippleComputer/RippleComputerIcons';
import { DEFAULT_GROUP_ID } from '@/modules/Computer';
import { GroupPolicyRelation, ServerPolicyRelation } from '@/modules/PolicyList/hooks';
import { Policy } from '@/services/teams/emm_policies';
import { getStreamerVersion } from '@/utils/computers';

import { expandedGroupsAtom } from '../atoms';
import { ServerCheckbox } from './ServerCheckbox';
import { useTableFilter } from './TableFilter';
import {
  CheckboxColumn,
  CheckboxHeader,
  Gap24Column,
  Gap24Header,
  NameColumn,
  NameHeader,
  PolicyColumn,
  PolicyHeader,
  VersionColumn,
  VersionHeader,
} from './columns';

const List = forwardRef((props, ref) => <Stack gap="12px" {...props} ref={ref} />);

type GroupTableProps = {
  keyword: string;
  isLoading: boolean;
  isAllGroupsSelected: boolean;
  currentPolicy: Policy;
  defaultPolicy: Policy;
  groupIds: Array<number>;
  groupPolicyRelation: GroupPolicyRelation;
  serverPolicyRelation: ServerPolicyRelation;
  selectedGroups: Record<number, boolean>;
  selectedServers: Record<number, boolean>;
  onSelectedGroupChange: (id: number) => void;
  onSelectedServerChange: (id: number) => void;
  onAllGroupSelect: () => void;
};

export const GroupTable = ({
  keyword,
  isLoading,
  isAllGroupsSelected,
  groupIds,
  groupPolicyRelation,
  serverPolicyRelation,
  currentPolicy,
  defaultPolicy,
  selectedGroups,
  selectedServers,
  onSelectedGroupChange,
  onSelectedServerChange,
  onAllGroupSelect,
}: GroupTableProps) => {
  const [expandedGroups, setExpandedGroups] = useAtom(expandedGroupsAtom);
  const { isFilteredByGroup, isFilteredBySelectedGroup, isFilteredBySelectedServer, isServerFilteredByPolicy, isGroupFilteredByPolicy } =
    useTableFilter();
  const { t } = useTranslation();

  const toggleGroupExpanded = useCallback(
    (groupId: number) => {
      setExpandedGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
    },
    [setExpandedGroups],
  );

  const serverInSameGroup = groupBy(
    Object.values(serverPolicyRelation).filter((node) => node.platform === currentPolicy.platform),
    (node) => node.groupId,
  );

  const groupedComputers = useMemo(() => {
    return (
      groupIds
        .map((id) => {
          return {
            group: groupPolicyRelation[id],
            servers: serverInSameGroup[id] ?? [],
          };
        }) // Filter by group
        .filter(({ group }) => isFilteredByGroup(group.group))
        // Filter by selected
        .map(({ group, servers }) => ({ group, servers: servers.filter((server) => isFilteredBySelectedServer(server.server)) }))
        .filter(
          ({ group, servers }) =>
            servers.length > 0 || isFilteredBySelectedGroup(group.group, group.policies[currentPolicy.platform].isFollowTeam),
        )
        // Filter by policies
        .map(({ group, servers }) => ({ group, servers: servers.filter((server) => isServerFilteredByPolicy(server.server)) }))
        .filter(({ group, servers }) => {
          return (
            servers.length > 0 ||
            isGroupFilteredByPolicy({
              groupId: group.group.id,
              platform: currentPolicy.platform,
              teamPolicyId: defaultPolicy.id,
            })
          );
        })
    );
  }, [
    currentPolicy.platform,
    defaultPolicy.id,
    groupIds,
    groupPolicyRelation,
    isFilteredByGroup,
    isFilteredBySelectedGroup,
    isFilteredBySelectedServer,
    isGroupFilteredByPolicy,
    isServerFilteredByPolicy,
    serverInSameGroup,
  ]);

  // filter by search
  const filteredBySearch = useMemo(
    () =>
      groupedComputers
        .map(({ group, servers }) => {
          const isGroupMatched =
            group.group.name.toLowerCase().includes(keyword) ||
            group.policies[currentPolicy.platform].policy.name.toLowerCase().includes(keyword);
          return {
            group,
            isGroupMatched,
            servers: servers.filter((server) => {
              if (!keyword || isGroupMatched) {
                return true;
              }
              return server.server.name.toLowerCase().includes(keyword) || server.policy.name.toLowerCase().includes(keyword);
            }),
          };
        })
        .filter((group) => {
          return group.isGroupMatched || group.servers.length > 0;
        }),
    [currentPolicy.platform, groupedComputers, keyword],
  );

  useEffect(
    function initExpandGroups() {
      groupIds.length > 0 &&
        setExpandedGroups(
          groupIds.reduce(
            (acc, id) => {
              acc[id] = true;
              return acc;
            },
            {} as Record<number, boolean>,
          ),
        );
    },
    [groupIds, groupIds.length, setExpandedGroups],
  );

  const renderComputerItems = useCallback(
    (servers: Array<ServerPolicyRelation[number]>, isGroupSelected: boolean) => {
      if (servers.length === 0) {
        return (
          <RippleTR>
            <CheckboxColumn />
            <Gap24Column />
            <NameColumn>
              <RippleTypography variant="body02">{t('common:no_computers')}</RippleTypography>
            </NameColumn>
            <PolicyColumn />
            <VersionColumn />
          </RippleTR>
        );
      }
      return (
        <>
          {servers.map((server) => {
            const isAssignedTheSamePolicy = server.policy.id === currentPolicy?.id;
            const isServerSelected = selectedServers[server.server.id];
            const isInherited = isGroupSelected && !isServerSelected && (server.isFollowGroup || isAssignedTheSamePolicy);
            // If the group is selected, the row is selected if the server is not assigned to any policy
            const isRowSelected = isInherited || isServerSelected;
            // If the server is assigned to another policy, the checkbox is hidden when group is selected
            const serverPolicyName = cond([
              [matches({ isFollowGroup: true }), constant(t('emm-policy:follow_group'))],
              [matches({ isCustomized: true }), () => server.parentPolicy?.name],
              [stubTrue, () => server.policy.name],
            ])(server);
            const version = getStreamerVersion(server.server.version);
            return (
              <RippleTR key={server.server.id} isSelected={isRowSelected}>
                <CheckboxColumn>
                  <ServerCheckbox
                    isChecked={isServerSelected}
                    isInherited={isInherited}
                    isHidden={isGroupSelected && !isAssignedTheSamePolicy}
                    onChange={() => onSelectedServerChange(server.server.id)}
                  />
                </CheckboxColumn>
                <Gap24Column />
                <NameColumn gap="16px">
                  {server.platform === 'Windows' && (server.server.online_status ? <RippleWindowsOnline /> : <RippleWindowsOffline />)}
                  {server.platform === 'macOS' && (server.server.online_status ? <RippleMacOnline /> : <RippleMacOffline />)}
                  <RippleTypography variant="body02" noOfLines={2} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                    <RippleHighlight query={keyword}>{server.server.name}</RippleHighlight>
                  </RippleTypography>
                </NameColumn>
                <PolicyColumn>
                  <HStack gap="8px">
                    <RippleTypography variant="body02" noOfLines={2} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                      <RippleHighlight query={keyword}>{serverPolicyName ?? ''}</RippleHighlight>
                    </RippleTypography>
                    {server.isCustomized && (
                      // TODO: Change it to new RippleChip component when new component ready
                      <Box p="2px 8px" border="1px solid" borderColor="blue.60" borderRadius="16px" bg="blue.10" flexShrink="0">
                        <RippleTypography variant="body03">{t('emm-policy:overridden')}</RippleTypography>
                      </Box>
                    )}
                  </HStack>
                </PolicyColumn>
                <VersionColumn>
                  <RippleTypography variant="body02">{version}</RippleTypography>
                </VersionColumn>
              </RippleTR>
            );
          })}
        </>
      );
    },
    [currentPolicy?.id, keyword, onSelectedServerChange, selectedServers, t],
  );

  const renderItemContent = useCallback(
    (
      _index: number,
      {
        group,
        servers,
      }: {
        group: GroupPolicyRelation[number];
        servers: Array<ServerPolicyRelation[number]>;
      },
    ) => {
      const isSelected = selectedGroups[group.group.id];
      const isExpanded = expandedGroups[group.group.id];
      const groupPolicy = group.policies[currentPolicy.platform];
      const isGroupSelected = currentPolicy.super_root ? groupPolicy.isFollowTeam || isSelected : isSelected;
      const isAnyServerSelected = servers.some((server) => selectedServers[server.server.id]);
      const groupPolicyName = groupPolicy.policy.name;
      const isDefaultGroup = group.group.id === Number(DEFAULT_GROUP_ID);
      return (
        <RippleTBody key={group.group.id}>
          <RippleTableGroupHead isSelected={isGroupSelected} textTransform="capitalize">
            <CheckboxColumn>
              {isDefaultGroup ? (
                <></>
              ) : (
                <RippleCheckbox
                  isChecked={isGroupSelected}
                  // Team default policy is unable unchecked
                  isDisabled={groupPolicy.policy.id === currentPolicy.id && currentPolicy.super_root}
                  isIndeterminate={!isGroupSelected && isAnyServerSelected}
                  onChange={() => {
                    onSelectedGroupChange(group.group.id);
                  }}
                />
              )}
            </CheckboxColumn>
            <Gap24Column>
              <RippleIconButton
                aria-label="Collapse"
                w="24px"
                h="24px"
                p="0"
                icon={isExpanded ? <RippleArrowDown /> : <RippleArrowRight />}
                onClick={() => toggleGroupExpanded(group.group.id)}
              />
            </Gap24Column>
            <NameColumn>
              <HStack gap="8px">
                <RippleTypography variant="heading07">
                  <RippleHighlight query={keyword}>{group.group.name}</RippleHighlight>
                </RippleTypography>
                <RippleCount variant="neutralLight" count={servers.length} />
              </HStack>
            </NameColumn>
            <PolicyColumn>
              <HStack gap="8px" alignItems="center">
                <RippleTypography variant="body02" noOfLines={2} isTruncated wordBreak="break-all" whiteSpace="break-spaces">
                  <RippleHighlight query={keyword}>{groupPolicyName}</RippleHighlight>
                </RippleTypography>
                {isDefaultGroup && (
                  <RippleTooltip label={t('emm-policy:the_policy_of_default_group_cannot_be_changed')}>
                    <Center>
                      <RippleInfo16 />
                    </Center>
                  </RippleTooltip>
                )}
              </HStack>
            </PolicyColumn>
            <VersionColumn />
          </RippleTableGroupHead>
          {isExpanded && <Fade in>{renderComputerItems(servers, isGroupSelected)}</Fade>}
        </RippleTBody>
      );
    },
    [
      currentPolicy.id,
      currentPolicy.platform,
      currentPolicy.super_root,
      expandedGroups,
      keyword,
      onSelectedGroupChange,
      renderComputerItems,
      selectedGroups,
      selectedServers,
      t,
      toggleGroupExpanded,
    ],
  );

  return (
    <RippleTable colorScheme="white">
      <RippleTHead>
        <RippleTR w="100%">
          <CheckboxHeader>
            <RippleCheckbox isChecked={isAllGroupsSelected} onChange={onAllGroupSelect} />
          </CheckboxHeader>
          <Gap24Header />
          <NameHeader>{t('emm-policy:group')}</NameHeader>
          <PolicyHeader>{t('emm-policy:endpoint_policy')}</PolicyHeader>
          <VersionHeader>
            <HStack gap="4px" alignItems="center">
              <RippleTypography variant="heading09">{t('emm-policy:streamer_version')}</RippleTypography>
              <RipplePopover placement="bottom">
                <RipplePopoverTrigger>
                  <Center cursor="pointer">
                    <RippleInfo16 />
                  </Center>
                </RipplePopoverTrigger>
                <RipplePopoverContent>
                  <RipplePopoverArrow />
                  <RipplePopoverBody>
                    <RippleTypography variant="body02" color="black">
                      <Trans
                        i18nKey="emm-policy:only_streamer_versions_or_later_are_listed_here_learn_more_about_feature_support_matrix"
                        values={{
                          version: '3.7.0.0',
                        }}
                        components={{
                          Link: (
                            <RippleHyperLink
                              variant="hyperlink02"
                              target="_blank"
                              href=" https://splashtopbusiness.zendesk.com/knowledge/editor/01J17EEK4K49X8KCTVXQ5EFJFG/en-us?brand_id=245134"
                            />
                          ),
                        }}
                      />
                    </RippleTypography>
                  </RipplePopoverBody>
                </RipplePopoverContent>
              </RipplePopover>
            </HStack>
          </VersionHeader>
        </RippleTR>
      </RippleTHead>
      {isLoading ? (
        <RippleTBody>
          <RippleTableGroupHead textTransform="capitalize">
            <CheckboxColumn>
              <RippleCheckbox isChecked={false} />
            </CheckboxColumn>
            <Gap24Column>
              <RippleIconButton aria-label="Collapse" w="24px" h="24px" p="0" icon={<RippleArrowDown />} />
            </Gap24Column>
            <NameColumn>
              <RippleSkeleton w="160px" />
            </NameColumn>
            <PolicyColumn>
              <RippleSkeleton w="160px" />
            </PolicyColumn>
            <VersionColumn>
              <RippleSkeleton w="160px" />
            </VersionColumn>
          </RippleTableGroupHead>
          <RippleTR>
            <CheckboxColumn>
              <ServerCheckbox onChange={() => {}} />
            </CheckboxColumn>
            <Gap24Column />
            <NameColumn>
              <RippleSkeleton w="200px" />
            </NameColumn>
            <PolicyColumn>
              <RippleSkeleton w="200px" />
            </PolicyColumn>
            <VersionColumn>
              <RippleSkeleton w="160px" />
            </VersionColumn>
          </RippleTR>
        </RippleTBody>
      ) : (
        <Virtuoso
          style={{ height: '520px', width: '100%' }}
          data={filteredBySearch}
          itemContent={renderItemContent}
          components={{ List }}
        />
      )}
    </RippleTable>
  );
};
