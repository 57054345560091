import { featureControl } from '@/feature/toggle';
import { TeamInformation } from '@/utils/useTeamInformation';

export function shouldShowCurrentSessions(teamInformation: TeamInformation | null, sosTeamInformation: TeamInformation | null) {
  const stbCanShow = teamInformation && !teamInformation.isExpired && teamInformation.memberStatus === 'enabled';
  const sosCanShow = sosTeamInformation && !sosTeamInformation.isExpired && sosTeamInformation.memberStatus === 'enabled';

  return stbCanShow || sosCanShow;
}

function checkIsAdmins(teamInformation: TeamInformation | null) {
  return teamInformation?.role === 'manager' || teamInformation?.role === 'owner';
}

function checkIsSTBAdmins(teamInformation: TeamInformation | null) {
  return teamInformation?.currentTeam !== 'sos' && checkIsAdmins(teamInformation);
}

function checkIsSOSTechnicianManager(sosTeamInformation: TeamInformation | null) {
  return sosTeamInformation?.currentTeam === 'sos' && sosTeamInformation?.teamMemberPermissions?.technicianManager;
}

export function shouldShowChatTranscript(teamInformation: TeamInformation | null, sosTeamInformation: TeamInformation | null) {
  const isSTBAdmins = checkIsSTBAdmins(teamInformation);
  const canSRSChatTranscript =
    (teamInformation?.seatPermissions?.chatTranscript || teamInformation?.seatPermissions?.offSessionChat) &&
    teamInformation.currentTeam !== 'sba';
  const canSTBChatTranscript = isSTBAdmins && canSRSChatTranscript;

  const isSOSTechnicianManager = checkIsSOSTechnicianManager(sosTeamInformation);

  return canSTBChatTranscript || isSOSTechnicianManager;
}

export function shouldShowFileTransfer(teamInformation: TeamInformation | null, sosTeamInformation: TeamInformation | null) {
  const isSTBAdmins = checkIsSTBAdmins(teamInformation);
  const canSTBFileTransfer =
    isSTBAdmins && (teamInformation?.seatPermissions?.fileTransfer || teamInformation?.seatPermissions?.offSessionFile);

  const isSOSTechnicianManager = checkIsSOSTechnicianManager(sosTeamInformation);

  return canSTBFileTransfer || isSOSTechnicianManager;
}

export function shouldShowSOSCustomizationBySOS(sosTeamInformation: TeamInformation | null) {
  if (!sosTeamInformation) return false;
  if (sosTeamInformation.plan === 'legacy') return false;
  return (
    sosTeamInformation.role === 'owner' ||
    (sosTeamInformation.role === 'manager' && sosTeamInformation.teamMemberPermissions.technicianManager)
  );
}

export function shouldShowSOSCustomizationByEnterprise(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  return (
    teamInformation.currentTeam === 'srs' &&
    [
      'enterprise',
      'ste_lite_sos_basic',
      'ste_lite_sos_plus',
      ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
        ? ['ste_lite_sos_unlimited']
        : []),
    ].includes(teamInformation.plan) &&
    teamInformation.role !== 'member' &&
    teamInformation.teamMemberPermissions.attendedAccess
  );
}

export function shouldShowSOSCustomization(teamInformation: TeamInformation | null, sosTeamInformation: TeamInformation | null) {
  return shouldShowSOSCustomizationBySOS(sosTeamInformation) || shouldShowSOSCustomizationByEnterprise(teamInformation);
}

export function shouldShowVulnerabilityScore(teamInformation: TeamInformation | null) {
  return teamInformation?.role !== 'member' && teamInformation?.seatPermissions?.vulnerabilityScore;
}

export function shouldShowInventory(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  return teamInformation.teamPermissions.premierPack && teamInformation.role !== 'member';
}

export function shouldShowPreferencePolicy(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  return (
    ['sba', 'srs', 'msp'].includes(teamInformation.currentTeam) &&
    (teamInformation?.role === 'manager' || teamInformation?.role === 'owner')
  );
}

export function shouldShowAntivirusPolicy(teamInformation: TeamInformation | null, hasBitDefender2?: boolean) {
  if (!teamInformation || !hasBitDefender2) return false;
  return (
    ['sba', 'srs', 'msp'].includes(teamInformation.currentTeam) &&
    (teamInformation?.role === 'manager' || teamInformation?.role === 'owner')
  );
}
