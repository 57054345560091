import { Fragment, useState } from 'react';

import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useTranslation } from 'next-i18next';
import { Controller, UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import {
  RippleAdd,
  RippleBodyText02,
  RippleIconButton,
  RippleInput,
  RippleMinus,
  RippleRadio,
  RippleRadioGroup,
  RippleTextField,
} from '@/design';
import colors from '@/design/theme/colors';
import {
  ActionBar,
  DragButton,
  DragListWrapper,
  DragWrapper,
  FieldTitle,
  Highlighter,
} from '@/modules/ServiceDesk/SupportForm/Editor/FormEditor/EditMode';
import { COMBO_BOX_OPTION_MAX_LENGTH, LABEL_MAX_LENGTH } from '@/modules/ServiceDesk/SupportForm/Editor/constants';
import { useCurrentOpenFieldAtom } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useEditingForm';
import { useScrollToLastField } from '@/modules/ServiceDesk/SupportForm/Editor/hooks/useScrollToLastField';
import { SupportFormValue } from '@/modules/ServiceDesk/SupportForm/Editor/types';
import { QuestionnaireFieldOption } from '@/services/serviceDesk/supportForm/types';
import preSanitize from '@/utils/pre-sanitize';

const NO_DEFAULT_VALUE = '';

type SingleSelectFieldEditModeProps = {
  fieldIndex: number;
  isRequireProps: UseFormRegisterReturn;
  labelProps: UseFormRegisterReturn;
  onDelete: () => void;
  onSave: () => void;
  options: Array<QuestionnaireFieldOption> | null;
  updateOptions: (options: Array<QuestionnaireFieldOption> | null) => void;
};

export function SingleSelectFieldEditMode({
  fieldIndex,
  isRequireProps,
  labelProps,
  onDelete,
  onSave,
  options,
  updateOptions,
}: SingleSelectFieldEditModeProps) {
  const { t } = useTranslation();
  const { labelRef } = useScrollToLastField();

  const currentOpenField = useCurrentOpenFieldAtom();

  const { control, setValue } = useFormContext<SupportFormValue>();

  // Handle add option logic
  const [newOption, setNewOption] = useState('');
  const [isSanitizedNewOptionError, setIsSanitizedNewOptionError] = useState(false);

  const newOptionTrimmed = newOption.trim();
  const newOptionSanitized = preSanitize(newOptionTrimmed).trim();

  const isNewOptionDuplicate = options?.some(({ option }) => option === newOption) ?? false;
  const isNewOptionSanitizedDuplicate = options?.some(({ option }) => option === newOptionSanitized) ?? false;

  const isDuplicateError = isNewOptionDuplicate || isSanitizedNewOptionError;
  const isDisabledAddOptionButton = newOption === '' || isDuplicateError;

  const addNewOption = () => {
    if (newOptionTrimmed === '' || newOptionSanitized === '') {
      setNewOption('');
    } else if (isNewOptionSanitizedDuplicate) {
      setNewOption(newOptionSanitized);
      setIsSanitizedNewOptionError(true);
    } else {
      updateOptions(options ? [...options, { option: newOptionSanitized }] : [{ option: newOptionSanitized }]);
      setNewOption('');
    }
  };

  // Drag & Drop methods
  const handleDragEnd = (event: DragEndEvent) => {
    if (!options || !(event.active && event.over)) return;

    const { active, over } = event;

    if (active.id !== over.id) {
      const originalIndex: number = active.data.current?.sortable.index;
      const droppedIndex: number = over.data.current?.sortable.index;

      updateOptions(arrayMove(options, originalIndex, droppedIndex));
    }
  };

  return (
    <Box w="100%" p="0px 36px 12px" position="relative">
      <Highlighter />
      <FieldTitle title={t('support-forms:comboBox')} />
      <RippleInput
        autoFocus={currentOpenField?.autoFocusType === 'label'}
        id="edit-combo-box-single-select"
        aria-describedby="edit-combo-box-single-select"
        maxLength={LABEL_MAX_LENGTH}
        variant="flushed"
        sx={{
          caretColor: colors.blue[100],
        }}
        _focus={{ borderColor: isDuplicateError ? 'red.100' : 'blue.100' }}
        fontSize="12px"
        size="sm"
        type="text"
        placeholder={t('support-forms:addName')}
        _placeholder={{
          fontStyle: 'italic',
          fontWeight: 'normal',
          fontSize: '14px',
          color: 'dark.40',
        }}
        mb="12px"
        {...labelProps}
        ref={(e) => {
          labelProps.ref(e);
          labelRef.current = e;
        }}
      />
      <>
        <Flex alignItems="flex-start" mb="12px" gap="8px" justify="space-between">
          <RippleTextField
            autoFocus={currentOpenField?.autoFocusType === 'input'}
            id="edit-combo-box-single-select-options"
            aria-describedby="edit-combo-box-single-select-options"
            maxLength={COMBO_BOX_OPTION_MAX_LENGTH}
            sx={{ width: '100%' }}
            size="sm"
            type="text"
            _placeholder={{
              color: 'dark.40',
              fontStyle: 'italic',
              fontWeight: 400,
            }}
            placeholder={t('support-forms:addAnOption')}
            value={newOption}
            onChange={(e) => {
              setIsSanitizedNewOptionError(false);
              setNewOption(e.target.value);
            }}
            isInvalid={isNewOptionDuplicate}
            helperText={isDuplicateError ? t('support-forms:optionDuplicated') : ''}
          />
          <HStack h="40px">
            <RippleIconButton
              isDisabled={isDisabledAddOptionButton}
              variant="ghost"
              aria-label="add option"
              icon={<RippleAdd color={isDisabledAddOptionButton ? 'dark.40' : 'blue.100'} />}
              onClick={addNewOption}
            />
            <RippleBodyText02 w="48px" textAlign="center">
              {t('common:default')}
            </RippleBodyText02>
          </HStack>
        </Flex>

        <Controller
          control={control}
          name={`questionnaire.${fieldIndex}.default_option`}
          render={({ field: { value, onChange } }) => {
            if (typeof value !== 'string') {
              console.error('SingleSelectFieldEditMode: combo box value is not string');
              return <></>;
            }
            return (
              <RippleRadioGroup value={value} onChange={onChange}>
                <VStack spacing="8px" alignItems="stretch" mb="8px">
                  <Flex alignItems="center" justify="space-between">
                    <RippleBodyText02 flex="auto" py="6px" pl="40px">
                      {t('support-forms:select')}
                    </RippleBodyText02>
                    <Center w="48px">
                      <RippleRadio value={NO_DEFAULT_VALUE} />
                    </Center>
                  </Flex>

                  {options && (
                    <DragListWrapper
                      items={options.map((optionInfo) => {
                        // @dnd-kit needs `id` for identification
                        return { ...optionInfo, id: optionInfo.option };
                      })}
                      onDragEnd={handleDragEnd}
                    >
                      <Fragment>
                        {options.map(({ option }) => (
                          <DragWrapper key={option} id={option} style={{ _hover: { bg: 'neutral.10' } }}>
                            <Flex gap="8px" alignItems="center" position="relative">
                              <DragButton style={{ left: '0px', opacity: 1, _hover: { bg: 'transparent' } }} />
                              <RippleBodyText02 flex="auto" pl="40px">
                                {option}
                              </RippleBodyText02>
                              <RippleIconButton
                                variant="ghost"
                                aria-label="remove-option"
                                icon={<RippleMinus color="neutral.300" />}
                                onClick={() => {
                                  if (value === option) {
                                    setValue(`questionnaire.${fieldIndex}.default_option`, NO_DEFAULT_VALUE);
                                  }
                                  updateOptions(options ? options.filter((item) => item.option !== option) : null);
                                }}
                              />
                              <Center w="48px">
                                <RippleRadio value={option} />
                              </Center>
                            </Flex>
                          </DragWrapper>
                        ))}
                      </Fragment>
                    </DragListWrapper>
                  )}
                </VStack>
              </RippleRadioGroup>
            );
          }}
        />
      </>
      <ActionBar switchProps={isRequireProps} onDelete={onDelete} onSave={onSave} />
    </Box>
  );
}
