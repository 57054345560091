import { useAttendedTeamInformation, useUnattendedTeamInformation } from '@/models/TeamInformation';

import {
  shouldShowServiceDeskChannelByEnterprise,
  shouldShowServiceDeskChannelBySOS,
  shouldShowServiceDeskSupportSessionByEnterprise,
  shouldShowServiceDeskSupportSessionBySOS,
} from './utils';

export function useAccessChannel() {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const canAccessChannelByEnterprise = shouldShowServiceDeskChannelByEnterprise(unattendedTeamInformation);
  const canAccessChannelBySOS = shouldShowServiceDeskChannelBySOS(attendedTeamInformation);
  const canAccessChannel = canAccessChannelByEnterprise || canAccessChannelBySOS;

  return { canAccessChannel, canAccessChannelByEnterprise, canAccessChannelBySOS };
}

export function useAccessSupportSession() {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const canAccessSupportSessionByEnterprise = shouldShowServiceDeskSupportSessionByEnterprise(unattendedTeamInformation);
  const canAccessSupportSessionBySOS = shouldShowServiceDeskSupportSessionBySOS(attendedTeamInformation);
  const canAccessSupportSession = canAccessSupportSessionByEnterprise || canAccessSupportSessionBySOS;

  return { canAccessSupportSession, canAccessSupportSessionByEnterprise, canAccessSupportSessionBySOS };
}
