import { atom } from 'jotai';

import { teamInformationMapAtom } from '@/models/TeamInformation/atoms';

import type { SettingControlContextValue } from '../types';

/**
 * SHOULD NOT USE IT DIRECTLY
 *
 * NOTE: This is along with feature toggle `PCP_2003__TeamSettings__SettingControlContext_refactor`
 */
const settingControlPrivateAtom = atom<SettingControlContextValue | null>(null);
/**
 * NOTE: This is along with feature toggle `PCP_2003__TeamSettings__SettingControlContext_refactor`
 */
export const settingControlAtom = atom<SettingControlContextValue, [Omit<SettingControlContextValue, 'teamMetadataSet'>], void>(
  (get) => {
    const settingControl = get(settingControlPrivateAtom);

    if (settingControl === null) {
      throw new Error('NO SETTING CONTROL');
    }

    return settingControl;
  },
  (get, set, update) => {
    const teamMetadataSet = get(teamInformationMapAtom);

    set(settingControlPrivateAtom, { ...update, teamMetadataSet });
  },
);
