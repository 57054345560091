import { Flex, HStack, Icon, VStack } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import {
  RippleBodyText02,
  RippleButton,
  RippleComputer,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalOverlay,
  RippleModalTitle,
  RippleSkeleton,
  RippleStrong,
  RippleUser,
} from '@/design';
import { forceDisconnect, getConnectInformation } from '@/services/computers';
import useTeamInformation from '@/utils/useTeamInformation';

import { checkDisconnectAtom } from '../atoms';

export const confirmModalAtom = atomWithReset<{
  isOpen: boolean;
  computerId: number | null;
}>({
  isOpen: false,
  computerId: null,
});

export function useConfirmModal() {
  const set = useSetAtom(confirmModalAtom);

  return {
    open({ computerId }: { computerId: number }) {
      set({ isOpen: true, computerId });
    },
  };
}

export function ConfirmModal(): React.JSX.Element {
  const { t } = useTranslation();
  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;

  const { isOpen, computerId } = useAtomValue(confirmModalAtom);
  const resetModal = useResetAtom(confirmModalAtom);
  const checkDisconnect = useSetAtom(checkDisconnectAtom);

  const connectInfoQuery = useQuery({
    queryKey: ['computerList', 'disconnectSession', 'getConnectInfo', computerId],
    queryFn: async () => {
      if (computerId) return getConnectInformation(computerId);
      return null as never;
    },
    enabled: isOpen && Boolean(computerId),
    cacheTime: 0,
  });
  const { src_name = '', src_spid = '' } = connectInfoQuery.data ?? {};

  const disconnectMutation = useMutation({
    mutationFn: async ({ computerId }: { computerId: number }) => forceDisconnect(computerId),
    onSuccess: () => {
      checkDisconnect({ teamId, computerId: String(computerId) });
    },
    onSettled: () => {
      resetModal();
    },
  });

  const isDisabled = connectInfoQuery.isFetching || disconnectMutation.isLoading;

  return (
    <RippleModal isOpen={isOpen} onClose={resetModal} size="xl">
      <RippleModalOverlay />
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('computer:disconnectSession.confirmModal.title')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody display="flex" flexDirection="column">
          <VStack spacing="24px" alignItems="start">
            <RippleBodyText02 mb="12px">{t('computer:disconnectSession.confirmModal.hint1')}</RippleBodyText02>

            <VStack
              spacing="4px"
              alignItems="stretch"
              w="100%"
              bgColor="neutral.10"
              borderRadius="4px"
              border="1px solid"
              borderColor="neutral.40"
              p="8px"
            >
              <Flex minH="24px" alignItems="center">
                <RippleUser color="dark.80" />
                <Flex flex="1" ml="8px">
                  {connectInfoQuery.isFetching ? (
                    <RippleSkeleton h="20px" w="100%" />
                  ) : (
                    <RippleStrong as="p" variant="strong02" color="dark.80" wordBreak="break-all">
                      {src_spid}
                    </RippleStrong>
                  )}
                </Flex>
              </Flex>
              <Flex h="16px">
                <ConnectingIcon />
              </Flex>
              <Flex minH="24px" alignItems="center">
                <RippleComputer color="dark.80" />
                <Flex flex="1" ml="8px">
                  {connectInfoQuery.isFetching ? (
                    <RippleSkeleton h="20px" w="100%" />
                  ) : (
                    <RippleStrong as="p" variant="strong02" color="dark.80" wordBreak="break-all">
                      {src_name}
                    </RippleStrong>
                  )}
                </Flex>
              </Flex>
            </VStack>

            <RippleBodyText02>{t('computer:disconnectSession.confirmModal.hint2')}</RippleBodyText02>
          </VStack>
        </RippleModalBody>
        <RippleModalFooter>
          <HStack spacing="8px">
            <RippleButton data-testid="cancel-button" variant="grayScaleGhost" onClick={resetModal}>
              {t('common:cancel')}
            </RippleButton>
            <RippleButton
              data-testid="submit-button"
              variant="danger"
              isDisabled={isDisabled}
              isLoading={disconnectMutation.isLoading}
              onClick={() => {
                if (computerId) {
                  disconnectMutation.mutate({ computerId });
                }
              }}
            >
              {t('computer:disconnect')}
            </RippleButton>
          </HStack>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
}

function ConnectingIcon(): React.JSX.Element {
  return (
    <Icon width="24px" height="16px" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0V16" stroke="#036DEA" stroke-dasharray="3 2" />
    </Icon>
  );
}
