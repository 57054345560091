import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import chunk from 'lodash/chunk';
import keyBy from 'lodash/keyBy';

import { useRippleFlashMessage } from '@/design';
import { featureControl } from '@/feature/toggle';
import { useEmmPolicyAccess } from '@/hooks/accessibility/useEmmPolicyAccess';
import { useTeamId } from '@/models/TeamInformation';
import {
  Platform,
  Policy,
  listEmmPoliciesService,
  listEmmPolicyGroupsService,
  listEmmPolicyServersService,
} from '@/services/teams/emm_policies';

type UsePolicyMapQueryProps = {
  enabled?: boolean;
};

export const usePolicyMapQuery = ({ enabled = true }: UsePolicyMapQueryProps = {}) => {
  const { flashMessage } = useRippleFlashMessage();
  const teamId = useTeamId();
  const canAccessPolicy = useEmmPolicyAccess();
  const policiesQuery = useQuery({
    queryKey: ['listEmmPolicies', teamId],
    queryFn: () => listEmmPoliciesService.execute(teamId),
    select: (data) => {
      return {
        list: data,
        map: keyBy(data, 'id'),
      };
    },
    enabled: enabled && canAccessPolicy && featureControl.getToggle('PCP_1134__Policy_management'),
  });
  const listPolicyServersQuery = useQuery({
    queryKey: ['listEmmPolicyServers', teamId, policiesQuery.data],
    queryFn: async () => {
      const chunks = chunk(policiesQuery.data?.list ?? [], 100);
      const results = await Promise.all(
        chunks.map((chunk) =>
          listEmmPolicyServersService.execute(
            teamId,
            chunk.map((policy) => policy.id),
          ),
        ),
      );
      return results.flat();
    },
    enabled: Boolean(policiesQuery.data),
    select: (data) =>
      data.reduce(
        (acc, curr) => {
          acc[curr.server_id] = policiesQuery.data?.map[curr.emm_policy_id];
          return acc;
        },
        {} as Record<number, Policy | undefined>,
      ),
  });
  const listPolicyGroupsQuery = useQuery({
    queryKey: ['listEmmPolicyGroups', teamId, policiesQuery.data],
    queryFn: async () => {
      const chunks = chunk(policiesQuery.data?.list ?? [], 100);
      const results = await Promise.all(
        chunks.map((chunk) =>
          listEmmPolicyGroupsService.execute(
            teamId,
            chunk.map((policy) => policy.id),
          ),
        ),
      );
      return results.flat();
    },
    enabled: Boolean(policiesQuery.data),
    select: (data) => {
      return data.reduce(
        (acc, { group_id, emm_policy_id }) => {
          const policyPlatform = policiesQuery.data?.map[emm_policy_id]?.platform;
          if (!policyPlatform) {
            return acc;
          }
          acc[group_id] = { ...acc[group_id], [policyPlatform]: policiesQuery.data?.map[emm_policy_id] };
          return acc;
        },
        {} as Record<number, Record<Platform, Policy | undefined>>,
      );
    },
  });
  useEffect(
    function handlePolicesQueryError() {
      if (policiesQuery.error) {
        flashMessage({ variant: 'error', title: 'Failed to fetch policies' });
      }
    },
    [flashMessage, policiesQuery.error],
  );
  useEffect(
    function handleListPolicyServersQueryError() {
      if (listPolicyServersQuery.error) {
        flashMessage({ variant: 'error', title: 'Failed to fetch policy servers' });
      }
    },
    [flashMessage, listPolicyServersQuery.error],
  );
  useEffect(
    function handleListPolicyGroupsQueryError() {
      if (listPolicyGroupsQuery.error) {
        flashMessage({ variant: 'error', title: 'Failed to fetch policy groups' });
      }
    },
    [flashMessage, listPolicyGroupsQuery.error],
  );
  return {
    refetch: policiesQuery.refetch,
    isFetched: policiesQuery.isFetched && listPolicyServersQuery.isFetched && listPolicyGroupsQuery.isFetched,
    isFetching: policiesQuery.isFetching || listPolicyServersQuery.isFetching || listPolicyGroupsQuery.isFetching,
    serverPolicies: listPolicyServersQuery.data,
    groupPolicies: listPolicyGroupsQuery.data,
    policyMap: policiesQuery.data?.map,
    getTeamDefaultPolicy: (platform: Platform) =>
      policiesQuery.data?.list.find((policy) => policy.platform === platform && policy.super_root),
  };
};
