import { useFormContext } from 'react-hook-form';

import { useSetCurrentOpenField, useValidateAndAutoFillEmptyField } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { SurveyFormValue } from '@/modules/ServiceDesk/SurveyForm/Editor/types';

import { WELCOME_UUID } from './../constant';

export const useWelcomeMessageHandler = () => {
  const { validateAndAutoFillEmptyField } = useValidateAndAutoFillEmptyField();

  const { setValue, watch } = useFormContext<SurveyFormValue>();
  const setCurrentOpenField = useSetCurrentOpenField();

  const welcomeMessage = watch('welcome_message');
  const noWelcomeMessageField = welcomeMessage.title === null && welcomeMessage.subtitle === null;

  const addWelcomeMessageField = () => {
    setValue('welcome_message.title', '');
    setValue('welcome_message.subtitle', '');
    validateAndAutoFillEmptyField();
    setCurrentOpenField({ uuid: WELCOME_UUID, autoFocusType: 'label' });
  };

  const deleteWelcomeMessageField = () => {
    setValue('welcome_message.title', null);
    setValue('welcome_message.subtitle', null);
    validateAndAutoFillEmptyField({ isResetCurrentOpenField: true });
  };

  const handleWelcomeMessageField = () => {
    if (noWelcomeMessageField) {
      addWelcomeMessageField();
    } else {
      deleteWelcomeMessageField();
    }
  };

  const editWelcomeMessageTitle = () => {
    validateAndAutoFillEmptyField();
    setCurrentOpenField({ uuid: WELCOME_UUID, autoFocusType: 'label' });
  };

  const editWelcomeMessageSubtitle = () => {
    validateAndAutoFillEmptyField();
    setCurrentOpenField({ uuid: WELCOME_UUID, autoFocusType: 'input' });
  };

  return {
    noWelcomeMessageField,
    deleteWelcomeMessageField,
    handleWelcomeMessageField,
    editWelcomeMessageTitle,
    editWelcomeMessageSubtitle,
  };
};
