import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import Spinner from '@/components/Spinner';
import { useRippleFlashMessage } from '@/design';
import { ErrorResponse } from '@/services/common/types';
import { checkBackgroundActions, getTeamComputerUUID, getUserComputerUUID } from '@/services/computers';
import type { BackgroundActionsKind } from '@/services/computers';
import useTeamInformation from '@/utils/useTeamInformation';

import { ComputerScope } from '../types';
import { Require2SVModal, useRequire2SVModal } from './Require2SVModal';

const backgroundActionsAtom = atomWithReset<{
  isTriggered: boolean;
  computerScope: ComputerScope | null;
  computerId: number | null;
  kind: BackgroundActionsKind | null;
}>({
  isTriggered: false,
  computerScope: null,
  computerId: null,
  kind: null,
});

export function useBackgroundActions() {
  const set = useSetAtom(backgroundActionsAtom);

  return {
    execute({ computerScope, computerId, kind }: { computerScope: ComputerScope; computerId: number; kind: BackgroundActionsKind }) {
      set({ isTriggered: true, computerScope, computerId, kind });
    },
  };
}

export function BackgroundActions(): React.JSX.Element {
  const { t } = useTranslation();
  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;

  const { isTriggered, computerScope, computerId, kind } = useAtomValue(backgroundActionsAtom);
  const reset = useResetAtom(backgroundActionsAtom);
  const require2SVModal = useRequire2SVModal();
  const { flashMessage } = useRippleFlashMessage();

  const { isFetching } = useQuery({
    queryKey: ['computerList', 'checkBackgroundActions', teamId, computerScope, computerId, kind],
    queryFn: async () => {
      if (computerId && kind && computerScope) {
        let devUUID;
        switch (computerScope) {
          case 'team': {
            devUUID = await getTeamComputerUUID({ teamId, computerId });
            break;
          }
          case 'user': {
            devUUID = await getUserComputerUUID({ computerId });
            break;
          }
        }

        if (devUUID) return checkBackgroundActions(devUUID, kind);
        else {
          throw new Error('Unexpected Error');
        }
      }
      return null as never;
    },
    enabled: isTriggered,
    cacheTime: 0,
    onSuccess: ({ session_url }) => {
      window.open(session_url, '_blank', 'noopener,noreferrer');
    },
    onSettled: () => {
      reset();
    },
    onError: (error: ErrorResponse) => {
      if (error.errorReason.error === 'required_2sv') {
        require2SVModal.open();
      } else {
        flashMessage({ variant: 'error', title: t('common:unexpectedError') });
      }
    },
  });

  return (
    <>
      {isFetching && <Spinner />}
      <Require2SVModal />
    </>
  );
}
