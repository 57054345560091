import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleAlertSpecificColor, RippleButton, RippleTypography } from '@/design';

type AlertMessageProps = {
  title: string;
  button?: {
    title: string;
    onClick: () => void;
  };
};

function AlertMessage({ title, button }: AlertMessageProps) {
  return (
    <Flex justifyContent="center" my="16px">
      <Flex
        justifyContent="center"
        alignItems="center"
        borderWidth="1px"
        borderStyle="solid"
        boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
        borderRadius="4px"
        padding="7px 12px"
        borderColor="yellow.200"
        backgroundColor="yellow.10"
      >
        <Box flexShrink={0}>
          <RippleAlertSpecificColor />
        </Box>
        <RippleTypography variant="body02" ml="8px">
          {title}
        </RippleTypography>
        {button && (
          <RippleButton size="xs" height="26px" ml="12px" textTransform="capitalize" onClick={button.onClick}>
            {button.title}
          </RippleButton>
        )}
      </Flex>
    </Flex>
  );
}

export type ComputerSelectorEmptyMessageProps = {
  isLoading: boolean;
  haveFilterApplied: boolean;
  haveSearchApplied: boolean;
  isEmpty: boolean;
  selectedComputerCount: number;
  onShowAll: () => void;
};

function ComputerSelectorEmptyMessage(props: ComputerSelectorEmptyMessageProps) {
  const { t } = useTranslation();
  const { isLoading, haveFilterApplied, haveSearchApplied, isEmpty, selectedComputerCount, onShowAll } = props;

  if (isLoading || !isEmpty) {
    return null;
  }

  let title = '';
  const haveSelectedComputers = selectedComputerCount !== 0;

  if (haveFilterApplied && haveSearchApplied) {
    title = haveSelectedComputers
      ? t('computer:selector.noAnyResult', { count: selectedComputerCount })
      : t('computer:selector.noAnyResultAndNoSelected');
  } else if (haveFilterApplied) {
    title = haveSelectedComputers
      ? t('computer:selector.noFilterResult', { count: selectedComputerCount })
      : t('computer:selector.noFilterResultAndNoSelected');
  } else if (haveSearchApplied) {
    title = haveSelectedComputers
      ? t('computer:selector.noSearchResult', { count: selectedComputerCount })
      : t('computer:selector.noSearchResultAndNoSelected');
  } else {
    title = t('computer:selector.noComputers');
  }

  const button = {
    title: t('computer:selector.showAll'),
    onClick: onShowAll,
  };

  return <AlertMessage title={title} button={button} />;
}

export default ComputerSelectorEmptyMessage;
